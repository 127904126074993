import User from "./User";
import Customer from "./Customer";
import Employee from "./Employee";
import InvoiceEmployeeExpense from "./InvoiceEmployeeExpense";
import Setting from "./Setting";
import JobExpense from "./JobExpense";
import TypeOfTransport from "./TypeOfTransport";
import EmployeeGroup from "./EmployeeGroup";
import Place from "./Place";

export default {
  User,
  Customer,
  Employee,
  EmployeeGroup,
  InvoiceEmployeeExpense,
  Setting,
  JobExpense,
  TypeOfTransport,
  Place,
};
