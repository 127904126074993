import { DateService, UtilsService } from "@standard/services";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";

const InvoiceDescription = ({ jobs, onChange, disabled }) => {
  const [selectedJobIdList, setSelectedJobIdList] = useState([]);

  useEffect(() => {
    setSelectedJobIdList(jobs.filter((f) => f.selected).map((job) => job.id));
  }, [jobs]);

  useEffect(() => {
    onChange(selectedJobIdList);
  }, [selectedJobIdList]);

  const onChangeCheckbox = (selectedJobId) => {
    const index = selectedJobIdList.indexOf(selectedJobId);
    const newSelectedJobIdList = [...selectedJobIdList];

    if (index === -1) {
      newSelectedJobIdList.push(selectedJobId);
      setSelectedJobIdList(newSelectedJobIdList);
    } else {
      newSelectedJobIdList.splice(index, 1);
      setSelectedJobIdList(newSelectedJobIdList);
    }
  };

  const onChangeCheckboxHead = () => {
    if (selectedJobIdList.length === jobs.length) {
      setSelectedJobIdList([]);
    } else {
      setSelectedJobIdList(jobs.map((job) => job.id));
    }
  };

  const entities = jobs.map((job, jobKey) => {
    const checked = selectedJobIdList.indexOf(job.id) !== -1;

    return (
      <tr key={jobKey}>
        <td className="text-center">
          <Form.Check
            inline
            type="checkbox"
            id={`jobSelectedId_${jobKey}`}
            checked={checked}
            onChange={(e) => onChangeCheckbox(job.id)}
            disabled={disabled}
          />
        </td>
        <td className="text-center">
          <Button
            variant="link"
            className="text-link p-1"
            target="_blank"
            href={`/transaction/job/edit/${job.id}`}
          >
            {job.jobSTS}
          </Button>
        </td>
        <td className="text-center">
          {DateService.convertDateToString(job.transactionDate)}
        </td>
        <td className="text-center">{job.carNo}</td>
        <td>{job.employeeName}</td>
        <td>
          <strong>From</strong> {job.sourcePlace}
          <br />
          <strong>To</strong> {job.destinationPlace}
        </td>
        <td className="text-right">
          {UtilsService.convertToMoney(job.price)} ฿
        </td>
        <td>
          {job.otherExpenses &&
            job.otherExpenses
              .map((expense, expenseKey) => (
                <span className="" key={expenseKey}>
                  <strong>{expense.label}:</strong>
                  {UtilsService.convertToMoney(expense.price)} ฿
                </span>
              ))
              .reduce((prev, curr) => (prev ? [prev, ", ", curr] : curr), "")}
        </td>
        <td>{job.remark}</td>
      </tr>
    );
  });

  const checkboxHeaderChecked =
    jobs.length > 0 && selectedJobIdList.length === jobs.length;

  return (
    <>
      <strong>Description:</strong>
      <table className="table">
        <thead>
          <tr>
            <th>
              <Form.Check
                inline
                type="checkbox"
                id={`jobSelectedId`}
                checked={checkboxHeaderChecked}
                onChange={onChangeCheckboxHead}
                disabled={disabled}
              />
            </th>
            <th>Job STS</th>
            <th>Transaction Date</th>
            <th>Car No</th>
            <th>Employee</th>
            <th>Place</th>
            <th>Bill</th>
            <th>Other Expense</th>
            <th>Remark</th>
          </tr>
        </thead>
        <tbody>{entities}</tbody>
      </table>
    </>
  );
};

export default InvoiceDescription;
