import React, { useState, memo, useEffect, useRef, forwardRef } from "react";
import { Badge, Button } from "react-bootstrap";
import ReactModal from "react-bootstrap/Modal";
import FormInput from "@standard/components/DataForm/FormInput";
import { AlertService } from "@standard/services";
import { PriceRateAPI } from "api";
import { useContext } from "react";
import { PriceRateContext, PRICE_RATE_ACTION } from "./PriceRateFormDetail";
import NumberFormat from "react-number-format";
import _ from "lodash";

const PriceRateEditSourceModal = ({
  onSave,
  disabled,
  label,
  title,
  className = "",
  variant = "info",
  priceRateDefaultEachType,
}) => {
  const { dispatch, datasource, fetchData, customerId, wheel } =
    useContext(PriceRateContext);
  const [show, setShow] = useState(false);
  const [places, setPlaces] = useState({
    sourcePlaces: [],
  });

  const [priceRateTypeId, setPriceRateTypeId] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onSaveModal = () => {
    AlertService.confirm("Do you want to save ?").then((res) => {
      if (res === true) {
        dispatch({ type: PRICE_RATE_ACTION.UPDATE_ALL_SOURCE_PLACE });
        const sourcePlaces = places.sourcePlaces.map((place) => place.key);

        PriceRateAPI.updateAllPlaces({
          customerId,
          wheel,
          sourcePlaces,
          priceRateTypeId,
        }).then(() => {
          setShow(false);
          fetchData();
        });
      }
    });
  };

  // useEffect(() => {
  //   if (priceRateDefaultEachType) {
  //     setPlaces({ sourcePlaces: [] });
  //   }
  // }, [priceRateDefaultEachType]);

  useEffect(() => {
    if (show === false) {
      if (priceRateDefaultEachType) setPlaces({ sourcePlaces: [] });
    }
  }, [show]);

  const onRemovePlace = ({ name, index }) => {
    AlertService.confirm("Do you want to delete ?").then((res) => {
      if (res === true) {
        const newPlaces = [...places[name]].filter(
          (place, placeIndex) => placeIndex !== index
        );

        setPlaces((prev) => {
          return { ...prev, [name]: newPlaces };
        });
      }
    });
  };

  const onInputPlaceChage = ({ name, value }) => {
    if (value === undefined) return;
    if (places[name].findIndex((place) => place.key === value) >= 0) return;

    const newPlaces = [...places[name]];

    const ds = datasource.place.find((f) => f.key === value);
    let placeName = value;
    if (ds) {
      placeName = ds.label;
    }

    newPlaces.push({
      key: value,
      label: placeName,
    });

    setPlaces((prev) => {
      return { ...prev, [name]: newPlaces };
    });
  };

  const onInputPriceRateTypeIdChange = ({ value }) => {
    const priceRate = priceRateDefaultEachType.find((f) => f.id === value);

    if (priceRate) {
      setPlaces({
        sourcePlaces: priceRate.sourcePlaces,
      });
    }

    setPriceRateTypeId(value);
  };

  const placeField = {
    priceRateType: {
      label: "Price Rate Type",
      name: "priceRateTypeId",
      type: "ddl",
      datasource: datasource.priceratetype,
      value: priceRateTypeId,
      placeholder: "Price Rate Type",
    },
    sourcePlace: {
      label: "Source Place",
      name: "sourcePlaces",
      type: "ddl",
      datasource: datasource.place,
      value: null,
      createable: true,
      placeholder: "Source Place",
    },
  };

  return (
    <>
      <Button
        variant={variant}
        onClick={handleShow}
        disabled={disabled}
        className={className}
      >
        {label}
      </Button>

      <ReactModal show={show} onHide={handleClose} size="lg">
        <ReactModal.Header className="bg-primary">
          <ReactModal.Title>{title}</ReactModal.Title>
          <a href="#" className="mt-2 mr-3 text-white" onClick={handleClose}>
            <i className="fa fa-times" style={{ fontSize: "1.5em" }}></i>
          </a>
        </ReactModal.Header>
        <ReactModal.Body>
          <FormInput
            field={placeField.priceRateType}
            onChange={onInputPriceRateTypeIdChange}
          />
          <FormInput
            field={placeField.sourcePlace}
            onChange={onInputPlaceChage}
          />
          <div className="col-12" style={{ marginTop: "-10px" }}>
            {places.sourcePlaces.map((place, placeIndex) => (
              <Badge key={placeIndex} bg="primary" className="text-white mr-1">
                {place.label}{" "}
                <span
                  className="ml-2 cursor-pointer"
                  onClick={() =>
                    onRemovePlace({ name: "sourcePlaces", index: placeIndex })
                  }
                >
                  <i className="fa fa-times text-danger"></i>
                </span>
              </Badge>
            ))}
          </div>
        </ReactModal.Body>
        <ReactModal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSaveModal}>
            Save Changes
          </Button>
        </ReactModal.Footer>
      </ReactModal>
    </>
  );
};

export default memo(PriceRateEditSourceModal);
