import { DateService, UtilsService } from "@standard/services";
import { useCallback } from "react";
import { useState, useEffect, memo } from "react";
import { Badge, Button, Form } from "react-bootstrap";
import InvoiceEmployeeJob from "./InvoiceEmployeeJob";
import InvoiceEmployeeExpense from "./InvoiceEmployeeExpense";

const InvoiceEmployeeDescription = ({ employees, onChange, id }) => {
  const disabled = id === undefined;

  const onChangeCheckbox = (index) => {
    const newEmployees = [...employees];
    newEmployees[index].selected = !newEmployees[index].selected;

    onChange(newEmployees);
  };

  const onChangeCheckboxHead = (e) => {
    const selected = e.target.checked;
    const newEmployees = employees.map((employee) => {
      employee.selected = selected;
      return employee;
    });

    onChange(newEmployees);
  };

  const onJobChange = useCallback(
    (jobs, index) => {
      const newEmployees = [...employees];
      newEmployees[index].jobs = jobs;

      onChange(newEmployees);
    },
    [employees]
  );

  const onExpenseChange = useCallback(
    (expenses, index) => {
      const newEmployees = [...employees];
      newEmployees[index].invoiceEmployeeExpenses = expenses;

      onChange(newEmployees);
    },
    [employees]
  );

  const onSaveJob = (jobs) => {
    const promise = new Promise((resolve) => {
      console.log("Save job", jobs);

      resolve();
    });

    return promise;
  };

  const entities = employees.map((employee, employeeKey) => {
    const checked = employee.selected;

    const totalSTS = employee.jobs.reduce(
      (pre, num) => pre + UtilsService.convertToDecimal(num.sts),
      0
    );
    const totalBill = employee.jobs.reduce(
      (pre, num) => pre + UtilsService.convertToDecimal(num.bill),
      0
    );
    const totalPay = employee.jobs.reduce(
      (pre, num) => pre + UtilsService.convertToDecimal(num.pay),
      0
    );

    return (
      <tr key={employeeKey}>
        <td className="text-center">
          <Form.Check
            inline
            type="checkbox"
            id={`jobSelectedId_${employeeKey}`}
            checked={checked}
            onChange={(e) => onChangeCheckbox(employeeKey)}
          />
        </td>
        <td>{employee.name}</td>
        <td className="text-center">{employee.carNo}</td>
        <td className="text-right">
          {UtilsService.convertToMoney(totalBill, "฿")}
        </td>
        <td className="text-right">
          {UtilsService.convertToMoney(totalSTS, "฿")}
        </td>
        <td className="text-right">
          {UtilsService.convertToMoney(totalPay, "฿")}
        </td>
        <td className="text-center">
          <InvoiceEmployeeJob
            jobs={employee.jobs}
            id={id}
            onChange={(jobs) => onJobChange(jobs, employeeKey)}
            disabled={disabled || employee.selected === false}
          />
        </td>
        <td className="text-center">
          <InvoiceEmployeeExpense
            expenses={employee.invoiceEmployeeExpenses}
            onChange={(expenses) => onExpenseChange(expenses, employeeKey)}
            disabled={disabled || employee.selected === false}
            carNoId={employee.carNoId}
            employeeId={employee.employeeId}
          />
        </td>
      </tr>
    );
  });

  const checkboxHeaderChecked =
    employees.findIndex((f) => f.selected === false) === -1;

  return (
    <>
      <strong>Description:</strong>
      <table className="table">
        <thead>
          <tr>
            <th>
              <Form.Check
                inline
                type="checkbox"
                id={`jobSelectedId`}
                checked={checkboxHeaderChecked}
                onChange={onChangeCheckboxHead}
                // disabled={disabled}
              />
            </th>
            <th>Name</th>
            <th>Car No</th>
            <th>Bill</th>
            <th>STS</th>
            <th>Pay</th>
            <th>Jobs</th>
            <th>Other Expenses</th>
          </tr>
        </thead>
        <tbody>{entities}</tbody>
      </table>
    </>
  );
};

export default memo(InvoiceEmployeeDescription);
