import axios from "@standard/axios";

export const getDashboard = () => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(`dashboard`)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};
