import { memo, useState, useEffect } from "react";
import { employeeTypeData, wheelData } from "data";
import { DateService } from "@standard/services";
import DataTable from "@standard/components/DataTable";
import { DatasourceAPI } from "api";

const InvoiceEmployeeDataTable = ({ url }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [datasource, setDatasource] = useState({
    employeegroup: [],
  });

  const columns = [
    {
      label: "Transaction Date From",
      name: "transactionDate",
      columnName: "transactionDateFrom",
      sortable: true,
      align: "center",
      search: { type: "date", isRange: true },
    },
    {
      label: "Type",
      name: "type",
      sortable: true,
      align: "center",
      search: { type: "checkbox", datasource: employeeTypeData, inline: true },
    },
    {
      label: "Employee Group",
      name: "employeeGroupName",
      sortable: true,
      align: "left",
    },
    {
      label: "Wheel",
      name: "wheel",
      sortable: true,
      align: "center",
      search: { type: "checkbox", datasource: wheelData, inline: true },
    },
  ];

  const config = {
    customFilters: [
      {
        label: "Employee Group",
        name: "employeeGroupId",
        type: "ddl",
        datasource: datasource.employeegroup,
        multiple: true,
      },
    ],
    enableActive: false,
    enableEdit: true,
    enableTrash: true,
    onBeforeBind: (data) => {
      return data.map((item) => {
        const { transactionDateFrom, transactionDateTo, wheel, type } =
          item.values;
        item.values.transactionDate = `${DateService.convertDateToString(
          transactionDateFrom
        )} - ${DateService.convertDateToString(transactionDateTo)}`;
        item.values.wheel = wheel === 4 ? "4 WD" : "6 WD";

        return item;
      });
    },
  };

  useEffect(() => {
    const tables = ["employeegroup"];
    DatasourceAPI.getActiveDatasource(tables).then((ds) => {
      setDatasource(ds);
      setIsLoading(false);
    });
  }, []);

  return <DataTable columns={columns} url={url} {...config} />;
};

export default memo(InvoiceEmployeeDataTable);
