import DataTable from "@standard/components/DataTable";
import { paymentMethodData } from "data";
import { Badge } from "react-bootstrap";
import { useState, useEffect, memo } from "react";
import { DatasourceAPI } from "api";
import axios from "@standard/axios";
import { getURLSearchCriteria } from "@standard/store/reducers/dataTableSlice";
import { useDispatch } from "react-redux";

const ReceiptDataTable = ({ url }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [datasource, setDatasource] = useState({
    customer: [],
    month: [
      { label: "January", key: 1 },
      { label: "February", key: 2 },
      { label: "March", key: 3 },
      { label: "April", key: 4 },
      { label: "May", key: 5 },
      { label: "June", key: 6 },
      { label: "July", key: 7 },
      { label: "August", key: 8 },
      { label: "September", key: 9 },
      { label: "October", key: 10 },
      { label: "November", key: 11 },
      { label: "Decembe", key: 12 },
    ],
    year: [],
  });

  const columns = [
    {
      label: "Customer",
      name: "customerName",
      sortable: true,
      columnName: "customerId",
      search: { type: "ddl", datasource: datasource.customer, multiple: true },
    },
    {
      label: "Receipt No",
      name: "documentNo",
      align: "center",
      sortable: true,
      search: { type: "text" },
    },
    {
      label: "Payment Method",
      name: "paymentMethod",
      sortable: true,
      align: "center",
      search: { type: "checkbox", datasource: paymentMethodData, inline: true },
    },
  ];

  const buttons = [
    {
      variant: "danger",
      label: '<i className="fa fa-print" aria-hidden="true"></i> Export Excel',
      onClick: () => {
        const url = "transaction/receipt/print/report";
        const printUrl = `${axios.defaults.baseURL}${url}`;
        const printUrlWithParam = dispatch(getURLSearchCriteria(printUrl));

        window.open(printUrlWithParam, "_blank");
      },
    },
  ];

  const config = {
    customFilters: [
      {
        label: "Month",
        name: "month",
        type: "ddl",
        datasource: datasource.month,
        col: 6,
        defaultValue: null,
      },
      {
        label: "Year",
        name: "year",
        type: "ddl",
        datasource: datasource.year,
        col: 6,
        defaultValue: null,
      },
    ],
    enableActive: false,
    enableEdit: true,
    enableTrash: true,
    buttons: buttons,
    onBeforeBind: (data) => {
      return data.map((item) => {
        const { paymentMethod, customerName, isLocked } = item.values;

        item.values.customerName = (
          <>
            {isLocked ? (
              <i className="fa fa-lock" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-unlock" aria-hidden="true"></i>
            )}{" "}
            {customerName}
          </>
        );

        item.values.paymentMethod =
          paymentMethod === "cash" ? (
            <Badge className="p-2" bg="primary">
              Cash
            </Badge>
          ) : (
            <Badge className="p-2" bg="danger">
              Cheque
            </Badge>
          );
        return item;
      });
    },
  };

  useEffect(() => {
    const tables = ["customer"];

    DatasourceAPI.getActiveDatasource(tables).then((ds) => {
      const currentYear = new Date().getFullYear() - 5;
      const yearDatasource = new Array(10).fill(null).map((item, itemIndex) => {
        return {
          key: currentYear + itemIndex,
          label: (currentYear + itemIndex).toString(),
        };
      });

      ds.year = yearDatasource;

      setDatasource({ ...datasource, ...ds });
      setIsLoading(false);
    });
  }, []);

  return <DataTable columns={columns} url={url} {...config} />;
};

export default memo(ReceiptDataTable);
