import { useState } from "react";
import { Badge } from "react-bootstrap";

const PriceRatePlaceName = ({ label, places, bg = "primary" }) => {
  const [isShowFull, setIsShowFull] = useState(false);
  const limit = 7;

  return (
    <>
      <span className="position-relative">
        {label}{" "}
        <Badge
          pill
          bg="info"
          style={{ top: "-7px" }}
          className="position-absolute ml-1"
        >
          {places.length}
        </Badge>
      </span>
      <div className={"alert alert-" + bg}>
        <ul className="list-unstyled">
          {places
            .slice(0, isShowFull ? places.length : limit)
            .map((place, placeIndex) => (
              <li key={placeIndex}>
                - {place.label}
                <br />
              </li>
            ))}
        </ul>
        {places.length > limit ? (
          <div className="text-right mt-1">
            {isShowFull ? (
              <a
                className="text-link cursor-pointer"
                onClick={() => setIsShowFull(false)}
              >
                Less...
              </a>
            ) : (
              <a
                className="text-link cursor-pointer"
                onClick={() => setIsShowFull(true)}
              >
                More...
              </a>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default PriceRatePlaceName;
