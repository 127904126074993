import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  errors: null,
};

export const containerSlice = createSlice({
  name: "container",
  initialState,
  reducers: {
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    clearErrors: (state, action) => {
      state.errors = null;
    },
  },
});

export const { setErrors, setLoading, clearErrors } = containerSlice.actions;

export const getState = (state) => state.container;

export default containerSlice.reducer;
