import TitleHeader from "@standard/components/TitleHeader";
import UserForm from "./UserForm";
import UserTable from "./UserTable";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useEffect } from "react";

const programType = "Master";
const programName = "User";
const url = "master/user";

export default function User() {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <UserTable url={url} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <UserForm url={url} />
        </Route>
      </Switch>
    </>
  );
}
