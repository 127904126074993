import { setSearchCriteria } from "@standard/store/reducers/dataTableSlice";
import { useEffect, memo, useState, useMemo } from "react";
import { Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "../DataForm/FormInput";
import { useLocation } from "react-router-dom";

const getFilterFields = (columns, customFilters, enableActive) => {
  const fields = columns
    .filter((f) => f.search !== undefined)
    .map((item) => {
      const field = {
        ...item.search,
        label: item.label,
        name: item.name,
        columnName: item.columnName,
      };

      return field;
    });

  if (customFilters !== undefined) {
    customFilters.map((item) => {
      const field = {
        ...item,
      };

      fields.push(field);
    });
  }

  if (enableActive === true) {
    const checkboxDatasource = [
      { key: 1, label: "Active" },
      { key: 0, label: "Inactive" },
    ];
    fields.push({
      label: "Active",
      name: "active",
      type: "checkbox",
      inline: true,
      datasource: checkboxDatasource,
    });
  }

  return fields;
};

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

function SearchCriteriaFilter({
  toggleAdvanceSearch,
  onSetToggleAdvanceSearch,
}) {
  const dispatch = useDispatch();
  const { searchCriteria, config } = useSelector((state) => state.dataTable);
  const { columns, customFilters } = config;
  const [filterFields, setFilterFields] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});

  // let query = useQuery();

  const { handleSubmit, reset, resetField, setValue, register, getValues } =
    useForm({
      defaultValues: defaultValues,
    });

  // useEffect(() => {
  //   const filterParams = JSON.parse(query.get("filters"));
  //   // const filters = [];
  //   for (let i in filterParams) {
  //     // filters.push({ name: i, value: filterParams[i], label: i });
  //     setValue(i, filterParams[i]);
  //     console.log("SearchCriteriaFilter:84 =>", i, filterParams[i]);
  //   }
  //   // dispatch(setSearchCriteria({ filters }));
  //   // console.log("Query DataTable:50 =>", query.get("filters"));
  // }, [query]);

  const onResetField = () => {
    reset(defaultValues);
    dispatch(setSearchCriteria({ filters: [] }));
  };

  const onSubmitForm = (data) => {
    const filters = [];
    for (const key in data) {
      if (data[key] === undefined || data[key] === "") continue;
      if (data[key] instanceof Array && data[key].length === 0) continue;

      const filterField = filterFields.find((f) => f.name === key);

      filters.push({
        ...filterField,
        name: key,
        value: data[key],
      });
    }

    dispatch(setSearchCriteria({ filters: filters }));
    onSetToggleAdvanceSearch(false);
  };

  const onChangeInput = ({ name, value }) => {
    setValue(name, value);
  };

  const initField = () => {
    const newFilterFields = getFilterFields(
      columns,
      customFilters,
      config.enableActive
    );
    const defaultValueNewFields = {};
    newFilterFields.map((m) => {
      if (m.defaultValue) {
        defaultValueNewFields[m.name] = m.defaultValue;
      } else if (
        m.type === "checkbox" ||
        (m.type === "ddl" && m.multiple === true)
      ) {
        defaultValueNewFields[m.name] = [];
      } else {
        defaultValueNewFields[m.name] = "";
      }
    });
    setFilterFields(newFilterFields);
    reset(defaultValueNewFields);
    setDefaultValues(defaultValueNewFields);
  };

  useEffect(() => {
    initField();
  }, [columns, customFilters, config.enableActive]);

  useEffect(() => {
    // renew value
    if (toggleAdvanceSearch === true) {
      reset(defaultValues);
      searchCriteria.filters.map((filter) => {
        setValue(filter.name, filter.value);
      });
    }
  }, [toggleAdvanceSearch]);

  const advanceSearchControl = filterFields.map((field, index) => {
    const value = getValues(field.name);

    if (value !== undefined) {
      field.value = value;
    } else {
      if (field.type === "checkbox") {
        field.value = [];
      } else {
        field.value = "";
      }
    }

    return (
      <FormInput
        key={index}
        field={field}
        {...register(field.name, {})}
        onChange={onChangeInput}
      />
    );
  });

  return (
    <Form onSubmit={handleSubmit(onSubmitForm)}>
      <div
        className={
          "advance-search-container container py-3 " +
          (toggleAdvanceSearch ? "show" : "")
        }
      >
        <div className="text-right">
          <Button
            type="button"
            variant="secondary"
            className="btn-circle"
            onClick={() => onSetToggleAdvanceSearch(!toggleAdvanceSearch)}
          >
            <i className="glyphicon glyphicon-remove"></i>
          </Button>
        </div>
        <div className="row">{advanceSearchControl}</div>
        <div className="text-right">
          <Button type="reset" variant="link" onClick={onResetField}>
            Reset
          </Button>
          <Button type="submit" variant="primary" className="ml-2">
            Search
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default memo(SearchCriteriaFilter);
