import DataTable from "@standard/components/DataTable";
import TitleHeader from "@standard/components/TitleHeader";
import JobForm from "./JobForm";
import JobDataTable from "./JobDataTable";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Badge, Button } from "react-bootstrap";
import { UtilsService, DateService } from "@standard/services";
import axios from "@standard/axios";

const programType = "Transaction";
const programName = "Job";
const url = "transaction/job";

export default function Job() {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <JobDataTable url={url} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <JobForm url={url} />
        </Route>
      </Switch>
    </>
  );
}
