import { UtilsService } from "@standard/services";
import React, { useState, useEffect, useRef } from "react";
import Switcher from "./../Switcher/Switcher";
import NumberFormat from "react-number-format";
import Dropdown from "../Dropdown/Dropdown";
import DatePicker from "../DatePicker";
import FileInput from "../FileInput";
import TimePicker from "../TimePicker/TimePicker";
// import RadioInput from "../RadioInput/RadioInput";
// import Checkbox from "../Checkbox/Checkbox";
// import TextEditor from '../TextEditor/TextEditor';
import { Form } from "react-bootstrap";
import "./style.css";
const $ = window.$;

// import axios from "../../axios";

const FormInput = React.forwardRef(({ field, onChange }, ref) => {
  const refInput = useRef(null);

  let isShowLabel = field.label !== undefined && field.label !== "";

  const [fieldValue, setFieldValue] = useState(field.value);

  const onValueChange = ({ name, value, ...params }) => {
    if (onChange !== null) onChange({ name, value, ...params });
    setFieldValue(value);
  };

  const onMultipleChange = ({ name, value }) => {
    let castValue = UtilsService.isNumeric(value)
      ? UtilsService.convertToInt(value)
      : value;

    let newValue = [];
    if (fieldValue instanceof Array) {
      newValue = [...fieldValue];
    } else {
      newValue = [];
    }

    const index = newValue.indexOf(castValue);

    // console.log("FormInput:37 =>", name, value, castValue, index);

    if (index === -1) {
      newValue.push(castValue);
    } else {
      newValue.splice(index, 1);
    }

    console.log(
      "FormInput:47 =>",
      newValue,
      castValue,
      typeof castValue,
      value
    );

    onChange({ name, value: newValue });
    setFieldValue(newValue);
  };

  useEffect(() => {
    if (field.type === "autocomplete") {
      $(refInput.current).autocomplete({
        source: field.datasource,
        select: (e, ui) => {
          onValueChange({ name: field.name, value: ui.item.value });
        },
        minLength: 0,
      });
    }
  }, [field.datasource]);

  useEffect(() => {
    if (field.value !== fieldValue) {
      setFieldValue(field.value);
    }
  }, [field.value]);

  // onImageUpload = (images, callback) => {
  //   const request_options = {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //     },
  //   };

  //   let form_data = new FormData();
  //   if (!images.length) return;

  //   for (let i = 0; i < images.length; i++) {
  //     form_data.append("files[]", images[i], images[i].name);
  //   }

  //   axios
  //     .post(Config_url.upload_asset, form_data, request_options)
  //     .then((res) => {
  //       res.data.map((data) => {
  //         const url = data.url;
  //         const file_name = data.file_name;
  //         return callback(url, file_name);
  //       });
  //     });
  // };

  // switch (field.type) {
  //   case "percent":
  //     input = (
  //       <div className="input-group">
  //         <input
  //           type="number"
  //           className="form-control"
  //           id={field.name}
  //           name={field.name}
  //           required={field.required}
  //           placeholder={field.placeholder}
  //           defaultValue={field.value || ""}
  //           onChange={(e) => this.onChange(e)}
  //           disabled={field.disabled}
  //         />
  //         <span className="input-group-addon">
  //           {" "}
  //           <i className="fa fa-percent"></i>{" "}
  //         </span>{" "}
  //       </div>
  //     );
  //     break;
  //   // case 'texteditor':
  //   //   input = <TextEditor onChange={e => this.onChange(e)} name={field.name} value={field.value} onImageUpload={this.onImageUpload}></TextEditor>;
  //   //   break;
  // }

  let label = "";
  let input = "";
  const labelContainerClassName = [
    "form-group",
    field.type === "label" || field.inline === true ? "mb-0" : "mb-3",
  ];

  if (
    field.type === "text" ||
    field.type === "password" ||
    field.type === "email" ||
    field.type === "url"
  ) {
    input = (
      <Form.Control
        ref={refInput}
        name={field.name}
        type={field.type}
        placeholder={field.label}
        value={fieldValue}
        disabled={field.disabled}
        onChange={(e) =>
          onValueChange({ name: field.name, value: e.target.value })
        }
      />
    );
  } else if (field.type === "autocomplete") {
    input = (
      <Form.Control
        ref={refInput}
        name={field.name}
        type={"text"}
        className="text-autocomplete"
        placeholder={field.label}
        value={fieldValue}
        disabled={field.disabled}
        onChange={(e) =>
          onValueChange({ name: field.name, value: e.target.value })
        }
      />
    );
  } else if (field.type === "number") {
    let decimal = 0;
    if (field.decimal) {
      decimal = parseInt(field.decimal);
    }

    input = (
      <NumberFormat
        ref={refInput}
        value={fieldValue}
        thousandSeparator={true}
        className="form-control"
        id={field.id}
        name={field.name}
        required={field.required}
        prefix={""}
        decimalScale={decimal}
        decimalSeparator="."
        disabled={field.disabled}
        onValueChange={(values) => {
          const { formattedValue, value } = values;
          onValueChange({ name: field.name, value: value });
          // if (field.onChange) field.onChange({ [field.name]: value });
          // formattedValue = $2,223
          // value ie, 2223
          // this.setState({ profit: formattedValue });
        }}
      />
    );
  } else if (field.type === "timepicker") {
    input = (
      <TimePicker
        name={field.name}
        // onChange={field.onChange}
        value={field.value || ""}
        required={field.required}
        disabled={field.disabled}
        placeholder={field.placeholder || ""}
      ></TimePicker>
    );
  } else if (field.type === "date") {
    input = (
      <DatePicker
        name={field.name}
        onChange={({ name, value }) =>
          onValueChange({ name: field.name, value: value })
        }
        value={field.value || ""}
        isRange={field.isRange}
        required={field.required}
        disabled={field.disabled}
        placeholder={field.placeholder || ""}
        align={field.align}
      ></DatePicker>
    );
  } else if (field.type === "ddl") {
    input = (
      <Dropdown
        multiple={field.multiple}
        id={field.name}
        name={field.name}
        datasource={field.datasource}
        required={field.required}
        disabled={field.disabled}
        placeholder={field.placeholder}
        value={fieldValue}
        onChange={(e) => {
          if (field.multiple) {
            // console.log("FormInput:242 =>", fieldValue, e[field.name]);
            onValueChange({
              name: field.name,
              value: e[field.name],
              isNew: e.isNew,
            });
          } else {
            onValueChange({
              name: field.name,
              value: e[field.name],
              isNew: e.isNew,
            });
          }
        }}
        createable={field.createable}
      ></Dropdown>
    );
  } else if (field.type === "textarea") {
    input = (
      <Form.Control
        as="textarea"
        rows={3}
        name={field.name}
        placeholder={field.label}
        value={fieldValue}
        // ref={ref}
        disabled={field.disabled}
        onChange={(e) =>
          onValueChange({ name: field.name, value: e.target.value })
        }
      />
    );
  } else if (field.type === "file") {
    input = (
      <FileInput
        onDelete={field.onDelete}
        showUpload={field.showUpload}
        multiple={field.multiple}
        extension={field.extension}
        onChange={field.onChange}
        name={field.name}
        value={field.value}
        view_mode={field.view_mode}
        required={field.required}
        disabled={field.disabled}
      ></FileInput>
    );
  } else if (field.type === "checkbox" || field.type === "radio") {
    if (field.datasource !== undefined) {
      input = field.datasource.map((item) => {
        let checked = false;

        if (fieldValue !== undefined) {
          if (field.type === "checkbox") {
            checked = fieldValue.indexOf(item.key) !== -1;
          } else if (field.type === "radio") {
            checked = String(fieldValue) === String(item.key);
          }
        }

        return (
          <Form.Check
            key={item.key}
            className={field.inline ? "mb-0" : "mb-2"}
            type={field.type}
            id={`${field.name}_${item.key}`}
            label={item.label}
            value={item.key}
            // ref={ref}
            inline={field.inline}
            checked={checked}
            disabled={field.disabled}
            onChange={(e) => {
              if (field.type === "checkbox") {
                onMultipleChange({ name: field.name, value: e.target.value });
              } else {
                onValueChange({ name: field.name, value: e.target.value });
              }
            }}
          />
        );
      });
    } else {
      isShowLabel = false;
      let checked = fieldValue ?? false;

      input = (
        <Form.Check
          className={field.inline ? "mb-0" : "mb-2"}
          type={field.type}
          id={field.name}
          label={field.label}
          inline={field.inline}
          checked={checked}
          disabled={field.disabled}
          onChange={(e) => {
            onValueChange({ name: field.name, value: e.target.checked });
          }}
        />
      );
    }
  } else if (field.type === "switch") {
    input = (
      <Switcher
        // ref={ref}
        name={field.name}
        checked={fieldValue}
        disabled={field.disabled}
        onChange={(value) => {
          onValueChange({ name: field.name, value });
        }}
      />
    );
  } else if (field.type === "label") {
    if (UtilsService.isReactElement(field.value)) {
      input = field.value;
    } else {
      input = UtilsService.htmlParse(field.value);
    }
  }

  let uom = "";
  let prefix = "";
  if (field.uom) {
    uom = (
      <div className="input-group-append">
        <span className="input-group-text" id="basic-addon2">
          {field.uom}
        </span>
      </div>
    );
  }

  if (field.prefix) {
    prefix = <span class="input-group-text">{field.prefix}</span>;
  }

  if (uom || prefix) {
    input = (
      <div className="input-group input-group-sm mb-1">
        {prefix}
        {input}
        {uom}
      </div>
    );
  }

  let description = "";
  if (field.description)
    description = (
      <Form.Text className="text-muted">{field.description}</Form.Text>
    );

  if (isShowLabel) {
    const labelClassName = ["col-form-label"];
    if (field.required) labelClassName.push("field-required");

    label = (
      <Form.Label className={labelClassName.join(" ")}>
        {field.label}:
      </Form.Label>
    );
  } else {
    return (
      <>
        {input}
        {description}
      </>
    );
  }

  const columnMerge = [];
  if (field.col !== undefined) {
    columnMerge.push("col-" + field.col);
  } else {
    columnMerge.push("col-12");
  }

  return (
    <div className={columnMerge.join(" ")}>
      <Form.Group
        className={labelContainerClassName.join(" ")}
        controlId={field.name}
      >
        {label} {input}
        {description}
      </Form.Group>
    </div>
  );
});

FormInput.defaultProps = {
  field: {}, // type, value, name, required, label, datasource, onChange
  onChange: null,
  // onDeleteFile: null, // function delete
};

export default FormInput;
