import React, { useState, useEffect } from "react";

import "./DataTable.css";
import SearchCriteria from "./SearchCriteria";
import SearchResult from "./SearchResult";
import SearchTool from "./SearchTool";
import Pagination from "./../Pagination/Pagination";
import useAxiosFetch from "@standard/hooks/useAxiosFetch";
import {
  setConfig,
  changePageNumber,
  fetchData,
  setSearchCriteria,
  clearSearchCriteria,
  setDefault,
} from "@standard/store/reducers/dataTableSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../BoxContainer/Loader";
import _ from "lodash";
import { UtilsService } from "@standard/services";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DataTable = (props) => {
  let query = useQuery();
  const dispatch = useDispatch();

  let location = useLocation();
  useEffect(() => {
    dispatch(clearSearchCriteria());
  }, [location.pathname]);

  const {
    searchCriteria,
    config,
    data,
    isLoading,
    totalRecord,
    totalPage,
    startRowRunning,
    endRowRunning,
  } = useSelector((state) => state.dataTable);

  useEffect(() => {
    dispatch(setConfig({ ...props }));
  }, [props]);

  useEffect(() => {
    const getLabel = (name) => {
      let column = props.columns.find((f) => f.name === name);
      if (!column) {
        column = props.customFilters.find((f) => f.name === name);

        if (column) return column.label;
      }

      return name;
    };
    const filterParams = JSON.parse(query.get("filters"));
    const filters = [];
    for (let i in filterParams) {
      const label = getLabel(i);
      filters.push({ name: i, value: filterParams[i], label: label });
    }

    if (!_.isEmpty(filters)) {
      dispatch(setSearchCriteria({ filters }));
    }
  }, [query]);

  useEffect(() => {
    dispatch(setDefault());
  }, []);

  useEffect(() => {
    dispatch(fetchData());
  }, [searchCriteria]);

  const onPageChange = (pageNumber) => {
    dispatch(changePageNumber(pageNumber));
    UtilsService.goToTop();
  };

  let table = (
    <div className="col-12">
      <div className="ibox">
        <div
          className={"ibox-content " + (isLoading === true ? "sk-loading" : "")}
        >
          <Loader isLoading={isLoading} />
          <div className="mb-2">
            <SearchCriteria />
          </div>
          <SearchTool />
          <SearchResult />
          <div>
            <div className="pull-right">
              <Pagination
                totalPage={totalPage}
                selectedPage={searchCriteria.pageNumber}
                onPageChange={onPageChange}
              />
            </div>
            <div className="pull-left">
              Showing {startRowRunning} to {endRowRunning} of {totalRecord}{" "}
              records
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="row wrapper wrapper-content animated fadeInRight"
      style={{ minHeight: "800px" }}
    >
      {table}
    </div>
  );
};
// }

DataTable.defaultProps = {
  columns: [
    {
      label: "column #1",
      align: "left",
      name: "col1",
      searchType: "string",
      sortable: false,
      search: {
        range: false,
        type: "string",
        datasource: [{ key: "", label: "" }],
      },
    }, // for advance search use search_type = 'string', 'datepicker', 'checkbox', 'ddl' <-- request ddl_source
  ],
  url: "", // API Url
  enableActive: false,
  enablePrint: false,
  enableInsert: true,
  enableTrash: true,
  enableView: false,
  enableEdit: true,
  enableCheckbox: false,
  enableAction: true,
  enableImport: false,
  onPrintClick: [],
  buttons: [], // [<button onClick={() => doSomething()}></button>]
  buttonsRow: [],
  beforeBind: null,
};

export default DataTable;
