import Loader from "@standard/components/BoxContainer/Loader";
import { DateService, UtilsService } from "@standard/services";
import { DashboardAPI } from "api";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DashboardList from "./DashboardList";

function Dashboard() {
  const defaultData = {
    jobAmount: 0,
    invoiceAmount: 0,
    receiptAmount: 0,
    dnReturnAmount: 0,
    jobsOverDuedate: {
      data: [],
      totalrecord: 0,
    },
    invoicesOverDuedate: {
      data: [],
      totalrecord: 0,
    },
    employeeOutOfWarranty: {
      data: [],
      totalrecord: 0,
    },
  };

  const [data, setData] = useState(defaultData);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    DashboardAPI.getDashboard().then((dashboardData) => {
      setData(dashboardData);
      setIsLoading(false);
    });
  }, []);

  const jobFilters = { isOverDuedate: true };
  const jobUrl = `/transaction/job?filters=${encodeURIComponent(
    JSON.stringify(jobFilters)
  )}`;

  const invoiceFilters = { isOverDuedate: true };
  const invoiceUrl = `/transaction/invoice?filters=${encodeURIComponent(
    JSON.stringify(invoiceFilters)
  )}`;

  const employeeFilters = { isOutOfWarranty: true };
  const employeeUrl = `/master/employee?filters=${encodeURIComponent(
    JSON.stringify(employeeFilters)
  )}`;

  return (
    <>
      <div
        className={
          "wrapper wrapper-content box-container " +
          (isLoading ? "sk-loading" : "")
        }
      >
        <Loader isLoading={isLoading}></Loader>
        <div className="row">
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <div className="ibox-tools">
                  <span className="label label-primary float-right">
                    {UtilsService.convertToMoney(data.dnReturnAmount)}
                  </span>
                </div>
                <h5>DN Return</h5>
              </div>
              <div className="ibox-content">
                <Link
                  to="/transaction/dnreturn/create"
                  className="btn btn-primary py-4 btn-block"
                >
                  <i className="fa fa-plus"></i> Create
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <div className="ibox-tools">
                  <span className="label label-info float-right">
                    {UtilsService.convertToMoney(data.jobAmount)}
                  </span>
                </div>
                <h5>Job</h5>
              </div>
              <div className="ibox-content">
                <Link
                  to="/transaction/job/create"
                  className="btn btn-info py-4 btn-block"
                >
                  <i className="fa fa-plus"></i> Create
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <div className="ibox-tools">
                  <span className="label label-success float-right">
                    {UtilsService.convertToMoney(data.invoiceAmount)}
                  </span>
                </div>
                <h5>Invoice</h5>
              </div>
              <div className="ibox-content">
                <Link
                  to="/transaction/invoice/create"
                  className="btn btn-success py-4 btn-block"
                >
                  <i className="fa fa-plus"></i> Create
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <div className="ibox-tools">
                  <span className="label label-warning float-right">
                    {UtilsService.convertToMoney(data.receiptAmount)}
                  </span>
                </div>
                <h5>Receipt</h5>
              </div>
              <div className="ibox-content">
                <Link
                  to="/transaction/receipt/create"
                  className="btn btn-warning py-4 btn-block"
                >
                  <i className="fa fa-plus"></i> Create
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <DashboardList
              url={jobUrl}
              data={data.jobsOverDuedate.data}
              totalrecord={data.jobsOverDuedate.totalrecord}
              title="Jobs"
              editUrl="/transaction/job/edit/"
            />
          </div>
          <div className="col-lg-6">
            <DashboardList
              url={invoiceUrl}
              data={data.invoicesOverDuedate.data}
              totalrecord={data.invoicesOverDuedate.totalrecord}
              title="Invoices"
              editUrl="/transaction/invoice/edit/"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <DashboardList
              url={employeeUrl}
              data={data.employeeOutOfWarranty.data}
              totalrecord={data.employeeOutOfWarranty.totalrecord}
              title="Employee out of warranty"
              editUrl="/master/employee/edit/"
            />
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
}

export default Dashboard;
