import axios from "@standard/axios";

// export const get = (id = null) => {
//   const promise = new Promise((resolve, reject) => {
//     axios
//       .put(`master/user/${id}`)
//       .then((res) => resolve(res.data))
//       .catch((errors) => reject(errors));
//   });

//   return promise;
// };

export const resetPassword = (id) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .put(`master/user/${id}/resetpassword`)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};
export const unlocked = (id) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .put(`master/user/${id}/unlocked`)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

// export const create = (entity) =>
//   new Promise((resolve, reject) => {
//     axios
//       .post(`master/user`, entity)
//       .then((res) => resolve(res.data))
//       .catch((errors) => reject(errors));
//   });

// export const update = (id, entity) =>
//   new Promise((resolve, reject) => {
//     axios
//       .put(`master/user/${id}`, entity)
//       .then((res) => resolve(res.data))
//       .catch((errors) => reject(errors));
//   });

// export const remove = async (id) =>
//   new Promise((resolve, reject) => resolve({}));
