import swal from "sweetalert2";
import { LABEL } from "../constants";

const alert = {
  confirm(text = LABEL.ALERT.TEXT.CONFIRM, title = LABEL.ALERT.TITLE.CONFIRM) {
    const promise = new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: text,
          icon: LABEL.ALERT.ICON.WARNING,
          confirmButtonText: "OK",
          showCancelButton: true,
        })
        .then((res) => {
          resolve(res.isConfirmed);
        });
    });

    return promise;
  },
  done(text = LABEL.ALERT.TEXT.SUCCESS, title = LABEL.ALERT.TITLE.SUCCESS) {
    const promise = new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: text,
          icon: LABEL.ALERT.ICON.SUCCESS,
          // button: LABEL.ALERT.BUTTON.OK,
        })
        .then((res) => {
          resolve(res);
        });
    });

    return promise;
  },
  error(text = LABEL.ALERT.TEXT.ERROR, title = LABEL.ALERT.TITLE.ERROR) {
    const promise = new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: text,
          icon: LABEL.ALERT.ICON.ERROR,
          // button: LABEL.ALERT.BUTTON.OK,
        })
        .then((res) => {
          resolve(res);
        });
    });

    return promise;
  },warning(text = LABEL.ALERT.TEXT.WARNING, title = LABEL.ALERT.TITLE.WARNING) {
    const promise = new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: text,
          icon: LABEL.ALERT.ICON.WARNING,
          // button: LABEL.ALERT.BUTTON.OK,
        })
        .then((res) => {
          resolve(res);
        });
    });

    return promise;
  },
};

export default alert;
