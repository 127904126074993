import { UtilsService } from "@standard/services";
import { useState, useEffect, memo } from "react";
import ErrorContainer from "./ErrorContainer.js";
import Loader from "./Loader.js";

const BoxContainer = ({ loading, children, errors }) => {
  // getErrorContainer = () => {
  //   const errors = errors.map((error_message, index) => {
  //     if (Array.isArray(error_message)) {
  //       if (error_message.length > 0) {
  //         return error_message.map((error_list, _index) => {
  //           return (
  //             <li key={_index}>
  //               <i className="fa fa-times"></i>{" "}
  //               {UtilsService.htmlParse(error_list)}
  //             </li>
  //           );
  //         });
  //       }
  //     }
  //     return (
  //       <li key={index}>
  //         <i className="fa fa-times"></i> {error_message}
  //       </li>
  //     );
  //   });

  //   if (errors.length > 0) {
  //     const errorContainer = (
  //       <div className="alert alert-danger" role="alert">
  //         <h3 className="alert-heading">มีข้อผิดพลาด !!!</h3>
  //         <ul className="list-unstyled">{errors}</ul>
  //       </div>
  //     );

  //     return errorContainer;
  //   }

  //   return;
  // };

  // const errorContainer = this.getErrorContainer();
  const onClearError = () => {};

  return (
    <div className="row wrapper wrapper-content animated fadeInRight">
      <div className="col-12">
        <div className="ibox">
          <div
            style={{ paddingBottom: "30px" }}
            className={
              "box-container ibox-content border-top-0 " +
              (loading ? "sk-loading" : "")
            }
          >
            <ErrorContainer
              errors={errors}
              onClearError={onClearError}
              col={12}
            />
            <Loader isLoading={loading}></Loader>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

BoxContainer.defaultProps = {
  loading: true,
  errors: [],
};

export default memo(BoxContainer);
