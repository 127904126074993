import { memo } from "react";
import DataFormButtonGroup from "./DataFormButtonGroup";

const DataFormFooter = ({}) => {
  return (
    <div className="form-group row">
      <div className="col-12 col-md-6 text-left"></div>
      <DataFormButtonGroup />
    </div>
  );
};

export default memo(DataFormFooter);
