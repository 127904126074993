import { useState, useEffect, memo } from "react";
import axios from "@standard/axios";
import DataTable from "@standard/components/DataTable";
import { invoiceStatusData } from "data";
import { Badge, Button } from "react-bootstrap";
import { AlertService, DateService } from "@standard/services";
import InvoiceStatus from "./InvoiceStatus";
import { DatasourceAPI } from "api";
import Swal from "sweetalert2";

const InvoiceDataTable = ({ url }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [datasource, setDatasource] = useState({
    customer: [],
    typeoftransport: [],
    month: [
      { label: "January", key: 1 },
      { label: "February", key: 2 },
      { label: "March", key: 3 },
      { label: "April", key: 4 },
      { label: "May", key: 5 },
      { label: "June", key: 6 },
      { label: "July", key: 7 },
      { label: "August", key: 8 },
      { label: "September", key: 9 },
      { label: "October", key: 10 },
      { label: "November", key: 11 },
      { label: "Decembe", key: 12 },
    ],
    year: [],
  });

  const columns = [
    {
      label: "Customer",
      name: "customerName",
      sortable: true,
      columnName: "customerId",
      search: { type: "ddl", datasource: datasource.customer, multiple: true },
    },
    {
      label: "Invoice No.",
      name: "documentNo",
      sortable: true,
      align: "center",
      search: { type: "text" },
    },
    {
      label: "Transaction Date",
      name: "transactionDate",
      columnName: "transactionDateFrom",
      align: "center",
      sortable: true,
      search: { type: "date", isRange: true },
    },
    {
      label: "Type of Transport",
      name: "typeOfTransport",
      sortable: true,
      columnName: "typeOfTransportId",
      search: {
        type: "ddl",
        datasource: datasource.typeoftransport,
        multiple: true,
      },
    },
    {
      label: "Receipt No.",
      name: "receiptNo",
      sortable: true,
      align: "center",
      search: { type: "text" },
    },
  ];

  const buttons = [
    {
      variant: "info",
      label: '<i className="fa fa-print" aria-hidden="true"></i> Export Group',
      onClick: (selectedId, searchCriteria) => {
        if (selectedId.length === 0) {
          AlertService.warning("Please select item to export");
          return;
        }

        const listId = selectedId.join("|");
        const printUrl = `${axios.defaults.baseURL}${url}/printcover/${listId}/NO`;
        window.open(printUrl, "_blank");
      },
    },
    {
      variant: "danger",
      label: '<i className="fa fa-print" aria-hidden="true"></i> Export Cover',
      onClick: (selectedId, searchCriteria) => {
        if (selectedId.length === 0) {
          AlertService.warning("Please select item to export");
          return;
        }

        Swal.fire({
          title: "Select Cover",
          input: "select",
          inputOptions: {
            INV15: "Invoice Cover + Customer",
            INV16: "Invoice Cover",
          },
          inputPlaceholder: "Select Cover",
          showCancelButton: true,
        }).then(({ value: invoiceCode }) => {
          if (invoiceCode === undefined) return;
          if (invoiceCode) {
            const listId = selectedId.join("|");
            const printUrl = `${axios.defaults.baseURL}${url}/printcover/${listId}/${invoiceCode}`;
            window.open(printUrl, "_blank");
          } else {
            AlertService.warning("Please select invoice cover");
          }
        });
      },
    },
  ];

  const config = {
    enableActive: false,
    enableEdit: true,
    enableTrash: true,
    enableCheckbox: true,
    buttons: buttons,
    customFilters: [
      {
        label: "Month",
        name: "transactionmonth",
        type: "ddl",
        datasource: datasource.month,
        col: 6,
        defaultValue: null,
      },
      {
        label: "Year",
        name: "transactionyear",
        type: "ddl",
        datasource: datasource.year,
        col: 6,
        defaultValue: null,
      },
      {
        label: "Over Duedate",
        name: "isOverDuedate",
        type: "switch",
      },
    ],
    onBeforeBind: (data) => {
      return data.map((item) => {
        const {
          transactionDateFrom,
          transactionDateTo,
          customerName,
          receipts,
          receiptId,
          isLocked,
        } = item.values;
        item.values.customerName = (
          <>
            {isLocked ? (
              <i className="fa fa-lock" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-unlock" aria-hidden="true"></i>
            )}{" "}
            {customerName}
          </>
        );
        item.values.transactionDate = `${DateService.convertDateToString(
          transactionDateFrom
        )} - ${DateService.convertDateToString(transactionDateTo)}`;

        item.values.receiptNo = "-";

        if (receipts && receipts.length > 0) {
          item.values.receiptNo = (
            <>
              {receipts.map((receipt) => {
                return (
                  <Button
                    variant="link"
                    className="p-1 text-link"
                    target="_blank"
                    href={`/transaction/receipt/edit/${receipt._id}`}
                  >
                    {receipt.documentNo}
                  </Button>
                );
              })}
            </>
          );
        }

        return item;
      });
    },
  };

  useEffect(() => {
    const tables = ["customer", "typeoftransport"];

    DatasourceAPI.getActiveDatasource(tables).then((ds) => {
      const currentYear = new Date().getFullYear() - 5;
      const yearDatasource = new Array(10).fill(null).map((item, itemIndex) => {
        return {
          key: currentYear + itemIndex,
          label: (currentYear + itemIndex).toString(),
        };
      });

      ds.year = yearDatasource;

      setDatasource({ ...datasource, ...ds });
      setIsLoading(false);
    });
  }, []);

  return <DataTable columns={columns} url={url} {...config} />;
};

export default memo(InvoiceDataTable);
