import { dnReturnPrintStatus } from "data/dnReturnPrintStatus";
import { dnReturnPrintStatusData } from "data";
import { memo } from "react";

const DNReturnPrintStatus = ({ printStatus }) => {
  let text = dnReturnPrintStatusData.find((f) => f.key === printStatus)?.label;
  let className = ["badge p-2"];

  switch (printStatus) {
    case dnReturnPrintStatus.PRINTED:
      className.push("badge-primary");
      break;
    case dnReturnPrintStatus.UNPRINTED:
      className.push("badge-danger");
      break;
  }

  return <span className={className.join(" ")}>{text}</span>;
};

export default memo(DNReturnPrintStatus);
