import { Tabs, Tab, Badge } from "react-bootstrap";
import React, {
  useEffect,
  useState,
  memo,
  useCallback,
  useContext,
} from "react";
import { resetConfigure } from "axios-hooks";
import { UtilsService } from "@standard/services";
import FormInput from "./FormInput";
import { Controller, useForm } from "react-hook-form";
import { DataFormContext } from "./DataForm";

const DataFormInput = ({ field, register, onChange }) => {
  const onChangeValue = useCallback(
    (value) => {
      onChange(value);
    },
    [field]
  );

  return (
    <FormInput
      field={field}
      {...register(field.name, {
        required: field.required,
      })}
      onChange={onChangeValue}
    />
  );
};

const DataFormContent = ({
  fields,
  onChange,
  enableTabs,
  setValue,
  getValues,
  register,
  // reset,
  defaultValues,
}) => {
  const [tabs, setTabs] = useState(null);

  const [fieldInputs, setFieldInputs] = useState([]);

  useEffect(() => {
    // set default value to reactHookForm
    // const defaultValues = {};

    // const setDefaultValue = (field) =>
    //   (defaultValues[field.name] = field.value);

    const getField = (field, index) => {
      field.value = defaultValues[field.name];

      return (
        <DataFormInput
          field={field}
          key={index}
          register={register}
          onChange={onDataFormInputChange}
        />
      );
    };

    if (enableTabs === true) {
      // fields.map((tab) => {
      //   if (!UtilsService.isReactElement(tab.fields))
      //     tab.fields.map(setDefaultValue);
      // });
      setTabs(
        fields.map((tab) => {
          return (
            <Tab eventKey={tab.name} key={tab.name} title={tab.label}>
              <div className="row">
                {UtilsService.isReactElement(tab.fields) === true
                  ? tab.fields
                  : tab.fields.map(getField)}
              </div>
            </Tab>
          );
        })
      );
    } else {
      // fields.map(setDefaultValue);
      setFieldInputs(fields.map(getField));
    }

    // reset(defaultValues);
  }, [fields, defaultValues]);

  const onDataFormInputChange = ({ name, value, ...params }) => {
    setValue(name, value);
    if (onChange !== undefined) {
      const values = getValues();
      onChange({ name, value, ...params }, values);
    }
  };

  return (
    <>
      {tabs && (
        <Tabs
          defaultActiveKey={fields[0].name}
          id="tabs"
          className="mb-3"
          onSelect={(tabName) => {}}
        >
          {tabs}
        </Tabs>
      )}

      {fieldInputs && <div className="row">{fieldInputs}</div>}
    </>
  );
};

export default memo(DataFormContent);
