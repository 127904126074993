import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory } from "react-router-dom";
import { useState, memo } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { AuthService, FormService } from "@standard/services";
import { useEffect } from "react";
import { ProfileRoleStatus } from "./ProfileRoleStatus";

export default function Profile({}) {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const url = "auth/profile";

  const currentUser = AuthService.getCurrentUser();

  const defaultData = {
    id: currentUser._id,
    username: currentUser.username,
    fullName: currentUser.fullName,
    email: currentUser.email,
    roles: currentUser.roles,
  };

  const [data, setData] = useState(defaultData);

  const onSubmit = (newData) => {
    setIsLoading(true);

    const params = { ...newData, id: data.id, userId: data.id };

    FormService.submitRequest(url, params)
      .then((result) => {
        setIsLoading(false);
        console.log("index:36 =>", result);
        AuthService.setCurrentUser(result.data);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const fields = [
    {
      label: "Username",
      name: "username",
      type: "label",
      required: true,
      value: data.username,
    },
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: false,
      value: data.fullName,
      col: 6,
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      required: true,
      value: data.email,
      col: 6,
    },
  ];

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        leftPanel={data.roles.map((role) => (
          <ProfileRoleStatus role={role} />
        ))}
        fields={fields}
        onSubmit={onSubmit}
        url={url}
        onErrors={(errors) => setErrors(errors)}
      ></DataForm>
    </BoxContainer>
  );
}
