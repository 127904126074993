import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory } from "react-router-dom";
import { useState, memo } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { UserAPI } from "api";
import { AlertService, FormService } from "@standard/services";
import { useEffect } from "react";
import UserStatus from "./UserStatus";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { userRolesData } from "data";
import { userStatus } from "data/userStatus";

const onResetPassword = (id) => {
  AlertService.confirm("Do you want to reset password ?").then((res) => {
    if (res) {
      UserAPI.resetPassword(id).then(() =>
        AlertService.done(
          "Link for reseting password was sending to your email, please check your email"
        )
      );
    }
  });
};

const onUnlock = (id) => {
  AlertService.confirm("Do you want to unlocked ?").then((res) => {
    if (res) {
      UserAPI.unlocked(id).then(() =>
        AlertService.done("This user account was changed status to Active")
      );
    }
  });
};

export default function UserForm({ url }) {
  const history = useHistory();
  const { id, action } = useParams();
  const [buttons, setButtons] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(
    action === "edit" && id !== undefined
  );

  const defaultData = {
    id: id,
    username: "",
    password: "",
    confirmPassword: "",
    fullName: "",
    email: "",
    roles: "user",
    active: true,
    status: "active",
  };

  const [data, setData] = useState(defaultData);

  useEffect(() => {
    if (id !== undefined) {
      retrieveData(id);
    }
  }, []);

  const retrieveData = (id) => {
    FormService.getRequest(url, id).then((result) => {
      if (result.status === userStatus.LOCKED) {
        setButtons([
          <DropdownButton
            key={1}
            id="dropdown-basic-button"
            title="Options"
            variant="secondary"
            className="d-inline-block mr-2"
          >
            <Dropdown.Item eventKey="2" onClick={() => onResetPassword(id)}>
              Reset Password
            </Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => onUnlock(id)}>
              Unlock Account
            </Dropdown.Item>
          </DropdownButton>,
        ]);
      }

      setData(result);
      setIsLoading(false);
    });
  };

  const onSubmit = (newData) => {
    setIsLoading(true);

    const params = { ...newData, id: id };
    params.roles = params.roles;

    FormService.submitRequest(url, params)
      .then((result) => {
        setIsLoading(false);
        history.push(`/${url}`);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const fields = [
    {
      label: "Username",
      name: "username",
      type: id === undefined ? "text" : "label",
      required: true,
    },
  ];

  if (id === undefined) {
    fields.push({
      label: "Password",
      name: "password",
      type: "password",
      required: true,
      col: 6,
    });

    fields.push({
      label: "Confirm password",
      name: "confirmPassword",
      type: "password",
      required: true,
      col: 6,
    });
  }

  fields.push({
    label: "Full Name",
    name: "fullName",
    type: "text",
    required: false,
    col: 6,
  });

  fields.push({
    label: "Email",
    name: "email",
    type: "email",
    required: true,
    col: 6,
  });

  fields.push({
    label: "Roles",
    name: "roles",
    type: "ddl",
    required: true,
    datasource: userRolesData,
  });

  fields.push({
    label: "Active",
    name: "active",
    type: "switch",
  });

  return (
    <>
      <BoxContainer loading={isLoading} errors={errors}>
        <DataForm
          defaultValues={data}
          leftPanel={<UserStatus status={data.status} />}
          onSubmit={onSubmit}
          fields={fields}
          url={url}
          buttons={buttons}
          onErrors={(errors) => setErrors(errors)}
        ></DataForm>
      </BoxContainer>
    </>
  );
}
