import usePrevious from "@standard/hooks/usePrevious";
import { UtilsService } from "@standard/services";
import React, { useEffect, useState, memo } from "react";
import { Form } from "react-bootstrap";
import FormInput from "../DataForm/FormInput";

const TableForm = ({
  fields, // { name: '', label: '', value: '', type: '', defaultValue: '', 'align' }
  label,
  name,
  values,
  disabled,
  enableInsert,
  enableTrash,
  onChange,
  buttons,
}) => {
  const [items, setItems] = useState([]);
  const preValues = usePrevious(values);

  useEffect(() => {
    if (!UtilsService.JSONEqual(values, preValues)) {
      const defaultValues = values.map((value) => {
        return {
          id: value._id ?? value.id,
          values: { ...value },
        };
      });
      setItems(defaultValues);
    }
  }, [values]);

  useEffect(() => {
    onChange(items);
  }, [items]);

  const getDefaultItem = () => {
    var defaultItem = {
      id: null,
      values: {},
    };

    fields.map((item) => {
      const defaultValue = item.defaultValue || "";
      defaultItem.values[item.name] = defaultValue;
    });

    return defaultItem;
  };

  const removeItem = (index) => {
    setItems(items.filter((f, fIndex) => fIndex !== index));
  };

  var headers = fields.map((item, index) => <th key={index}>{item.label}</th>);

  var fieldItems = items.map((item, itemIndex) => {
    const fieldItem = fields.map((field, fieldIndex) => {
      let alignClass = "text-left";

      field.disabled = disabled;

      if (field.align) {
        if (field.align === "center") {
          alignClass = "text-center";
        } else if (field.align === "right") {
          alignClass = "text-right";
        }
      }

      if (item.values[field.name] !== undefined) {
        field.value = item.values[field.name];
      }

      if (field.placeholder !== undefined) {
        field.placeholder = item[item.placeholder];
      }

      return (
        <td key={fieldIndex} className={alignClass}>
          <FormInput
            field={{ ...field, label: "" }}
            onChange={({ name, value }) => {
              const newItems = [...items];
              newItems[itemIndex].values[name] = value;
              setItems(newItems);
              // onChange(e, index, child.name)
            }}
          ></FormInput>
        </td>
      );
    });

    let trashCol;
    if (disabled === false) {
      if (enableTrash) {
        trashCol = (
          <td className="text-center">
            <button
              onClick={() => {
                removeItem(itemIndex);
              }}
              type="button"
              data-toggle="tooltip"
              data-placemeent="top"
              data-original-title="Trash"
              title="Trash"
              className="btn btn-light btn-sm ml-1"
            >
              <i className="fa fa-trash"></i>
            </button>
          </td>
        );
      }
    }

    return (
      <tr key={itemIndex}>
        {fieldItem}
        {trashCol}
      </tr>
    );
  });

  const addNewItem = () => {
    const newItem = getDefaultItem();

    setItems([...items, newItem]);
  };

  let btnInsert;
  let actionColumnHead;

  if (disabled === false) {
    if (enableInsert) {
      btnInsert = (
        <button
          onClick={addNewItem}
          type="button"
          data-toggle="tooltip"
          data-placemeent="top"
          data-original-title="Add New"
          title="Add New"
          className="btn btn-primary btn-sm ml-1 mb-2 float-right"
        >
          <i className="fa fa-plus"></i> Add New
        </button>
      );
    }

    if (enableTrash) {
      actionColumnHead = <th># Action</th>;
    }
  }

  return (
    <div className="w-100">
      {label && <Form.Label className="col-form-label">{label}:</Form.Label>}
      {btnInsert} {buttons}
      <table className="table table-bordered">
        <thead>
          <tr>
            {headers}
            {actionColumnHead}
          </tr>
        </thead>
        <tbody>{fieldItems}</tbody>
      </table>
    </div>
  );
};

TableForm.defaultProps = {
  fieldItems: [], // { name: '', label: '', value: '', type: '', defaultValue: '', 'align' }
  label: "",
  name: "",
  values: [],
  disabled: false,
  enableInsert: true,
  enableTrash: true,
  onChange: {},
  buttons: [],
};

export default memo(TableForm);
