import DataTable from "@standard/components/DataTable";
import TitleHeader from "@standard/components/TitleHeader";
import TypeOfTransportForm from "./TypeOfTransportForm";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const programType = "Master";
const programName = "Type of Transport Setup";
const url = "master/typeoftransport";
const columns = [
  {
    label: "Type of Transport",
    name: "name",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
];

const config = {
  enableActive: true,
  enableEdit: true,
  enableTrash: true,
};

export default function TypeOfTransport() {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <DataTable columns={columns} url={url} {...config} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <TypeOfTransportForm url={url} />
        </Route>
      </Switch>
    </>
  );
}
