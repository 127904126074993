import { DateService } from "@standard/services";
import React, { useState } from "react";
import { useEffect } from "react";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

const getYear = (date) => date.getFullYear();
const getMonth = (date) => date.getMonth();
const range = (start, end, increment) => {
  let no = [];
  for (let i = start; i <= end; i += increment) no.push(i);

  return no;
};

const years = range(getYear(new Date()) - 100, getYear(new Date()) + 50, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function DatePicker({
  name,
  onChange,
  value,
  isRange,
  required,
  disabled,
  placeholder,
  align,
}) {
  let defaultValue;

  if (isRange) {
    if (isRange && value instanceof Array) {
      const [startDate, endDate] = value;
      if (startDate !== null && endDate !== null) {
        defaultValue = [new Date(startDate), new Date(endDate)];
      } else {
        defaultValue = [null, null];
      }
    } else {
      defaultValue = [null, null];
    }
  } else {
    defaultValue = value ? new Date(value) : null;
  }

  const [dateValue, setDateValue] = useState(defaultValue);

  useEffect(() => {
    if (isRange && value instanceof Array) {
      const [startDate, endDate] = value;
      if (startDate !== null && endDate !== null) {
        setDateValue([new Date(startDate), new Date(endDate)]);
      }
    } else if (value !== "" && value !== null) {
      const newDate = new Date(value);

      setDateValue(newDate);
    } else {
      setDateValue(isRange ? [null, null] : null);
    }
  }, [value]);

  const className = ["form-control"];
  if (align === "left") {
    className.push("text-left");
  } else if (align === "right") {
    className.push("text-right");
  } else if (align === "center") {
    className.push("text-center");
  }

  let params;

  if (isRange) {
    const [startDate, endDate] = dateValue;
    params = {
      startDate: startDate,
      endDate: endDate,
    };
  } else {
    params = {
      selected: dateValue,
    };
  }

  return (
    <div className="input-group mb-3">
      <ReactDatePicker
        {...params}
        onChange={(date) => {
          setDateValue(date);
          onChange({ name: name, value: date });
        }}
        className={className.join(" ")}
        disabled={disabled}
        id={name}
        required={required}
        name={name}
        // locale="en-GB"
        placeholderText={placeholder}
        isClearable={!disabled}
        dateFormat="dd/MM/yyyy"
        selectsRange={isRange}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <input
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              value="<"
            />
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <input
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              value=">"
            />
          </div>
        )}
      />
      <div className="input-group-append">
        <span className="input-group-text" id="basic-addon2">
          <i className="fa fa-calendar" />
        </span>
      </div>
    </div>
  );
}

DatePicker.defaultProps = {
  name: "",
  onChange: {},
  value: "",
  isRange: false,
  required: false,
  disabled: false,
  placeholder: "",
  align: "left",
};
