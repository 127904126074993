import DataTable from "@standard/components/DataTable";
import TitleHeader from "@standard/components/TitleHeader";
import EmployeeGroupForm from "./EmployeeGroupForm";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const programType = "Master";
const programName = "Employee Group Setup";
const url = "master/employeegroup";
const columns = [
  {
    label: "Employee Group",
    name: "name",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
];

const config = {
  enableActive: true,
  enableEdit: true,
  enableTrash: true,
  onBeforeBind: (data) => {
    console.log("Employee Group", data);
    return data;
  },
};

export default function EmployeeGroup() {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <DataTable columns={columns} url={url} {...config} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <EmployeeGroupForm url={url} />
        </Route>
      </Switch>
    </>
  );
}
