import React from "react";
import Switch from "react-switch";

const Switcher = React.forwardRef(
  ({ checked = true, onChange, name, disabled = false }, ref) => {
    return (
      <Switch
        ref={ref}
        onChange={onChange}
        checked={checked === "" ? false : checked}
        name={name}
        disabled={disabled}
      />
    );
  }
);

export default Switcher;
