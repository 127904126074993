import FormInput from "@standard/components/DataForm/FormInput";
import Modal from "@standard/components/Modal";
import { AlertService, DateService, UtilsService } from "@standard/services";
import { PriceRateAPI } from "api";
import { useMemo } from "react";
import { useState, useEffect, memo, useCallback, useContext } from "react";
// import { Button, Badge, Modal as ReactModal } from "react-bootstrap";

const JobFormEditPrice = ({
  selectedDetailId,
  priceRates,
  customerId,
  wheel,
  setIsLoading,
  jobId,
  disabled,
  onSaveNewPriceRateCompleted,
}) => {
  const priceRate = useMemo(() => {
    const priceRate = priceRates.find((f) => f.detailId === selectedDetailId);
    if (priceRate) return priceRate;

    return {
      sts: "",
      bill: "",
      label: "",
    };
  }, [selectedDetailId]);

  const onSave = (data) => {
    setIsLoading(true);
    const params = {
      jobId: jobId,
      detailId: selectedDetailId,
      bill: data.bill,
      sts: data.sts,
      customerId,
      wheel,
    };

    const promise = new Promise((resolve, reject) => {
      PriceRateAPI.updatePriceRateByJob(params).then((res) => {
        AlertService.done("Update Complete").then(() => {
          onSaveNewPriceRateCompleted();
        });
        resolve({ show: false });
      });
    });

    return promise;
  };

  const fields = [
    { name: "label", label: "Ratio", value: priceRate.label, type: "label" },
    { name: "bill", label: "Bill", value: priceRate.bill, type: "number" },
    { name: "sts", label: "STS", value: priceRate.sts, type: "number" },
  ];

  return (
    <Modal
      disabled={!selectedDetailId || disabled}
      label="Edit Price Rate"
      title="Edit Price Rate"
      fields={fields}
      onSave={onSave}
      enableSave={true}
    />
  );
};

export default JobFormEditPrice;
