import FormInput from "@standard/components/DataForm/FormInput";
import { DateService, UtilsService } from "@standard/services";
import { InvoiceEmployeeAPI } from "api";
import { useState, useEffect, memo, useCallback, useContext } from "react";
import { Button, Badge, Modal as ReactModal } from "react-bootstrap";
import { InvoiceEmployeeFormContext } from "./InvoiceEmployeeForm";
import _ from "lodash";

const InvoiceEmployeeJob = ({ jobs, disabled, onChange, id }) => {
  const { setIsLoading } = useContext(InvoiceEmployeeFormContext);
  const [show, setShow] = useState(false);
  const [jobState, setJobState] = useState([]);

  const handleClose = () => {
    setJobState(jobs);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    setJobState([...jobs]);
  }, [jobs]);

  const onSaveModal = () => {
    setShow(false);
    setIsLoading(true);
    InvoiceEmployeeAPI.updatePayJobs({
      jobs: jobState.map((m) => {
        return {
          _id: m._id,
          pay: parseFloat(m.pay),
          selectedPayDetailId: m.selectedPayDetailId,
        };
      }),
      id: id,
    }).then(() => {
      onChange(jobState);
      setIsLoading(false);
    });
  };

  const onPayChange = (value, jobKey) => {
    const newJobs = JSON.parse(JSON.stringify(jobState));
    newJobs[jobKey].pay = value;

    setJobState(newJobs);
  };

  const onSelectedRatioPayChange = (value, jobKey, price = null) => {
    const newJobs = JSON.parse(JSON.stringify(jobState));
    if (value !== null) newJobs[jobKey].pay = price;
    newJobs[jobKey].selectedPayDetailId = value;

    setJobState(newJobs);
  };

  const jobsData = jobState.map((job, jobKey) => {
    const payFields = {
      type: "number",
      uom: "฿",
      value: job.pay,
      name: "pay",
      disabled,
    };

    const transactionDate = DateService.convertDateToString(
      job.transactionDate
    );

    const place = UtilsService.htmlParse(
      `${job.sourcePlace} <br/> ${job.destinationPlace}`
    );

    const pay = (
      <FormInput
        field={payFields}
        onChange={({ value }) => onPayChange(value, jobKey)}
      />
    );

    const link = `/transaction/job/edit/${job._id}`;

    return (
      <tr key={jobKey}>
        <td>{job.customerName}</td>
        <td className="text-center">{transactionDate}</td>
        <td>
          <Button
            variant="link"
            className="text-link"
            target="_blank"
            href={link}
          >
            {job.jobSTS}
          </Button>
        </td>
        <td>{place}</td>
        <td>{job.remark}</td>
        <td>
          {pay}
          <Button
            variant={
              job.selectedPayDetailId === null ? "warning" : "outline-warning"
            }
            className="mr-1 mb-1"
            size="sm"
            disabled={disabled}
            onClick={() => {
              onSelectedRatioPayChange(null, jobKey);
            }}
          >
            Custom
          </Button>
          {!_.isEmpty(job.priceRate) && (
            <Button
              variant={
                job.selectedPayDetailId !== null ? "info" : "outline-info"
              }
              className="mr-1 mb-1"
              size="sm"
              disabled={disabled}
              onClick={() => {
                onSelectedRatioPayChange(
                  job.priceRate.payDetailId,
                  jobKey,
                  job.priceRate.price
                );
                // onPayChange(job.priceRate.price, jobKey);
              }}
            >
              {job.priceRate.label}{" "}
              <Badge bg="light">
                {UtilsService.convertToMoney(job.priceRate.price)}
              </Badge>
            </Button>
          )}
        </td>
      </tr>
    );
  });

  return (
    <>
      <Button variant="link" className="p-1 text-link" onClick={handleShow}>
        Jobs
      </Button>
      <Badge bg="warning">{jobs.length}</Badge>

      <ReactModal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="Jobs"
      >
        <ReactModal.Header className="bg-primary">
          <ReactModal.Title>Jobs</ReactModal.Title>
          <a href="#" className="mt-2 mr-3 text-white" onClick={handleClose}>
            <i className="fa fa-times" style={{ fontSize: "1.5em" }}></i>
          </a>
        </ReactModal.Header>
        <ReactModal.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Customer</th>
                <th>Date</th>
                <th>Job STS</th>
                <th>Place</th>
                <th>Remark</th>
                <th>Pay</th>
              </tr>
            </thead>
            <tbody>{jobsData}</tbody>
          </table>
        </ReactModal.Body>
        <ReactModal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!disabled && (
            <Button variant="primary" onClick={onSaveModal}>
              Save Changes
            </Button>
          )}
        </ReactModal.Footer>
      </ReactModal>
    </>
  );
};

export default memo(InvoiceEmployeeJob);
