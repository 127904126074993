import axios from "@standard/axios";
const url = "";

export const login = (username, password, remember) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .post("auth/login", { username, password, remember })
      .then((res) => {
        const user = res.data;
        if (res.status === 200) {
          resolve({
            data: {
              user,
              token: "mytoken",
            },
          });
        } else {
          reject(["Username or password is invalid"]);
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          reject([err.data]);
        } else {
          reject(["Unexpected error please contact admin"]);
        }
        console.log(err);
      });
  });

  return promise;
};

export const logout = () => axios.post(`auth/logout`);

export const changePassword = ({ token, newPassword }) =>
  new Promise((resolve, reject) => {
    axios
      .post(`auth/changepasswordfromresetpassword/${token}`, {
        newPassword: newPassword,
      })
      .then((res) => {
        resolve({ data: res.data });
      })
      .catch((err) => {
        if (err.status === 400) {
          reject([err.data]);
        } else {
          reject(["Unexpected error please contact admin"]);
        }
        console.log(err);
      });
  });

export const forgetPassword = async (email) =>
  new Promise((resolve, reject) => {
    axios
      .post("auth/forgetPassword", { email })
      .then((res) => {
        if (res.status === 200) resolve({});
      })
      .catch((err) => {
        if (err.status === 404) {
          reject([err.data]);
        } else {
          reject(["Unexpected error please contact admin"]);
        }
        console.log(err);
      });
  });
export const activated = async (token) =>
  new Promise((resolve, reject) => resolve({}));
