import React, { useState, memo, useEffect } from "react";
import { DateService, UtilsService, AlertService } from "@standard/services";
import { PriceRateAPI } from "api";

const PriceRateFormHistoryType = ({ type }) => {
  let classBadge = "";
  switch (type) {
    case "bill":
      classBadge = "primary";
      break;
    case "sts":
      classBadge = "warning";
      break;
    case "pay":
      classBadge = "danger";
      break;
  }
  return (
    <span className={`badge badge-${classBadge}`}>{type.toUpperCase()}</span>
  );
};

const PriceRateFormHistory = ({ customerId, wheel }) => {
  const [histories, setHistories] = useState([]);

  useEffect(() => {
    PriceRateAPI.getHistories({ customerId, wheel }).then((res) => {
      setHistories(res);
    });
  }, []);

  return (
    <div className="row">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Updated Date</th>
            <th>Program</th>
            <th>Type</th>
            <th>Old</th>
            <th>New</th>
            <th>Updated By</th>
          </tr>
        </thead>
        <tbody>
          {histories.map((history) => (
            <tr key={history._id}>
              <td className="text-center">
                {DateService.convertDateTimeToString(history.createdAt)}
              </td>
              <td className="text-center">
                {history.invoiceEmployeeId ? (
                  <a
                    href={`/transaction/invoiceemployee/edit/${history.invoiceEmployeeId}`}
                    className="btn btn-link text-link"
                    target="_blank"
                  >
                    Invoice Employee
                  </a>
                ) : history.jobId ? (
                  <a
                    href={`/transaction/job/edit/${history.jobId}`}
                    className="btn btn-link text-link"
                    target="_blank"
                  >
                    Job - {history.jobSTS}
                  </a>
                ) : (
                  "Job"
                )}
              </td>
              <td className="text-center">
                <PriceRateFormHistoryType type={history.type} />
              </td>
              <td className="text-right">
                <del>{UtilsService.convertToMoney(history.oldValue, "฿")}</del>
              </td>
              <td className="text-right">
                {UtilsService.convertToMoney(history.newValue, "฿")}
              </td>
              <td className="text-left">{history.createdBy}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(PriceRateFormHistory);
