import FormInput from "@standard/components/DataForm/FormInput";
import { DateService, UtilsService } from "@standard/services";
import { expenseTypeData } from "data";
import { useState, useEffect, memo, useCallback, useContext } from "react";
import { InvoiceEmployeeFormContext } from "./InvoiceEmployeeForm";
import { Button, Badge, Modal as ReactModal, Form } from "react-bootstrap";
import { InvoiceEmployeeAPI } from "api";

const InvoiceEmployeeExpense = ({
  expenses,
  disabled,
  onChange,
  employeeId,
  carNoId,
}) => {
  const { setIsLoading, invoiceEmployeeId } = useContext(
    InvoiceEmployeeFormContext
  );
  const [show, setShow] = useState(false);
  const [expenseState, setExpenseState] = useState([]);

  const handleClose = () => {
    setExpenseState(expenses);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    setExpenseState([...expenses]);
  }, [expenses]);

  const onSaveModal = () => {
    setShow(false);
    setIsLoading(true);
    InvoiceEmployeeAPI.updatePriceExpenses({
      expenses: expenseState.map((m) => {
        return { _id: m._id, value: parseFloat(m.value) };
      }),
      employeeId: employeeId,
      carNoId: carNoId,
      invoiceEmployeeId: invoiceEmployeeId,
    }).then(() => {
      onChange(expenseState);
      setIsLoading(false);
    });
  };

  const onPriceChange = (value, expenseKey) => {
    const newExpenses = JSON.parse(JSON.stringify(expenseState));
    newExpenses[expenseKey].value = value;

    setExpenseState(newExpenses);
  };

  const expensesData = expenses.map((expense, expenseKey) => {
    const priceFields = {
      type: "number",
      uom: "฿",
      value: expense.value ?? "",
      name: "pay",
      decimal: 2,
      disabled,
    };

    const expenseType = expenseTypeData.find(
      (f) => f.key === expense.expenseType
    ).label;

    const price = (
      <FormInput
        field={priceFields}
        onChange={({ value }) => onPriceChange(value, expenseKey)}
      />
    );

    return (
      <tr key={expenseKey}>
        <td>{expense.label}</td>
        <td>{expenseType}</td>
        <td>{price}</td>
      </tr>
    );
  });

  const totalExpense = expenseState.reduce(
    (pre, num) => pre + UtilsService.convertToDecimal(num.value),
    0
  );

  const onSubmitForm = () => {
    console.log("onSubmitForm");
  };

  return (
    <>
      <Button variant="link" className="p-1 text-link" onClick={handleShow}>
        Expenses
      </Button>
      <Badge bg="warning">{expenses.length}</Badge>
      <small className="d-block text-danger" style={{ marginTop: -5 }}>
        <i className="fa fa-minus"></i>{" "}
        {UtilsService.convertToMoney(totalExpense, "฿")}
      </small>

      <ReactModal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="Jobs"
      >
        <ReactModal.Header className="bg-primary">
          <ReactModal.Title>Expenses</ReactModal.Title>
          <a href="#" className="mt-2 mr-3 text-white" onClick={handleClose}>
            <i className="fa fa-times" style={{ fontSize: "1.5em" }}></i>
          </a>
        </ReactModal.Header>
        <ReactModal.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Expense Type</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>{expensesData}</tbody>
          </table>
        </ReactModal.Body>
        <ReactModal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!disabled && (
            <Button variant="primary" onClick={onSaveModal}>
              Save Changes
            </Button>
          )}
        </ReactModal.Footer>
      </ReactModal>
    </>
  );
};

export default memo(InvoiceEmployeeExpense);
