import { useState, useEffect } from "react";
import {
  BrowserRouter,
  useLocation,
  useRouteMatch,
  Link,
} from "react-router-dom";

const ProgramName = ({ path, action, children }) => {
  if (action) return <Link to={`${path}`}>{children}</Link>;

  return <strong>{children}</strong>;
};

const TitleHeader = ({ programName, programType, children }) => {
  var program_type;
  const [action, setAction] = useState("");

  if (programType !== "") {
    program_type = (
      <li className="breadcrumb-item">
        <span>{programType}</span>
      </li>
    );
  }

  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  useEffect(() => {
    const url_action = pathname.replace(path, "");
    if (url_action !== "") {
      if (url_action.indexOf("create") > -1) {
        setAction("Create");
      } else if (url_action.indexOf("edit") > -1) {
        setAction("Edit");
      } else if (url_action.indexOf("view") > -1) {
        setAction("View");
      } else {
        setAction("");
      }
    } else {
      setAction("");
    }

    document.title = `${process.env.REACT_APP_PROJECT_NAME} - ${programName}`;
  }, [pathname]);

  return (
    // <BrowserRouter></BrowserRouter>
    <div className="row wrapper border-bottom white-bg page-heading">
      <div className="col-12">
        {children}
        <h2>{programName}</h2>
        <ol className="breadcrumb">
          {program_type}
          <li className="breadcrumb-item active">
            <ProgramName action={action} path={path}>
              {programName}
            </ProgramName>
          </li>
          {action && (
            <li className="breadcrumb-item active">
              <strong>{action}</strong>
            </li>
          )}
        </ol>
      </div>
    </div>
  );
};

TitleHeader.defaultProps = {
  programName: "",
  programType: "",
};

export default TitleHeader;
