import DataTable from "@standard/components/DataTable";
import TitleHeader from "@standard/components/TitleHeader";
import SampleForm from "./SampleForm";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";

const programType = "Sample";
const programName = "Sample Setup";
const url = "master/user";
const columns = [
  {
    label: "Username",
    name: "username",
    align: "center",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Email",
    name: "email",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Full Name",
    name: "full_name",
    sortable: true,
    search: { type: "text" },
  },
  // { label: 'Status', name: 'status', sortable: false, search: {} },
];
function Sample() {
  const config = {
    enableCheckbox: true,
    enableActive: true,
    enableAction: true,
    enablePrint: true,
    enablePrintMultiple: true,
    enableTrashMultiple: true,
    enableEdit: true,
    enableTrash: true,
    enableView: true,
    enableImport: true,
    onPrint: (data, index) => {
      // console.log("Print data", data, index);
    },
    onPrintMultiple: (selectedId) => {
      // console.log("Print Multiple", selectedId);
    },
    onBeforeBind: (data) => {
      // console.log("beforeBind", data);

      return data.map((item) => {
        item.values.username = (
          <span className="badge badge-primary">{item.values.username}</span>
        );

        return item;
      });
    },
    // buttons: [
    //   {
    //     variant: "primary",
    //     label: "Do #1",
    //     onClick: (selectedId) => console.log("do something #1", selectedId),
    //   },
    //   {
    //     variant: "primary",
    //     label: "Do #2",
    //     isMultiple: true,
    //     datasource: [
    //       {
    //         label: "Do #2.1",
    //         onClick: (selectedId) => console.log("Do #2.1", selectedId),
    //       },
    //       {
    //         label: "Do #2.2",
    //         onClick: (selectedId) => console.log("Do #2.2", selectedId),
    //       },
    //     ],
    //   },
    // ],
    // buttonsRow: [
    //   {
    //     variant: "primary",
    //     label: "Do #1",
    //     onClick: (data) => console.log("do something #1", data),
    //   },
    //   {
    //     variant: "primary",
    //     label: "Do #2",
    //     onClick: (data) => console.log("do something #2", data),
    //   },
    // ],
    // onButtonRowRender: (data, index) => {
    //   if (index === 0) {
    //     return (
    //       <Button
    //         variant="danger"
    //         onClick={() => alert(`Hello ${data.values.full_name}`)}
    //       >
    //         Click Me {data.key}
    //       </Button>
    //     );
    //   }
    // },
  };

  const { path, url: urlRoute } = useRouteMatch();
  const { action } = useParams();

  console.log("Loading Sample");

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <DataTable columns={columns} url={url} {...config} />
        </Route>
        <Route path={`${path}/:id/:action/`}>
          <SampleForm url={url} />
        </Route>
        <Route path={`${path}/create`}>
          <SampleForm url={url} />
        </Route>
      </Switch>
    </>
  );
}

export default Sample;
