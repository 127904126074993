import { UtilsService } from "../../services";
import ErrorContainer from "./ErrorContainer";
import Loader from "./Loader.js";
import { useState, useEffect } from "react";
import Transitions from "./Transitions";

const BoxContainer = ({
  errors = [],
  isLoading = false,
  isShowProjectName = false,
  children,
  title = "",
  description = "",
}) => {
  const projectName = UtilsService.getProjectName();
  const [_isLoading, setIsLoading] = useState(false);
  const [_errors, setErrors] = useState([]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setErrors(errors);
  }, [errors]);

  return (
    <Transitions>
      <div className="row">
        <div className="col-12">
          <h1 className="text-center logo-name">{projectName}</h1>
        </div>
        <div
          className={
            "col-12 " +
            (_isLoading ? "sk-loading ibox-content border-top-0" : "")
          }
        >
          {isShowProjectName ? (
            <div className="col-12">
              <h3 className="text-center">Welcome to {projectName}</h3>
            </div>
          ) : (
            ""
          )}
          {title !== "" ? (
            <div className="col-12 text-center">
              <h1 className="font-bold">{title}</h1>
            </div>
          ) : (
            ""
          )}
          {description !== "" ? (
            <div className="col-6 mx-auto text-center">
              <p>{description}</p>
            </div>
          ) : (
            ""
          )}
          <Loader isLoading={_isLoading} />
          <ErrorContainer errors={_errors} onClearError={() => setErrors([])} />
          {children}
        </div>
      </div>
    </Transitions>
  );
};

export default BoxContainer;
