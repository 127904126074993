import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory, Link } from "react-router-dom";
import { useState, memo } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { FormService } from "@standard/services";
import { useEffect } from "react";
import { DatasourceAPI } from "api";
import { Button } from "react-bootstrap";
import axios from "@standard/axios";

export default function CustomerForm({ url }) {
  const history = useHistory();
  const { id, action } = useParams();
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const defaultData = {
    id: id,
    name: "",
    alias: "",
    address: "",
    address2: "",
    taxNo: "",
    bankId: null,
    branch: "",
    invoiceTemplates: [],
    active: true,
    dueDateInvoiceDay: 30,
    dueDateReceiptDay: 30,
    ceil: false,
    employeeGroups: [],
    receiptTemplateId: "",
  };

  const [data, setData] = useState(defaultData);
  const [datasource, setDatasource] = useState({
    bank: [],
    invoicetemplate: [],
    employeegroup: [],
    receipttemplate: [],
  });

  useEffect(() => {
    const tables = [
      "bank",
      "invoicetemplate",
      "employeegroup",
      "receipttemplate",
    ];
    const promiseDatasource = DatasourceAPI.getActiveDatasource(tables);
    if (id !== undefined) {
      const promiseData = FormService.getRequest(url, id);

      Promise.all([promiseDatasource, promiseData]).then((values) => {
        const [datasourceResult, dataResult] = values;
        setDatasource(datasourceResult);
        setData(dataResult);

        setIsLoading(false);
      });
    } else {
      promiseDatasource.then((datasourceResult) => {
        setDatasource(datasourceResult);

        setIsLoading(false);
      });
    }
  }, []);

  const onSubmit = (newData) => {
    setIsLoading(true);

    const params = { ...newData, id: id };

    FormService.submitRequest(url, params)
      .then((result) => {
        setIsLoading(false);
        history.push(`/${url}`);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const fieldsInfo = [
    {
      label: "Customer Name",
      name: "name",
      type: "text",
      datasource: [],
      required: true,
    },
    {
      label: "Alias Customer Name",
      name: "alias",
      type: "text",
      required: true,
    },
    {
      label: "Address",
      name: "address",
      type: "text",
      required: false,
    },
    {
      label: "Address 2",
      name: "address2",
      type: "text",
      required: false,
    },
    {
      label: "Tax No.",
      name: "taxNo",
      type: "text",
      required: false,
    },
    {
      label: "Bank",
      name: "bankId",
      type: "ddl",
      datasource: datasource.bank,
      required: false,
      col: 6,
    },
    {
      label: "Branch",
      name: "branch",
      type: "text",
      required: false,
      col: 6,
    },
    {
      label: "Invoice Template",
      name: "invoiceTemplates",
      type: "ddl",
      multiple: true,
      required: false,
      datasource: datasource.invoicetemplate,
    },
    {
      label: "Employee Groups",
      name: "employeeGroups",
      type: "ddl",
      multiple: true,
      required: false,
      datasource: datasource.employeegroup,
    },
    {
      label: "Receipt Template",
      name: "receiptTemplateId",
      type: "ddl",
      multiple: false,
      required: true,
      datasource: datasource.receipttemplate,
    },
    { label: "Ceil", name: "ceil", type: "switch" },
    { label: "Active", name: "active", type: "switch" },
  ];

  const fieldDueDate = [
    {
      label: "Due Date Invoice",
      name: "dueDateInvoiceDay",
      type: "number",
      uom: "Day",
      description: "ระยะเวลาแจ้งเตือน Job ที่ยังไม่ถูกวางบิล",
    },
    {
      label: "Due Date Receipt",
      name: "dueDateReceiptDay",
      type: "number",
      uom: "Day",
      description: "ระยะเวลาแจ้งเตือน Invoice ที่ยังไม่ออกใบเสร็จ",
    },
  ];

  const tabs = [
    { name: "info", label: "Info", fields: fieldsInfo },
    { name: "dueDate", label: "Due Date", fields: fieldDueDate },
  ];

  const leftPanel = id && (
    <>
      <Link
        title="Pricerate for 4 WD"
        to={`/master/customer/pricerate/${id}/4`}
        className="btn btn-link btn-small text-link"
      >
        <i className="fa fa-external-link" aria-hidden="true"></i> Setup
        Pricerate for 4 WD
      </Link>
      <br />
      <Link
        title="Pricerate for 6 WD"
        to={`/master/customer/pricerate/${id}/6`}
        className="btn btn-link btn-small text-link"
      >
        <i className="fa fa-external-link" aria-hidden="true"></i> Setup
        Pricerate for 6 WD
      </Link>
    </>
  );

  const buttons = [];

  if (id) {
    buttons.push(
      <Button
        key={1}
        variant="danger"
        className="mr-1"
        onClick={() => {
          const printUrl = `${axios.defaults.baseURL}master/pricerate/print/${id}`;
          window.open(printUrl, "_blank");
        }}
      >
        Export Price Rate
      </Button>
    );
  }

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        buttons={buttons}
        defaultValues={data}
        leftPanel={leftPanel}
        loading={isLoading}
        fields={tabs}
        enableTabs={true}
        onSubmit={onSubmit}
        url={url}
        onErrors={(errors) => setErrors(errors)}
        onChange={({ name, value }, values) => {
          // console.log("CustomerForm:237 =>", name, value, values);
        }}
      ></DataForm>
      <br />
      <br />
    </BoxContainer>
  );
}
