import { UtilsService, AlertService } from "@standard/services";
import {
  setSearchCriteria,
  importFile,
  removeData,
} from "@standard/store/reducers/dataTableSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { Button, Form } from "react-bootstrap";

const onDelete = (selectedId, dispatch) => {
  AlertService.confirm("Do you want to delete data ?").then((willDelete) => {
    if (willDelete) {
      dispatch(removeData(selectedId.join("|")));
    }
  });
};

const SearchTool = () => {
  const dispatch = useDispatch();

  const uploadFile = (e) => {
    const { files } = e.target;
    const params = { importFile: files[0] };
    dispatch(importFile(params));
  };

  const fileElement = useRef(null);
  const { config, selectedId, searchCriteria } = useSelector(
    (state) => state.dataTable
  );
  const {
    enableImport,
    enableInsert,
    enablePrint,
    enableTrash,
    enablePrintMultiple,
    enableTrashMultiple,
    exportURL,
    importURL,
    onPrintMultiple,
    buttons,
  } = config;

  let searchToolButtons = [];

  if (enablePrint === true && enablePrintMultiple === true) {
    searchToolButtons.push(
      <Button
        key={1}
        variant="warning"
        className="ml-2"
        disabled={selectedId.length === 0}
        onClick={() => onPrintMultiple(selectedId)}
        title="Print"
      >
        {" "}
        <i className="fa fa-print"></i> Print
      </Button>
    );
  }

  if (enableTrash === true && enableTrashMultiple === true) {
    searchToolButtons.push(
      <Button
        variant="danger"
        key={2}
        className="ml-2"
        disabled={selectedId.length === 0}
        onClick={() => onDelete(selectedId, dispatch)}
        title="Delete"
      >
        {" "}
        <i className="fa fa-trash"></i> Delete
      </Button>
    );
  }

  if (enableImport === true) {
    const fileElementInput = (
      <input
        ref={fileElement}
        type="file"
        className="d-none"
        onChange={uploadFile}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      ></input>
    );

    searchToolButtons.push(
      <div className="dropdown ml-2" key={4}>
        {fileElementInput}
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          นำเข้าข้อมูล
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href={exportURL}>
            ดาวน์โหลด Template
          </a>
          <a
            className="dropdown-item"
            onClick={() => fileElement.current.click()}
          >
            นำเข้าข้อมูล
          </a>
        </div>
      </div>
    );
  }

  if (enableInsert === true) {
    const path = window.location.pathname;
    searchToolButtons.push(
      <Link
        key={3}
        to={`${path}/create`}
        className="btn btn-success ml-2"
        data-toggle="tooltip"
        data-placemeent="top"
        data-original-title="Create"
        title="Create"
      >
        <i className="fa fa-plus"></i> Add New
      </Link>
    );
  }

  if (buttons.length > 0) {
    buttons.map((button, key) => {
      let variant = button.variant ?? "primary";

      if (button.isMultiple === true) {
        const btnId = `btn_${key}`;
        const subButtons = button.datasource.map((subButton, subButtonKey) => {
          return (
            <a
              href="#"
              key={subButtonKey}
              className="dropdown-item"
              onClick={(e) => subButton.onClick(selectedId, searchCriteria)}
            >
              {UtilsService.htmlParse(subButton.label)}
            </a>
          );
        });
        searchToolButtons.push(
          <div className="dropdown" key={key + 5}>
            <button
              className="dropdown-toggle btn btn-primary"
              type="button"
              id={btnId}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {UtilsService.htmlParse(button.label)}
            </button>
            <div className="dropdown-menu" aria-labelledby={btnId}>
              {subButtons}
            </div>
          </div>
        );
      } else {
        searchToolButtons.push(
          <Button
            key={key + 5}
            variant={variant}
            className="ml-2"
            onClick={(e) => button.onClick(selectedId)}
          >
            {UtilsService.htmlParse(button.label)}
          </Button>
        );
      }
      return 0;
    });
  }

  return (
    <div className="mb-2 row">
      <div className="col-12 col-md-6">
        <div className="form-inline my-2">
          <div className="form-group">
            <label htmlFor="page-amount-input" className="mr-2">
              Show{" "}
            </label>
            <Form.Select
              className="form-control"
              aria-label=""
              size="sm"
              id="page-amount-input"
              value={searchCriteria.recordPerPage}
              onChange={(e) =>
                dispatch(
                  setSearchCriteria({
                    recordPerPage: UtilsService.convertToInt(e.target.value),
                  })
                )
              }
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={0}>ALL</option>
            </Form.Select>
            <span className="ml-2">records</span>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 text-right">
        <div id="toolbar" className="btn-group">
          {searchToolButtons}
        </div>
      </div>
    </div>
  );
};

export default SearchTool;
