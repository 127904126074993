import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";

function LayoutWithAuthen({ children }) {
  return (
    <div id="wrapper">
      <Sidebar></Sidebar>
      <div id="page-wrapper" className="gray-bg">
        <div className="row border-bottom">
          <Navbar></Navbar>
        </div>
        {children}
        <div className="footer">
          <strong>Copyright</strong>&nbsp;Setthasit &copy; 2022
        </div>
      </div>
    </div>
  );
}

export default LayoutWithAuthen;
