import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory } from "react-router-dom";
import { useState, memo } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { AlertService, DateService, FormService } from "@standard/services";
import { useEffect } from "react";
import { employeeTypeData, wheelData } from "data";
import { DatasourceAPI } from "api";
import { useCallback } from "react";

export default function EmployeeForm({ url }) {
  const history = useHistory();
  const { id, action } = useParams();
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const defaultData = {
    employeeGroupId: null,
    code: "",
    prefixName: "",
    name: "",
    nameEN: "",
    identityNo: "",
    identityNoExpireDate: null,
    driverLicense: "",
    driverLicenseExpireDate: null,
    telephone: "",
    birthdate: null,
    address: "",
    address2: "",
    warranty: "",
    warrantyExpireDate: null,
    bankId: null,
    bankAccountName: "",
    bankAccountNo: "",
    isOutsource: 0,
    active: true,
    remark: "",
    taxUseEmployeeInfo: true,
    taxName: "",
    taxIdentityNo: "",
    taxAddress: "",
    taxAddress2: "",
    wheel: 4,
    carNo: "",
    carProvinceId: null,
    carBrand: "",
    carColor: "",
    carType: "",
    carNoExpireDate: null,
    carInsurance: "",
    carInsuranceExpireDate: null,
  };

  const [data, setData] = useState(defaultData);
  const [datasource, setDatasource] = useState({
    employeegroup: [],
    bank: [],
    province: [],
  });

  useEffect(() => {
    if (id !== undefined) {
      FormService.getRequest(url, id).then((dataResult) => {
        const tables = [
          { name: "employeegroup", value: dataResult.employeeGroupId },
          { name: "bank", value: dataResult.bankId },
          { name: "province", value: dataResult.carProvinceId },
        ];
        DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
          setDatasource(datasourceResult);
          setData(dataResult);
          setIsLoading(false);
        });
      });
    } else {
      const tables = ["employeegroup", "bank", "province"];
      DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
        setDatasource(datasourceResult);
      });
    }
  }, []);

  const onSubmit = (newData) => {
    setIsLoading(true);

    const params = { ...newData, id: id };

    FormService.submitRequest(url, params)
      .then((result) => {
        setIsLoading(false);
        history.push(`/${url}`);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const infoFields = [
    {
      label: "Type",
      name: "isOutsource",
      type: "radio",
      inline: true,
      required: false,
      datasource: employeeTypeData,
    },
    {
      label: "Employee Group",
      name: "employeeGroupId",
      type: "ddl",
      datasource: datasource.employeegroup,
    },
    {
      label: "Code",
      name: "code",
      type: "text",
      col: 12,
    },
    {
      label: "Prefix",
      name: "prefixName",
      type: "text",
      required: false,
      col: 6,
    },
    {
      label: "Name",
      name: "name",
      type: "text",
      required: true,
      col: 6,
    },
    {
      label: "Name EN",
      name: "nameEN",
      type: "text",
      required: false,
      col: 12,
    },
    {
      label: "Identity No",
      name: "identityNo",
      type: "text",
      col: 6,
    },
    {
      label: "Identity No Expire Date",
      name: "identityNoExpireDate",
      type: "date",
      col: 6,
    },
    {
      label: "Driver License",
      name: "driverLicense",
      type: "text",
      col: 6,
    },
    {
      label: "Driver License Expire Date",
      name: "driverLicenseExpireDate",
      type: "date",
      col: 6,
    },
    {
      label: "Telephone",
      name: "telephone",
      type: "text",
    },
    {
      label: "Date of Birth",
      name: "birthdate",
      type: "date",
    },
    {
      label: "Address",
      name: "address",
      type: "text",
    },
    {
      label: "Address 2",
      name: "address2",
      type: "text",
      value: data.address2,
    },
    {
      label: "Warranty",
      name: "warranty",
      type: "text",
      col: 6,
    },
    {
      label: "Warranty Expire Date",
      name: "warrantyExpireDate",
      type: "date",
      col: 6,
    },
    {
      label: "Bank",
      name: "bankId",
      type: "ddl",
      datasource: datasource.bank,
      required: false,
      col: 6,
    },
    {
      label: "Bank Account Name",
      name: "bankAccountName",
      type: "text",
      required: false,
      col: 6,
    },
    {
      label: "Bank Account No",
      name: "bankAccountNo",
      type: "text",
      required: false,
      col: 12,
    },
    { label: "Active", name: "active", type: "switch" },
    { label: "หมายเหตุ", name: "remark", type: "textarea" },
  ];

  const taxFields = [
    {
      label: "Use Employee Info",
      name: "taxUseEmployeeInfo",
      type: "switch",
    },
    {
      label: "Name",
      name: "taxName",
      type: "text",
      disabled: data.taxUseEmployeeInfo === true,
      col: 6,
    },
    {
      label: "Identity No",
      name: "taxIdentityNo",
      type: "text",
      disabled: data.taxUseEmployeeInfo === true,
      col: 6,
    },
    {
      label: "Address",
      name: "taxAddress",
      type: "text",
      disabled: data.taxUseEmployeeInfo === true,
    },
    {
      label: "Address 2",
      name: "taxAddress2",
      type: "text",
      disabled: data.taxUseEmployeeInfo === true,
    },
  ];

  const carFields = [
    {
      label: "Wheel",
      name: "wheel",
      type: "radio",
      inline: true,
      datasource: wheelData,
    },
    {
      label: "Car No",
      name: "carNo",
      type: "text",
      col: 6,
    },
    {
      label: "Province",
      name: "carProvinceId",
      type: "ddl",
      datasource: datasource.province,
      col: 6,
    },
    {
      label: "Car Brand",
      name: "carBrand",
      type: "text",
      col: 6,
    },
    {
      label: "Car Color",
      name: "carColor",
      type: "text",
      col: 6,
    },
    {
      label: "Car Type",
      name: "carType",
      type: "text",
      col: 6,
    },
    {
      label: "Car No Expire Date",
      name: "carNoExpireDate",
      type: "date",
      col: 6,
    },
    {
      label: "Car Insurance",
      name: "carInsurance",
      type: "text",
      col: 6,
    },
    {
      label: "Car Insurance Expire Date",
      name: "carInsuranceExpireDate",
      type: "date",
      col: 6,
    },
  ];

  const tabs = [
    { name: "info", label: "Info", fields: infoFields },
    {
      name: "tax",
      label: "Tax",
      fields: taxFields,
      description: "ข้อมูลในการออกใบกำกับภาษี",
    },
    {
      name: "car",
      label: "Car",
      fields: carFields,
    },
  ];

  const dataFormOnChange = useCallback(({ name, value }, values) => {
    if (name === "taxUseEmployeeInfo") {
      setData(values);
    }
  }, []);

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        defaultValues={data}
        fields={tabs}
        onSubmit={onSubmit}
        url={url}
        onErrors={(errors) => setErrors(errors)}
        enableTabs={true}
        onChange={dataFormOnChange}
      ></DataForm>
    </BoxContainer>
  );
}
