import { expenseTypeData } from "data";

export default ({ expenseType }) => {
  // console.log("ExpenseType:4 =>", expenseType);
  const expenseTypeEntity = expenseTypeData.find((f) => f.key === expenseType);

  let className = ["badge p-2"];
  switch (expenseType) {
    case "c":
      className.push("badge-warning");
      break;
    case "di":
      className.push("badge-primary");
      break;
    case "dd":
      className.push("badge-danger");
      break;
  }

  return (
    <span className={className.join(" ")}>{expenseTypeEntity?.label}</span>
  );
};
