import * as moment from "moment";

export default {
  getThaiDateFullFormat,
  getToday,
  getNow,
  getAdjNewDate,
  convertDate,
  convertToDate,
  convertStringToDate,
  convertDateToString,
  convertDateTimeToString,
  convertStringToDateTime,
  convertDateToServer,
  yearTh,
  dateDiff,
  withoutTime,
};

const FULL_MONTHS_TH = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

const DATE_FORMAT = {
  FORMAT: "DD/MM/YYYY",
  FORMAT_WITH_TIME: "DD/MM/YYYY HH:mm:ss",
  FORMAT_TO_SERVER: "YYYY-MM-DD",
};

function yearTh(_date, format = DATE_FORMAT.FORMAT) {
  if (_date === null) return "";
  var date = new Date(this.convertStringToDate(_date));

  var dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
  var mm = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
  var yyyy = date.getFullYear() - 543;
  var th = dd + "/" + mm + "/" + yyyy;
  var date_th = new Date(this.convertStringToDate(th));
  return moment(date_th).format(format);
}

function getThaiDateFullFormat(_date) {
  if (_date === null) return "";
  var date = new Date(_date);

  var day = date.getDate();
  var month = FULL_MONTHS_TH[date.getMonth()];
  var year = date.getFullYear() + 543;
  return day + " " + month + " " + year;
}

function withoutTime(dateTime) {
  var date = new Date(dateTime.getTime());
  date.setUTCHours(0, 0, 0, 0);
  return date;
}

function getToday() {
  const date = new Date();

  return withoutTime(date);
}
function getNow() {
  return this.convertDateTimeToString(new Date());
}
function getAdjNewDate(_day = null, _month = null, _year = null) {
  var new_date = new Date();
  new_date.setDate(new_date.getDate() + parseInt(_day));
  var date = this.convertDateToString(new_date);
  var d = date.substring(0, 2);
  var m = date.substring(3, 5);
  var y = date.substring(6, 10);

  return d + "-" + m + "-" + y;
}
function convertDate(_date) {
  return moment(_date).format(DATE_FORMAT.FORMAT);
}

function convertToDate(_date, format = DATE_FORMAT.FORMAT) {
  return moment(_date, format);
}
function convertStringToDate(_date, format = DATE_FORMAT.FORMAT) {
  return moment(_date, format)._d;
}
function convertStringToDateTime(_date, format = DATE_FORMAT.FORMAT_WITH_TIME) {
  return moment(_date, format)._d;
}
function convertDateTimeToString(_date, format = DATE_FORMAT.FORMAT_WITH_TIME) {
  return _date !== null ? moment(_date).format(format) : null;
}
function convertDateToString(_date, format = DATE_FORMAT.FORMAT) {
  return _date !== null ? moment(_date).format(format) : null;
}
function convertDateToServer(_date, format = DATE_FORMAT.FORMAT_TO_SERVER) {
  return _date !== null ? moment(_date).format(format) : null;
}

function dateDiff(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}
