import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory } from "react-router-dom";
import { useState, memo, useCallback } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import {
  DateService,
  AuthService,
  FormService,
  AlertService,
  UtilsService,
} from "@standard/services";
import { useEffect } from "react";
import FormInput from "@standard/components/DataForm/FormInput";
import { Badge, Button } from "react-bootstrap";
import { wheelData, dnReturnTypeData, dnReturnPrintStatusData } from "data";
import { DatasourceAPI } from "api";
import { Wheel } from "data/wheel";
import JobFormEditPrice from "./JobFormEditPrice";

export default function JobForm({ url }) {
  const history = useHistory();
  const { id, action } = useParams();
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fullname = AuthService.getFullName();
  const dhlOtherExpenseCodes = ["MIR02"];

  const defaultData = {
    id: id,
    createdAt: new Date(),
    createdByName: fullname,
    customerId: null,
    typeOfTransportId: null,
    transactionDate: new Date(),
    documentNo: "",
    hawbNo: "",
    jobSTS: "",
    jobName: "",
    dossier: "",
    sourcePlaceId: null,
    destinationPlaceId: null,
    wheel: Wheel.WD4,
    employeeId: null,
    carNoId: null,
    bill: "",
    sts: "",
    pay: "",
    remark: "",
    invoiceId: null,
    invoiceNo: null,
    receiptInfo: null,
    dnReturnId: null,
    selectedDetailId: null,
    selectedPayDetailId: null,
    otherExpenses: [],
    isEditable: true,
    isCancelJob: false,
    invoiceEmployeeId: null,
  };

  const [data, setData] = useState(defaultData);
  const [customerId, setCustomerId] = useState(data.customerId);
  const [wheel, setWheel] = useState(data.wheel);
  const [employeeId, setEmployeeId] = useState(data.employeeId);
  const [sourcePlaceId, setSourcePlaceId] = useState(data.sourcePlaceId);
  const [destinationPlaceId, setDestinationPlaceId] = useState(
    data.destinationPlaceId
  );
  const [datasource, setDatasource] = useState({
    customer: [],
    employee: [],
    place: [],
    typeoftransport: [],
    employeecarno: [],
    jobexpense: [],
  });

  const [priceRates, setPriceRates] = useState([]);

  const disabled = !data.isEditable || action === "view";

  useEffect(() => {
    if (isLoading === true) return;

    setIsLoading(true);
    const tables = [{ name: "place", ref1: customerId }];
    DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
      setDatasource({ ...datasource, ...datasourceResult });
      setIsLoading(false);
    });
  }, [customerId]);

  useEffect(() => {
    if (isLoading === true) return;

    setIsLoading(true);
    const tables = [
      { name: "employeecarno", ref1: wheel },
      { name: "employee", ref1: wheel },
    ];
    DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
      setDatasource({ ...datasource, ...datasourceResult });

      setIsLoading(false);
    });
  }, [wheel]);

  // get price rate
  const retrievePriceRate = () => {
    if (employeeId && sourcePlaceId && destinationPlaceId) {
      setIsLoading(true);

      const params = {
        customerId: customerId,
        wheel: wheel,
        sourcePlaceId: sourcePlaceId,
        destinationPlaceId: destinationPlaceId,
        employeeId: employeeId,
      };

      const tables = [{ name: "pricerate", ref1: params }];
      DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
        setPriceRates(datasourceResult.pricerate);

        setIsLoading(false);
      });
    }
  };
  useEffect(() => {
    if (isLoading === true) return;

    retrievePriceRate();
  }, [employeeId, sourcePlaceId, destinationPlaceId]);

  // prepare data
  useEffect(() => {
    if (id !== undefined) {
      FormService.getRequest(url, id).then((dataResult) => {
        const priceRateparams = {
          customerId: dataResult.customerId,
          wheel: dataResult.wheel,
          sourcePlaceId: dataResult.sourcePlaceId,
          destinationPlaceId: dataResult.destinationPlaceId,
          employeeId: dataResult.employeeId,
        };

        const tables = [
          { name: "customer", value: dataResult.customerId },
          {
            name: "employee",
            value: dataResult.employeeId,
            ref1: dataResult.wheel,
          },
          { name: "typeoftransport", value: dataResult.typeoftransportId },
          {
            name: "employeecarno",
            value: dataResult.carNoId,
            ref1: dataResult.wheel,
          },
          {
            name: "place",
            ref1: dataResult.customerId,
          },
          {
            name: "jobexpense",
            value: dataResult.otherExpenses.map((item) => item.expenseTypeId),
          },
          { name: "pricerate", ref1: priceRateparams },
        ];

        DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
          setDatasource(datasourceResult);
          setPriceRates(datasourceResult.pricerate);

          if (dataResult.carNoId === null) {
            dataResult.carNoId = dataResult.carNoText;
          }
          if (dataResult.employeeId === null) {
            dataResult.employeeId = dataResult.employeeText;
          }

          datasourceResult.jobexpense.map((jobExpense) => {
            const otherExpense = dataResult.otherExpenses.find(
              (f) => f.expenseTypeId === jobExpense.key
            );
            dataResult["otherExpense_" + jobExpense.key] = otherExpense
              ? otherExpense.price
              : "";
          });

          setData(dataResult);
          setCustomerId(dataResult.customerId);
          setWheel(dataResult.wheel);
          setEmployeeId(dataResult.employeeId);
          setSourcePlaceId(dataResult.sourcePlaceId);
          setDestinationPlaceId(dataResult.destinationPlaceId);
          setIsLoading(false);
        });
      });
    } else {
      const tables = [
        { name: "customer" },
        { name: "employee", ref1: data.wheel },
        { name: "typeoftransport" },
        { name: "employeecarno", ref1: data.wheel },
        { name: "jobexpense" },
      ];

      DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
        datasourceResult.jobexpense.map((jobExpense) => {
          data["otherExpense_" + jobExpense.key] = "";
        });

        setData(data);
        setDatasource(datasourceResult);
        setIsLoading(false);
      });
    }
  }, []);

  const save = (newData) => {
    setIsLoading(true);
    const savedData = { ...data, ...newData };

    savedData.selectedDetailId = data.selectedDetailId;
    savedData.selectedPayDetailId = data.selectedPayDetailId;
    savedData.otherExpenses = datasource.jobexpense.map((item) => {
      let otherExpense = {
        expenseTypeId: item.key,
        price: "",
      };

      if (savedData["otherExpense_" + item.key]) {
        otherExpense.price = savedData["otherExpense_" + item.key];
      }

      return otherExpense;
    });

    savedData.createdBy = data.createdBy;
    savedData.updatedBy = data.updatedBy;
    savedData.id = id;

    const params = { ...savedData };

    FormService.submitRequest(url, params)
      .then((result) => {
        setIsLoading(false);
        history.push(`/${url}`);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const onSubmit = (newData) => {
    save(newData);
  };

  const payChangeable = data.invoiceEmployeeId ? false : true;

  const fields = [
    {
      label: "Customer",
      name: "customerId",
      type: "ddl",
      required: true,
      datasource: datasource.customer,
      // value: data.customerId,
      disabled,
    },
    {
      label: "Type of Transport",
      name: "typeOfTransportId",
      type: "ddl",
      required: false,
      datasource: datasource.typeoftransport,
      // value: data.typeOfTransportId,
      col: 6,
      disabled,
    },
    {
      label: "Transaction Date",
      name: "transactionDate",
      type: "date",
      required: true,
      // value: data.transactionDate,
      col: 6,
      disabled,
    },
    {
      label: "ใบรับงานเลขที่ / Shipment No / Job Reference",
      name: "documentNo",
      type: "text",
      required: false,
      // value: data.documentNo,
      disabled,
      col: 6,
    },
    {
      label: "Job STS / งานเลขที่",
      name: "jobSTS",
      type: "text",
      required: true,
      // value: data.jobSTS,
      disabled,
      col: 6,
    },
    {
      label: "Dossier / Cost Center",
      name: "dossier",
      type: "text",
      required: false,
      // value: data.dossier,
      disabled,
      col: 6,
    },
    {
      label: "HAWB No / FLT",
      name: "hawbNo",
      type: "text",
      required: false,
      // value: data.hawbNo,
      disabled:
        (data.dnReturnId !== null && data.dnReturnId !== undefined) || disabled,
      col: 6,
    },
    {
      label: "Job Name / Consignee",
      name: "jobName",
      type: "text",
      required: false,
      // value: data.jobName,
      disabled,
    },
    {
      label: "Source Place",
      name: "sourcePlaceId",
      type: "ddl",
      required: true,
      // value: data.sourcePlaceId,
      col: 6,
      datasource: datasource.place,
      disabled,
    },
    {
      label: "Destination Place",
      name: "destinationPlaceId",
      type: "ddl",
      required: true,
      // value: data.destinationPlaceId,
      col: 6,
      datasource: datasource.place,
      disabled,
    },
    {
      label: "Wheel",
      name: "wheel",
      type: "radio",
      required: true,
      // value: data.wheel,
      datasource: wheelData,
      inline: true,
      disabled,
    },
    {
      label: "Car No",
      name: "carNoId",
      type: "ddl",
      required: true,
      // value: data.carNoId,
      datasource: datasource.employeecarno,
      createable: true,
      col: 6,
      disabled,
    },
    {
      label: "Employee",
      name: "employeeId",
      type: "ddl",
      required: true,
      // value: data.employeeId,
      createable: true,
      col: 6,
      datasource: datasource.employee,
      disabled,
    },
    {
      label: "Bill",
      name: "bill",
      type: "number",
      decimal: 2,
      required: false,
      disabled: data.selectedDetailId !== null || disabled,
      uom: "฿",
      col: 6,
      description: (
        <>
          <Button
            key={1}
            variant={
              data.selectedDetailId === null ? "warning" : "outline-warning"
            }
            className="mr-1 mb-1"
            size="sm"
            disabled={disabled}
            onClick={() => {
              if (payChangeable) {
                setData({
                  ...data,
                  bill: "",
                  sts: "",
                  pay: "",
                  selectedPayDetailId: null,
                  selectedDetailId: null,
                });
              } else {
                setData({
                  ...data,
                  bill: "",
                  sts: "",
                  selectedDetailId: null,
                });
              }
            }}
          >
            Custom
          </Button>
          {priceRates
            .filter((f) => f.bill)
            .map((priceRate, priceRateIndex) => (
              <Button
                key={priceRate.detailId}
                variant={
                  priceRate.ratio < 0
                    ? data.selectedDetailId === priceRate.detailId
                      ? "danger"
                      : "outline-danger"
                    : data.selectedDetailId === priceRate.detailId
                    ? "secondary"
                    : "outline-secondary"
                }
                className="mr-1 mb-1"
                size="sm"
                disabled={disabled}
                onClick={() => {
                  if (payChangeable) {
                    setData({
                      ...data,
                      bill: priceRate.bill,
                      sts: priceRate.sts,
                      selectedDetailId: priceRate.detailId,
                      pay: priceRate.pay,
                      selectedPayDetailId: priceRate.detailId,
                    });
                  } else {
                    setData({
                      ...data,
                      bill: priceRate.bill,
                      sts: priceRate.sts,
                      selectedDetailId: priceRate.detailId,
                    });
                  }
                }}
              >
                {priceRate.label}{" "}
                <Badge bg="danger">
                  {UtilsService.convertToMoney(priceRate.bill)}
                </Badge>
              </Button>
            ))}
          <Button
            key={2}
            variant="primary"
            className="mr-1 mb-1"
            size="sm"
            disabled={disabled}
            onClick={() => {
              retrievePriceRate();
            }}
          >
            <i className="fa fa-refresh" aria-hidden="true"></i>
          </Button>
          <JobFormEditPrice
            customerId={data.customerId}
            wheel={data.wheel}
            selectedDetailId={data.selectedDetailId}
            priceRates={priceRates}
            setIsLoading={setIsLoading}
            jobId={id}
            disabled={disabled}
            onSaveNewPriceRateCompleted={() => {
              retrievePriceRate();
            }}
          />
        </>
      ),
    },
    {
      label: "STS",
      name: "sts",
      type: "number",
      decimal: 2,
      required: false,
      disabled: data.selectedDetailId !== null || disabled,
      uom: "฿",
      col: 6,
    },
    {
      label: "Pay",
      name: "pay",
      type: "number",
      decimal: 2,
      required: false,
      disabled:
        data.selectedPayDetailId !== null ||
        disabled ||
        payChangeable === false,
      uom: "฿",
      col: 6,
      description: (
        <>
          <Button
            key={1}
            variant={
              data.selectedPayDetailId === null ? "warning" : "outline-warning"
            }
            className="mr-1 mb-1"
            size="sm"
            disabled={disabled || payChangeable === false}
            onClick={() => {
              setData({
                ...data,
                pay: "",
                selectedPayDetailId: null,
              });
            }}
          >
            Custom
          </Button>
          {priceRates
            .filter((f) => f.pay)
            .map((priceRate) => (
              <Button
                key={priceRate.detailId}
                variant={
                  data.selectedPayDetailId === priceRate.detailId
                    ? "info"
                    : "outline-info"
                }
                className="mr-1 mb-1"
                size="sm"
                disabled={disabled || payChangeable === false}
                onClick={() =>
                  setData({
                    ...data,
                    pay: priceRate.pay,
                    selectedPayDetailId: priceRate.detailId,
                  })
                }
              >
                {priceRate.label}{" "}
                <Badge bg="warning">
                  {UtilsService.convertToMoney(priceRate.pay)}
                </Badge>
              </Button>
            ))}
        </>
      ),
    },
    {
      label: "Remark",
      name: "remark",
      type: "textarea",
      required: false,
      // value: data.remark,
      disabled,
    },
    {
      label: "Other Expenses",
      name: "otherExpense",
      type: "label",
    },
  ];

  datasource.jobexpense
    .filter((f) => dhlOtherExpenseCodes.indexOf(f.code) === -1)
    .map((otherExpense) => {
      fields.push({
        label: otherExpense.label,
        name: "otherExpense_" + otherExpense.key,
        type: "number",
        placeholder: "Price",
        uom: "฿",
        col: 3,
        decimal: 2,
        disabled,
      });
    });

  const leftPanel = (
    <>
      <strong style={{ fontSize: "14px", color: "#676a6c", fontWeight: "600" }}>
        By {data.createdByName}
      </strong>
      <br />
      <small className="text-muted">
        {DateService.convertDateTimeToString(data.createdAt)}
      </small>
      <br />
      {data.invoiceId && (
        <>
          <strong>Invoice No: </strong>
          <Button
            variant="link"
            className="text-link p-1"
            target="_blank"
            href={`/transaction/invoice/edit/${data.invoiceId}`}
          >
            {data.invoiceNo}
          </Button>
        </>
      )}
      {data.receiptInfo && (
        <>
          | <strong>Receipt No: </strong>
          {data.receiptInfo.map((receipt) => (
            <Button
              variant="link"
              className="text-link p-1"
              target="_blank"
              href={`/transaction/receipt/edit/${receipt.receiptId}`}
            >
              {receipt.receiptNo}
            </Button>
          ))}
        </>
      )}
    </>
  );

  const buttons = [];

  if (data.dnReturnId) {
    buttons.unshift(
      <Button
        key={1}
        variant="link"
        target="_blank"
        href={`/transaction/dnreturn/edit/${data.dnReturnId}`}
      >
        <i className="fa fa-chevron-right pr-1" aria-hidden="true"></i>Go to
        DNReturn
      </Button>
    );
  }

  const fieldOnlyDHL = {
    cancelJob: {
      label: "Cancel Job",
      name: "isCancelJob",
      type: "checkbox",
      required: false,
      value: data.isCancelJob,
      disabled,
    },
    otherExpenseLabel: {
      label: "Other Expenses",
      name: "otherExpense",
      type: "label",
    },

    otherExpenses: datasource.jobexpense
      .filter((f) => dhlOtherExpenseCodes.indexOf(f.code) !== -1)
      .map((otherExpense) => {
        const name = "otherExpense_" + otherExpense.key;
        const price = data[name];
        return {
          key: otherExpense.key,
          label: otherExpense.label,
          name: name,
          type: "number",
          placeholder: "Price",
          uom: "฿",
          decimal: 2,
          col: 12,
          value: price,
          disabled,
        };
      }),
  };

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        defaultValues={data}
        loading={isLoading}
        fields={fields}
        enableSave={!disabled}
        onSubmit={onSubmit}
        buttons={buttons}
        url={url}
        onErrors={(errors) => setErrors(errors)}
        leftPanel={leftPanel}
        onChange={({ name, value, ...params }, values) => {
          if (name === "customerId") {
            setCustomerId(value);
            setSourcePlaceId(null);
            setDestinationPlaceId(null);
            setData({
              ...values,
              customerId: value,
              sourcePlaceId: null,
              destinationPlaceId: null,
            });
            setPriceRates([]);
          } else if (name === "carNoId") {
            setPriceRates([]);
            if (!params.isNew) {
              setEmployeeId(value);
              setData({
                ...values,
                employeeId: value,
              });
            } else {
              setData({
                ...values,
              });
            }
          } else if (name === "wheel") {
            setPriceRates([]);
            setWheel(value);
            setData({
              ...values,
              wheel: value,
              carNoId: null,
              employeeId: null,
            });
          } else if (name === "employeeId") {
            setPriceRates([]);
            setEmployeeId(value);
            setData({
              ...values,
              employeeId: value,
            });
          } else if (name === "sourcePlaceId") {
            setPriceRates([]);
            setSourcePlaceId(value);
            setData({
              ...values,
              sourcePlaceId: value,
            });
          } else if (name === "destinationPlaceId") {
            setPriceRates([]);
            setDestinationPlaceId(value);
            setData({
              ...values,
              destinationPlaceId: value,
            });
          } else {
            setData({
              ...values,
            });
          }
          // else if (name === "bill") {
          //   setData({ ...values, sts: value });
          // } else {
          //   setData({ ...data, ...values });
          // }
        }}
      >
        <hr />
        <div className="card mb-3">
          <div className="card-header">
            <strong>Only DHL</strong>
          </div>
          <div className="card-body">
            <div className="col-12">
              <FormInput
                field={fieldOnlyDHL.cancelJob}
                onChange={({ name, value }) => {
                  setData({ ...data, [name]: value });
                }}
              ></FormInput>
            </div>
            {fieldOnlyDHL.otherExpenses.map((otherExpenseField) => (
              <FormInput
                key={otherExpenseField.key}
                field={otherExpenseField}
                onChange={({ name, value }) => {
                  setData({ ...data, [name]: value });
                }}
              ></FormInput>
            ))}
          </div>
        </div>
      </DataForm>
    </BoxContainer>
  );
}
