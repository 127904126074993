import axios from "@standard/axios";

export const getJobs = ({
  customerId,
  transactionDateFrom,
  transactionDateTo,
  typeOfTransportId,
  invoiceId,
}) => {
  const promise = new Promise((resolve, reject) => {
    const params = {
      customerId: customerId,
      transactionDateFrom: transactionDateFrom,
      transactionDateTo: transactionDateTo,
      typeOfTransportId: typeOfTransportId,
      invoiceId: invoiceId,
    };

    axios
      .post(`transaction/invoice/getjobs/`, params)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const requestUnlocked = ({ requestByUserId, invoiceId }) => {
  const promise = new Promise((resolve, reject) => {
    const params = {
      requestBy: requestByUserId,
    };

    axios
      .post(`transaction/invoice/requestunlocked/${invoiceId}`, params)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const unlocked = ({ invoiceId }) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .post(`transaction/invoice/unlocked/${invoiceId}`)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const lock = ({ invoiceId }) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .post(`transaction/invoice/lock/${invoiceId}`)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};
