import { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSearchCriteria } from "@standard/store/reducers/dataTableSlice";
import { DateService } from "@standard/services";

function SearchCriteriaFilterBy() {
  const dispatch = useDispatch();
  const { searchCriteria, config } = useSelector((state) => state.dataTable);
  const removeFilter = (index) => {
    let filters = [...searchCriteria.filters];
    filters.splice(index, 1);
    dispatch(setSearchCriteria({ filters }));
  };

  const onClearAllFilters = () => {
    dispatch(setSearchCriteria({ filters: [] }));
  };

  const badgeColors = [
    "badge-primary",
    "badge-warning",
    "badge-info",
    "badge-secondary",
    "badge-danger",
  ];

  const filterByFields = searchCriteria.filters.map((item, index) => {
    let { label, value, datasource, type } = item;

    if (type === "date") {
      if (value instanceof Array) {
        value = value
          .map((v) => (v ? DateService.convertDateToString(v) : ""))
          .join(" - ");
      } else {
        value = DateService.convertDateToString(value);
      }
    } else if (value instanceof Array) {
      value = value
        .map((m) => {
          if (datasource) {
            const data = datasource.find((k) => k.key === m);
            if (data) {
              return data.label;
            }
          }

          return m;
        })
        .join(", ");
    } else if (datasource && datasource.length > 0) {
      const data = datasource.find((k) => k.key === value);
      if (data) value = data.label;
    } else {
      if (value === true) {
        value = "True";
      } else if (value === false) {
        value = "False";
      }
    }

    return (
      <span
        className={"badge mx-1 " + badgeColors[index % badgeColors.length]}
        key={index}
      >
        {label}: {value}
        <span
          className="remove"
          onClick={() => removeFilter(index)}
          title="Remove"
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </span>
      </span>
    );
  });

  return (
    <div className="mt-1 filter-by-container">
      <small>Filter by</small> {filterByFields}{" "}
      {filterByFields.length > 0 && (
        <button
          className="btn btn-link text-link text-danger"
          onClick={onClearAllFilters}
        >
          <i className="fa fa-times"></i> Clear
        </button>
      )}
    </div>
  );
}

export default memo(SearchCriteriaFilterBy);
