import axios from "@standard/axios";
import { DateService } from "@standard/services";

export const getEmployees = ({
  isOutsource,
  employeeGroupId,
  transactionDateFrom,
  transactionDateTo,
  invoiceEmployeeId,
  wheel,
}) => {
  const promise = new Promise((resolve, reject) => {
    const params = {
      isOutsource: isOutsource,
      employeeGroupId,
      transactionDateFrom: DateService.convertDateToServer(transactionDateFrom),
      transactionDateTo: DateService.convertDateToServer(transactionDateTo),
      invoiceEmployeeId,
      wheel: parseInt(wheel),
    };

    axios
      .post(`transaction/invoiceemployee/getemployees/`, params)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const updatePayJobs = ({ jobs, id }) => {
  const promise = new Promise((resolve, reject) => {
    const params = {
      jobs,
    };

    axios
      .put(`transaction/invoiceemployee/updatepayjobs/${id}`, params)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const updatePriceExpenses = ({
  expenses,
  employeeId,
  carNoId,
  invoiceEmployeeId,
}) => {
  const promise = new Promise((resolve, reject) => {
    const params = {
      expenses,
      employeeId,
      carNoId,
      invoiceEmployeeId,
    };

    axios
      .put(`transaction/invoiceemployee/updatepriceexpenses/`, params)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const createNewInvoiceEmployee = ({ employees, id }) => {
  const promise = new Promise((resolve, reject) => {
    const params = {
      employees,
    };

    axios
      .post(
        `transaction/invoiceemployee/createnewinvoiceemployee/${id}`,
        params
      )
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};
