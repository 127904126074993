import axios from "@standard/axios";

// tables = ["employeegroup", "employee"]
// tables = [{name: "employeegroup", value: "62986777dde29a4b32920f18"}]
export const getActiveDatasource = (tables) => {
  const promise = new Promise((resolve, reject) => {
    const params = { tables };
    axios
      .post(`datasource/active`, params)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};
