import axios from "@standard/axios";

export const getInvoices = ({ customerId, receiptId }) => {
  const promise = new Promise((resolve, reject) => {
    const params = {
      customerId: customerId,
      receiptId: receiptId,
    };

    axios
      .post(`transaction/receipt/getinvoices/`, params)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const requestUnlocked = ({ requestByUserId, receiptId }) => {
  const promise = new Promise((resolve, reject) => {
    const params = {
      requestBy: requestByUserId,
    };

    axios
      .post(`transaction/receipt/requestunlocked/${receiptId}`, params)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const unlocked = ({ receiptId }) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .post(`transaction/receipt/unlocked/${receiptId}`)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const lock = ({ receiptId }) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .post(`transaction/receipt/lock/${receiptId}`)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};
