import { UtilsService } from "@standard/services";
import React, { Component, useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import "./Dropdown.css";

const Dropdown = ({
  placeholder,
  createable,
  disabled,
  multiple,
  value,
  onChange,
  name,
  datasource,
  required,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (multiple === true && value instanceof Array && value.length > 0) {
      if (
        selectedOption === null ||
        !UtilsService.arraysEqual(
          selectedOption.map((m) => m.value),
          value
        )
      ) {
        const data = datasource
          .filter((f) => value.indexOf(f.key) >= 0)
          .map((item) => {
            return {
              value: item.key,
              label: item.label,
            };
          });

        // console.log("Set multiple data", data, value);

        setSelectedOption(data);
      }
    } else {
      const data = datasource.find((f) => f.key == value);

      if (data) {
        setSelectedOption({
          value: data.key,
          label: data.label,
        });
      } else if (createable && value) {
        setSelectedOption({
          value: value,
          label: value,
          __isNew__: true,
        });
      } else if (
        (value === null || (value instanceof Array && value.length === 0)) &&
        data === undefined
      ) {
        setSelectedOption(null);
      }
    }
  }, [value]);

  useEffect(() => {
    if (onChange && onChange instanceof Function) {
      if (value !== selectedOption) {
        if (multiple === false && value !== selectedOption?.value) {
          onChange({
            [name]: selectedOption?.value,
            isNew: selectedOption?.__isNew__,
          });
        }

        if (multiple === true && selectedOption !== null) {
          // console.log(name, "DDL selectedOption", selectedOption, value);
          onChange({
            [name]: selectedOption.map((m) => m.value),
            isNew: selectedOption?.__isNew__,
          });
        }
      }
    }
  }, [selectedOption]);

  const onSelectedChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const options = datasource.map((item) => {
    const returnValue = {
      value: item.key,
      label: item.label,
    };

    return returnValue;
  });

  const controlProps = {
    placeholder: placeholder,
    className: "ddl",
    classNamePrefix: "ddl",
    value: selectedOption,
    options: options,
    isDisabled: disabled,
    isMulti: multiple,
    onChange: onSelectedChange,
    isClearable: true,
  };

  let control;

  if (createable) {
    control = <CreatableSelect {...controlProps} />;
  } else {
    control = <Select {...controlProps} />;
  }

  return (
    <div>
      {control}
      {!disabled && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            height: 0,
            width: "100%",
            position: "absolute",
          }}
          value={value ?? ""}
          onChange={() => {}}
          // required={required}
        />
      )}
      {/* <pre>{JSON.stringify(selectedOption, null, 2)}</pre> */}
    </div>
  );
};

Dropdown.defaultProps = {
  datasource: [],
  name: "",
  id: "",
  disabled: false,
  value: "",
  onChange: [],
  required: false,
  placeholder: "Please select",
  // ajax: false,
  createable: false,
  multiple: false,
};

export default Dropdown;
