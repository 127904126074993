import cookie from "js-cookie";
import { STORAGE_NAME } from "@standard/constants";

const getToken = () => {
  const _token = cookie.get(STORAGE_NAME.TOKEN_NAME);

  return _token;
};

const setToken = (token) => {
  cookie.set(STORAGE_NAME.TOKEN_NAME, token);
};

const removeToken = () => {
  cookie.remove(STORAGE_NAME.TOKEN_NAME);
};

export default {
  getToken,
  setToken,
  removeToken,
};
