import { UtilsService } from "@standard/services";
import { useSelector } from "react-redux";
import { getState } from "@standard/store/reducers/containerSlice";

function LayoutWithUnauthen({ children }) {
  // const projectName = Utils.getProjectName();
  // const { isLoading, errors } = useSelector(getState);

  return (
    <div className="center-login gray-bg">
      <div className="login animated fadeInDown ">{children}</div>
    </div>
  );
}

export default LayoutWithUnauthen;
