import { AlertService } from "@standard/services";
import { PriceRateAPI } from "api";
import { useContext, memo } from "react";
import { Badge } from "react-bootstrap";
import { PriceRateContext, PRICE_RATE_ACTION } from "./PriceRateFormDetail";
import PriceRateFormInput from "./PriceRateFormInput";
import PriceRatePlaceModal from "./PriceRatePlaceModal";
import PriceRatePlaceName from "./PriceRatePlaceName";

const PriceRateFormRow = ({ priceRate }) => {
  const { dispatch, fetchData } = useContext(PriceRateContext);
  const onDeletePriceRate = () => {
    AlertService.confirm("Do you want to delete ?").then((res) => {
      if (res === true) {
        dispatch({ type: PRICE_RATE_ACTION.DELETE_PRICE_RATE });
        PriceRateAPI.removePriceRate({ priceRateId: priceRate._id }).then(
          () => {
            fetchData();
          }
        );
      }
    });
  };
  return (
    <tr>
      <td className="">
        {priceRate.priceRateTypeName ? (
          <Badge className="float-right" bg="primary">
            {priceRate.priceRateTypeName}
          </Badge>
        ) : (
          ""
        )}
        <PriceRatePlaceName label="Sources" places={priceRate.sourcePlaces} />{" "}
        <PriceRatePlaceName
          label="Destinations"
          places={priceRate.destinationPlaces}
          bg="warning"
        />
        <div className="btn-group d-block" role="group">
          <button
            type="button"
            className="btn btn-danger w-50"
            onClick={onDeletePriceRate}
          >
            Delete
          </button>
          <PriceRatePlaceModal
            priceRate={priceRate}
            onSave={(value) => {}}
            label="Edit"
            className="w-50"
            variant="info"
            title="Edit Price Rate"
          />
        </div>
      </td>
      {priceRate.detail.map((ratio, ratioIndex) => (
        <td key={ratioIndex}>
          <PriceRateFormInput detail={ratio} detailIndex={ratioIndex} />
        </td>
      ))}
    </tr>
  );
};

export default memo(PriceRateFormRow);
