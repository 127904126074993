import DataTable from "@standard/components/DataTable";
import TitleHeader from "@standard/components/TitleHeader";
import InvoiceEmployeeExpenseForm from "./InvoiceEmployeeExpenseForm";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ExpenseType from "./ExpenseType";
import { expenseTypeData } from "data";

const programType = "Master";
const programName = "Invoice Employee Expense Setup";
const url = "master/invoiceemployeeexpense";
const columns = [
  {
    label: "Expense Name",
    name: "name",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Percent",
    name: "percent",
    sortable: true,
    align: "center",
  },
  {
    label: "Expense Type",
    name: "expenseType",
    align: "center",
    sortable: true,
    search: { type: "checkbox", datasource: expenseTypeData, inline: true },
  },
];

const config = {
  enableActive: true,
  enableEdit: true,
  enableTrash: true,
  onBeforeBind: (data) => {
    console.log("InvoiceEmployeeExpenses", data);
    return data.map((item) => {
      const { expenseType, percent } = item.values;

      item.values.percent = percent ? `${percent} %` : "";

      item.values.expenseType = <ExpenseType expenseType={expenseType} />;

      return item;
    });
  },
};

export default function InvoiceEmployeeExpense() {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <DataTable columns={columns} url={url} {...config} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <InvoiceEmployeeExpenseForm url={url} />
        </Route>
      </Switch>
    </>
  );
}
