import DataTable from "@standard/components/DataTable";
import {
  dnReturnTypeData,
  wheelData,
  dnReturnPrintStatusData,
  jobStatusData,
} from "data";

import { jobStatus as jobStatusConst } from "data/jobStatus";
import { Badge, Button } from "react-bootstrap";
import { AlertService, DateService } from "@standard/services";
import axios from "@standard/axios";
import DNReturnType from "./DNReturnType";
import DNReturnPrintStatus from "./DNReturnPrintStatus";
import { memo } from "react";

const DNReturnDataTable = ({ url }) => {
  const columns = [
    {
      label: "Job STS",
      name: "jobSTS",
      sortable: true,
      align: "center",
      search: { type: "text" },
    },
    {
      label: "Type",
      name: "type",
      align: "center",
      sortable: true,
      search: { type: "checkbox", datasource: dnReturnTypeData, inline: true },
    },
    {
      label: "Return Date",
      name: "returnDate",
      sortable: true,
      align: "center",
      search: { type: "date", isRange: true },
    },
    {
      label: "Wheel",
      name: "wheel",
      align: "center",
      sortable: true,
      search: { type: "checkbox", datasource: wheelData, inline: true },
    },
    {
      label: "Status",
      name: "status",
      align: "center",
      sortable: true,
      search: {
        type: "checkbox",
        datasource: dnReturnPrintStatusData,
        inline: true,
      },
    },
  ];
  const buttons = [
    {
      variant: "danger",
      label: '<i className="fa fa-print" aria-hidden="true"></i> Export Excel',
      onClick: (selectedId) => {
        if (selectedId.length === 0) {
          AlertService.warning("Please select item to export");
          return;
        }
        const listId = selectedId.join("|");
        const printUrl = `${axios.defaults.baseURL}${url}/print/${listId}`;
        window.open(printUrl, "_blank");
      },
    },
  ];

  const config = {
    customFilters: [
      {
        label: "Job Status",
        name: "jobStatus",
        columnName: "job.status",
        type: "checkbox",
        datasource: jobStatusData,
        inline: true,
      },
    ],
    enableView: true,
    enableActive: false,
    enableEdit: true,
    enableTrash: true,
    enableCheckbox: true,
    buttons: buttons,
    onBeforeBind: (data) => {
      console.log("DNReturn before bind");

      return data.map((item) => {
        const { jobSTS, type, wheel, status, jobStatus, returnDate, jobId } =
          item.values;

        item.values.jobSTS = (
          <>
            {jobStatus === jobStatusConst.COMPLETE ? (
              <i className="fa fa-check" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-times" aria-hidden="true"></i>
            )}
            <Button
              variant="link"
              className="text-link p-1"
              href={`/transaction/job/edit/${jobId}`}
              target="_blank"
            >
              {jobSTS}
            </Button>
          </>
        );

        item.values.returnDate = DateService.convertDateToString(returnDate);

        item.values.type = <DNReturnType type={type} />;

        item.values.wheel = wheel === 4 ? "4 WD" : "6 WD";
        item.values.status = <DNReturnPrintStatus printStatus={status} />;

        return item;
      });
    },
  };

  return <DataTable columns={columns} url={url} {...config} />;
};

export default memo(DNReturnDataTable);
