import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory } from "react-router-dom";
import React, { useState, memo, useEffect, useMemo, useCallback } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import {
  DateService,
  AuthService,
  FormService,
  AlertService,
} from "@standard/services";
import { Button, DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import { DatasourceAPI, InvoiceAPI, InvoiceEmployeeAPI, ReceiptAPI } from "api";
import axios from "@standard/axios";
import { employeeTypeData, wheelData } from "data";
import InvoiceEmployeeDescription from "./InvoiceEmployeeDescription";

export const InvoiceEmployeeFormContext = React.createContext({});

const getDefaultTaxDate = () => {
  const currentDate = new Date();
  const defaultTaxDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  return defaultTaxDate;
};

export default function InvoiceEmployeeForm({ url }) {
  const history = useHistory();
  const { id: paramsId, action } = useParams();
  const [id, setId] = useState(paramsId);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userName = AuthService.getUserName();

  const defaultData = {
    employeeGroupId: null,
    isOutsource: 1,
    wheel: 4,
    transactionDate: [null, null],
    taxDate: getDefaultTaxDate(),
    createdBy: userName,
    updatedBy: userName,
  };

  const [data, setData] = useState(defaultData);
  const [employees, setEmployees] = useState([]);
  const [datasource, setDatasource] = useState({
    employeegroup: [],
  });
  const [employeeFilters, setEmployeeFilters] = useState({
    isOutsource: data.isOutsource,
    wheel: data.wheel,
    employeeGroupId: data.employeeGroupId,
    transactionDate: data.transactionDate,
  });

  const disabledField = useMemo(() => id !== undefined, []);

  useEffect(() => {
    const { isOutsource, wheel, employeeGroupId, transactionDate } =
      employeeFilters;
    const [transactionDateFrom, transactionDateTo] = transactionDate;
    if (transactionDateFrom !== null && transactionDateTo !== null) {
      setIsLoading(true);

      InvoiceEmployeeAPI.getEmployees({
        isOutsource,
        employeeGroupId,
        transactionDateFrom,
        transactionDateTo,
        invoiceEmployeeId: id,
        wheel,
      }).then((employeeResult) => {
        const employeeEntities = employeeResult.map((employee) => {
          if (id) {
            employee.selected =
              data.employees.findIndex(
                (i) =>
                  i.employeeId === employee.employeeId &&
                  i.carNoId === employee.carNoId
              ) >= 0;
          } else {
            employee.selected = true;
          }

          return employee;
        });

        setEmployees(employeeEntities);
        setIsLoading(false);
      });
    }
  }, [employeeFilters]);

  const fetchData = () => {
    if (id !== undefined) {
      FormService.getRequest(url, id).then((dataResult) => {
        const tables = [
          { name: "employeegroup", value: dataResult.employeeGroupId },
        ];

        DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
          setDatasource(datasourceResult);

          const transactionDate = [
            DateService.convertDateToServer(
              new Date(dataResult.transactionDateFrom)
            ),
            DateService.convertDateToServer(
              new Date(dataResult.transactionDateTo)
            ),
          ];

          setData({
            ...dataResult,
            transactionDate,
            isOutsource: dataResult.isOutsource,
          });

          setEmployeeFilters({
            isOutsource: dataResult.isOutsource,
            wheel: dataResult.wheel,
            employeeGroupId: dataResult.employeeGroupId,
            transactionDate: transactionDate,
          });

          setIsLoading(false);
        });
      });
    } else {
      const tables = [{ name: "employeegroup" }];

      DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
        setDatasource(datasourceResult);

        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const save = (newData) => {
    setIsLoading(true);
    const savedData = { ...newData };
    const [transactionDateFrom, transactionDateTo] = savedData.transactionDate;

    const selectedEmployee = employees.filter((f) => f.selected);

    const jobs = [];

    savedData.employees = selectedEmployee.map((employee) => {
      employee.jobs.map((job) => jobs.push(job));
      return {
        employeeId: employee.employeeId,
        carNoId: employee.carNoId,
        invoiceEmployeeExpenses: employee.invoiceEmployeeExpenses.map(
          (invoiceEmployeeExpense) => {
            return {
              invoiceEmployeeExpenseId: invoiceEmployeeExpense._id,
              value: invoiceEmployeeExpense.value,
            };
          }
        ),
      };
    });

    savedData.taxDate = DateService.convertDateToServer(savedData.taxDate);
    savedData.transactionDateFrom =
      DateService.convertDateToServer(transactionDateFrom);
    savedData.transactionDateTo =
      DateService.convertDateToServer(transactionDateTo);

    savedData.jobs = jobs;
    savedData.id = id;

    const params = { ...savedData };

    FormService.submitRequest(url, params)
      .then((result) => {
        if (id) {
          fetchData();
        } else {
          history.push(`/${url}/edit/${result.data._id}`);
          setId(result.data._id);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const onSubmit = (newData) => {
    AlertService.confirm("Do you want to save ?", "Confirmation").then(
      (res) => {
        if (res === true) {
          save(newData);
        }
      }
    );
  };

  const onCreateNewInvoiceEmployeeClick = () => {
    const selectedEmployee = employees.filter((f) => f.selected);
    if (selectedEmployee.length === 0) {
      AlertService.warning(
        "Please select employee to create new invoice employee"
      );
    } else {
      AlertService.confirm(
        "Do you want to create new invoice employee with selected data ?",
        "Confirmation"
      ).then((res) => {
        if (res === true) {
          setIsLoading(true);
          InvoiceEmployeeAPI.createNewInvoiceEmployee({
            id,
            employees: selectedEmployee.map((employee) => {
              return {
                carNoId: employee.carNoId,
                employeeId: employee.employeeId,
              };
            }),
          }).then((result) => {
            window.open(`/transaction/invoiceemployee/edit/${result._id}`);
            console.log("result", result);

            fetchData();
          });
        }
      });
    }
  };

  const fields = [
    {
      label: "Type",
      name: "isOutsource",
      type: "radio",
      inline: true,
      required: true,
      datasource: employeeTypeData,
      value: data.isOutsource,
      disabled: disabledField,
    },
    {
      label: "Wheel",
      name: "wheel",
      type: "radio",
      required: true,
      datasource: wheelData,
      value: data.wheel,
      inline: true,
      disabled: disabledField,
    },
    {
      label: "Employee Group",
      name: "employeeGroupId",
      type: "ddl",
      required: false,
      datasource: datasource.employeegroup,
      value: data.employeeGroupId,
      disabled: disabledField,
    },
    {
      label: "Transaction Date",
      name: "transactionDate",
      type: "date",
      required: true,
      isRange: true,
      value: data.transactionDate,
      disabled: disabledField,
    },
    {
      label: "Tax Date",
      name: "taxDate",
      type: "date",
      required: true,
      value: data.taxDate,
      disabled: disabledField,
    },
  ];

  const baseUrl = axios.defaults.baseURL;

  let buttons = [];

  if (id) {
    buttons = [
      <DropdownButton as={ButtonGroup} className="mr-1" title="Report" key={1}>
        <Dropdown.Item
          target="_blank"
          href={`${baseUrl}transaction/invoiceemployee/employeedetail/print/${id}`}
          eventKey="1"
        >
          Employee Detail Report
        </Dropdown.Item>
        <Dropdown.Item
          target="_blank"
          href={`${baseUrl}transaction/invoiceemployee/employeeinvoice/print/${id}`}
          eventKey="2"
        >
          Employee Invoice Report
        </Dropdown.Item>
        <Dropdown.Item
          target="_blank"
          href={`${baseUrl}transaction/invoiceemployee/employeetax/print/${id}`}
          eventKey="2"
        >
          หนังสือรับรองการหักภาษี ณ ที่จ่าย
        </Dropdown.Item>
      </DropdownButton>,
    ];

    buttons.push(
      <Button
        key={2}
        className="btn btn-danger mr-1"
        onClick={onCreateNewInvoiceEmployeeClick}
      >
        Create New Invoice Employee
      </Button>
    );
  }

  const invoiceEmployeeDescriptionOnChange = useCallback(
    (data) => setEmployees(data),
    []
  );

  const contextValues = {
    setIsLoading,
    invoiceEmployeeId: id,
  };

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        defaultValues={data}
        loading={isLoading}
        buttons={buttons}
        fields={fields}
        onSubmit={onSubmit}
        url={url}
        onErrors={(errors) => setErrors(errors)}
        onChange={({ name, value }, values) => {
          if (
            [
              "isOutsource",
              "transactionDate",
              "employeeGroupId",
              "wheel",
            ].indexOf(name) !== -1
          ) {
            setEmployeeFilters({
              ...employeeFilters,
              [name]: value,
            });
          }

          setData({ ...data, ...values });
        }}
      >
        <InvoiceEmployeeFormContext.Provider value={contextValues}>
          <InvoiceEmployeeDescription
            employees={employees}
            id={id}
            onChange={invoiceEmployeeDescriptionOnChange}
          />
        </InvoiceEmployeeFormContext.Provider>
      </DataForm>
    </BoxContainer>
  );
}
