import DataTable from "@standard/components/DataTable";
import UserStatus from "./UserStatus";
import { userStatusData } from "data";

const url = "master/user";
const columns = [
  {
    label: "Username",
    name: "username",
    align: "center",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Email",
    name: "email",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Full Name",
    name: "fullName",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Status",
    name: "status",
    align: "center",
    sortable: true,
    search: {
      type: "checkbox",
      datasource: userStatusData,
      inline: true,
      // defaultValue: userStatusData.map((m) => m.key),
    },
  },
];

const config = {
  enableActive: true,
  enableEdit: true,
  enableTrash: true,
  onBeforeBind: (data) => {
    return data.map((item) => {
      const { status } = item.values;
      item.values.status = <UserStatus status={status} />;

      return item;
    });
  },
};

export default function UserTable() {
  return <DataTable columns={columns} url={url} {...config} />;
}
