import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory } from "react-router-dom";
import { useState, memo, useCallback } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { FormService } from "@standard/services";
import { useEffect } from "react";
import PriceRateFormDetail from "./PriceRateFormDetail";
import PriceRateFormHistory from "./PriceRateFormHistory";
import { Tabs, Tab } from "react-bootstrap";

export default function PriceRateForm() {
  // const history = useHistory();
  const { id, wheel } = useParams();
  const url = `master/customer/${id}`;
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState({
    customerName: "",
    wheel: "",
    ceil: "",
    employeeGroups: [],
    autoUpdatePriceRate: false,
    ceilLabel: "",
    autoUpdatePriceRateLabel: "",
    employeeGroupsLabel: "",
  });

  const fetchData = () => {
    FormService.getRequest(url).then((dataResult) => {
      setData({
        customerName: dataResult.name,
        wheel: wheel + " ล้อ",
        employeeGroups: dataResult.employeeGroupNames,
        autoUpdatePriceRate: dataResult.autoUpdatePriceRate,
        ceil: dataResult.ceil,
        ceilLabel: dataResult.ceil ? "ปัดเศษ" : "ไม่ปัดเศษ",
        autoUpdatePriceRateLabel: dataResult.autoUpdatePriceRate
          ? "ใช่"
          : "ไม่ใช่",
        employeeGroupsLabel: dataResult.employeeGroupNames
          .map((employeeGroup) => employeeGroup.label)
          .join(", "),
      });

      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fields = [
    {
      label: "Customer Name",
      name: "customerName",
      type: "label",
      required: false,
    },
    {
      label: "Wheel",
      name: "wheel",
      type: "label",
      required: false,
    },
    {
      label: "Ceil",
      name: "ceilLabel",
      type: "label",
      required: false,
    },
    {
      label: "อัพเดทราคาอัตโนมัติ",
      name: "autoUpdatePriceRateLabel",
      type: "label",
      required: false,
    },
    {
      label: "Employee Group",
      name: "employeeGroupsLabel",
      type: "label",
      required: false,
    },
  ];

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        defaultValues={data}
        fields={fields}
        enableSave={false}
        url={`master/customer/edit/${id}`}
        onErrors={(errors) => setErrors(errors)}
      >
        <Tabs defaultActiveKey={"priceRate"} id="tabs" className="mb-3">
          <Tab title="Price Rate" eventKey="priceRate">
            {isLoading ? (
              "Loading..."
            ) : (
              <PriceRateFormDetail
                customerId={id}
                wheel={wheel}
                autoUpdatePriceRate={data.autoUpdatePriceRate}
                ceil={data.ceil}
                employeeGroups={data.employeeGroups}
              />
            )}
          </Tab>
          <Tab title="History" eventKey="history">
            <PriceRateFormHistory customerId={id} wheel={wheel} />
          </Tab>
        </Tabs>
      </DataForm>
    </BoxContainer>
  );
}
