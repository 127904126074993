import React, { useState, memo, useEffect, useRef, forwardRef } from "react";
import Button from "react-bootstrap/Button";
import ReactModal from "react-bootstrap/Modal";
import FormInput from "../DataForm/FormInput";

const Modal = (
  {
    label,
    title,
    children,
    enableSave,
    onSave,
    onClosed,
    onShown,
    fields,
    disabled,
    variant = "link",
  },
  ref
) => {
  // const button = useRef();
  const [show, setShow] = useState(false);
  const [values, setValues] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onSaveModal = () => {
    if (onSave) {
      onSave(values).then((result) => setShow(result.show));
    }
  };

  useEffect(() => {
    const defaultValues = {};

    if (fields)
      fields.map((field) => (defaultValues[field.name] = field.value));

    setValues(defaultValues);
  }, []);

  useEffect(() => {
    const defaultValues = {};

    if (fields)
      fields.map((field) => (defaultValues[field.name] = field.value));

    setValues(defaultValues);
  }, [fields]);

  useEffect(() => {
    if (show === false && onClosed) onClosed();
    if (show === true && onShown) onShown();
  }, [show]);

  const onInputChange = ({ name, value }) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  let className = [];
  if (variant === "link") {
    className.push("p-1 text-link");
  }

  return (
    <>
      <Button
        variant={variant}
        className={className.join(" ")}
        onClick={handleShow}
        disabled={disabled}
        ref={ref}
      >
        {label}
      </Button>

      <ReactModal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby={label}
      >
        <ReactModal.Header className="bg-primary">
          <ReactModal.Title>{title ?? label}</ReactModal.Title>
          <a href="#" className="mt-2 mr-3 text-white" onClick={handleClose}>
            <i className="fa fa-times" style={{ fontSize: "1.5em" }}></i>
          </a>
        </ReactModal.Header>
        <ReactModal.Body>
          {fields &&
            fields.map((field, fieldIndex) => (
              <FormInput
                field={field}
                key={fieldIndex}
                onChange={onInputChange}
              />
            ))}
          {children}
        </ReactModal.Body>
        <ReactModal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {enableSave && (
            <Button variant="primary" onClick={onSaveModal}>
              Save Changes
            </Button>
          )}
        </ReactModal.Footer>
      </ReactModal>
    </>
  );
};

export default forwardRef(Modal);
