import { dnReturnType } from "data/dnReturnType";
import { dnReturnTypeData } from "data";
import { memo } from "react";

const DNReturnType = ({ type }) => {
  let text = dnReturnTypeData.find((f) => f.key === type)?.label;
  let className = ["badge p-2"];

  switch (type) {
    case dnReturnType.IMPORT:
      className.push("badge-info");
      break;
    case dnReturnType.EXPORT:
      className.push("badge-primary");
      break;
    case dnReturnType.PAT:
      className.push("badge-danger");
      break;
  }

  return <span className={className.join(" ")}>{text}</span>;
};

export default memo(DNReturnType);
