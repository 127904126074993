import {
  setLoading as containerSetLoading,
  setErrors as containerSetErrors,
  clearErrors as containerClearErrors,
} from "@standard/store/reducers/containerSlice";
import { store } from "@standard/store";
import { useDispatch } from "react-redux";
import axios from "../axios";
import { AlertService } from ".";
const $ = window.$;

function goToTop() {
  var body = $("html, body");
  body.stop().animate({ scrollTop: 0 }, 500, "swing");
}

function submitRequestWithFile(params, options = {}) {
  const default_options = { show_popup: true };
  options = {
    ...default_options,
    ...options,
  };

  const request_options = {
    headers: { "content-type": "multipart/form-data" },
  };

  let form_data = new FormData();

  for (var key in params.params) {
    let value = params.params[key];
    if (value === null) {
      value = "";
    }

    if (value instanceof Array) {
      form_data.append(key, JSON.stringify(value));
    } else if (value instanceof FileList) {
      for (let i = 0; i < value.length; i++) {
        form_data.append(key, value[i]);
      }
    } else {
      form_data.append(key, value);
    }
  }

  const params_request = {
    url: params.url,
  };

  const promise = new Promise((resolve, reject) => {
    let request;
    const id = params.params.id;
    const url = params_request.url;

    if (id !== undefined && id !== null) {
      // Update
      form_data.append("_method", "PUT");
      request = axios.put(url + "/" + id, form_data, request_options); // change from post to put
    } else {
      // Insert
      request = axios.post(url, form_data, request_options);
    }

    request
      .then((result) => {
        if (options.show_popup === true) {
          // swal({
          //   title: SERVICES.LABEL_SUCCESS,
          //   text: SERVICES.LABEL_SAVE_SUCCESS,
          //   icon: SERVICES.ICON.SUCCESS,
          //   button: SERVICES.BUTTON.OK,
          // }).then((res) => {
          //   resolve(result);
          // });
        } else {
          resolve(result);
        }
      })
      .catch((res) => {
        // if(res.data){
        //     reject(res.data);
        // }else{

        try {
          // var msg = [];
          // if (res.data instanceof Array) {
          //     msg = res.data;
          // } else {
          //     for (var i in res.data.errors) {
          //         res.data.errors[i].map(item => {
          //             return msg.push(item);
          //         });
          //     }
          // }
          var msg = [];
          if (res !== undefined) {
            if (res.data !== undefined) {
              if (res.data.message !== undefined) {
                msg.push(res.data.message);
              } else {
                for (var i in res.data.errors) {
                  res.data.errors[i].map((item) => {
                    return msg.push(item);
                  });
                }
              }
            } else {
              for (var it in res.response.data.errors) {
                res.response.data.errors[it].map((item) => {
                  return msg.push(item);
                });
              }
            }
          }
          goToTop();
          reject(msg);
        } catch (error) {
          // swal({
          //   title: SERVICES.LABEL_ERROR_TH,
          //   text: "Something wrong, please try again latter.",
          //   icon: SERVICES.ICON.ERROR,
          //   button: SERVICES.BUTTON.OK,
          // }).then(() => reject([]));
          console.log(error);
        }
        // }
      });
  });

  return promise;
}

function getRequest(url, id = "") {
  const promise = new Promise((resolve, reject) => {
    if (id instanceof Object) {
      axios.get(`${url}\\${Object.values(id).join("\\")}`).then((res) => {
        resolve(res.data);
      });
    } else {
      axios.get(`${url}\\${id}`).then((res) => resolve(res.data));
    }
  });

  return promise;
}

function submitRequest(url, params = []) {
  const paramsRequest = {
    url,
    params,
  };

  const promise = new Promise((resolve, reject) => {
    let request;
    const id = paramsRequest.params.id;
    const url = paramsRequest.url;

    if (id !== undefined && id !== null && id !== "" && id !== 0) {
      // Update
      request = axios.put(`${url}/${id}`, paramsRequest.params);
    } else {
      // Insert
      request = axios.post(url, paramsRequest.params);
    }

    request
      .then((result) => {
        AlertService.done("Save complete").then(() => resolve(result));
      })
      .catch((res) => {
        var msg = [];
        if (res.status === 400) {
          if (res.data instanceof Array) {
            msg = res.data;
          } else {
            msg = [res.data];
          }
        } else {
          console.log("Unexpected error was found", res);
          AlertService.error(
            "Unexpected error was found, please contact admin"
          );
        }

        goToTop();

        reject(msg);
      });
  });

  return promise;
}

const setErrors = (errors) => store.dispatch(containerSetErrors(errors));

const setLoading = (isLoading) =>
  store.dispatch(containerSetLoading(isLoading));

const clearErrors = () => store.dispatch(containerClearErrors());

export default {
  submitRequest,
  submitRequestWithFile,
  setErrors,
  setLoading,
  clearErrors,
  getRequest,
};
