export const userStatus = {
  ACTIVE: "active",
  LOCKED: "locked",
  PENDING: "pending",
};

export default [
  { key: userStatus.ACTIVE, label: "Active" },
  { key: userStatus.LOCKED, label: "Locked" },
  { key: userStatus.PENDING, label: "Pending" },
];
