import { userStatus } from "data/userStatus";
import { memo } from "react";

const UserStatus = ({ status }) => {
  let statusText;
  let className = ["badge p-2"];

  switch (status) {
    case userStatus.PENDING:
      statusText = "Pending";
      className.push("badge-warning");
      break;
    case userStatus.ACTIVE:
      statusText = "Active";
      className.push("badge-primary");
      break;
    case userStatus.LOCKED:
      statusText = "Locked";
      className.push("badge-danger");
      break;
  }

  return <span className={className.join(" ")}>{statusText}</span>;
};

export default memo(UserStatus);
