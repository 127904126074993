import React, { Component } from "react";
import { AuthService } from "@standard/services";

class Navbar extends Component {
  logout() {
    AuthService.logout();
  }
  render() {
    return (
      <nav
        className="navbar navbar-static-top white-bg App-mb"
        role="navigation"
      >
        <div className="navbar-header">
          <a
            className="navbar-minimalize minimalize-styl-2 btn btn-primary "
            href="/"
          >
            <i className="fa fa-bars"></i>{" "}
          </a>
        </div>
        <ul className="nav navbar-top-links navbar-right">
          <li>
            <button className="btn btn-link" onClick={this.logout}>
              <i className="fa fa-sign-out"></i> ออกจากระบบ
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;
