import InvoiceEmployeeDataTable from "./InvoiceEmployeeDataTable";
import TitleHeader from "@standard/components/TitleHeader";
import InvoiceEmployeeForm from "./InvoiceEmployeeForm";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const programType = "Transaction";
const programName = "Invoice Employee";
const url = "transaction/invoiceemployee";

export default function Customer() {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <InvoiceEmployeeDataTable url={url} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <InvoiceEmployeeForm url={url} />
        </Route>
      </Switch>
    </>
  );
}
