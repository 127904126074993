import axios from "@standard/axios";

export const updatePriceRateByJob = ({
  jobId,
  detailId,
  bill,
  sts,
  customerId,
  wheel,
}) => {
  const promise = new Promise((resolve, reject) => {
    const params = {
      jobId: jobId,
      detailId: detailId,
      bill: bill,
      sts: sts,
      customerId,
      wheel,
    };

    axios
      .put(
        `master/pricerate/updatepriceratebyjob/${customerId}/${wheel}`,
        params
      )
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const updatePriceRateById = ({ detail }) => {
  const promise = new Promise((resolve, reject) => {
    const params = {
      pay: detail.pay,
      sts: detail.sts,
      bill: detail.bill,
      employeeGroups: detail.employeeGroups,
    };

    axios
      .put(`master/pricerate/detail/${detail._id}`, params)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const removePriceRate = ({ priceRateId }) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .delete(`master/pricerate/${priceRateId}`)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const removePriceRateRatio = (priceRateRatioId) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .delete(`master/pricerate/ratio/${priceRateRatioId}`)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const createPriceRateRatio = ({ customerId, wheel, ratio }) => {
  const promise = new Promise((resolve, reject) => {
    // const params = {
    //   label: "",
    //   ratio: "",
    //   ratioOfPriceRateRatioId: "",
    //   enable: {
    //       bill: true,
    //       sts: true,
    //       pay: true,
    //       employeeGroup: true
    //   }
    // };

    axios
      .post(`master/pricerate/ratio/${customerId}/${wheel}`, ratio)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const updatePriceRateRatio = ({ customerId, wheel, ratio }) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .put(`master/pricerate/ratio/${customerId}/${wheel}/${ratio._id}`, ratio)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const updatePriceRateRow = ({ customerId, wheel, priceRate }) => {
  const promise = new Promise((resolve, reject) => {
    // const params = {
    //   _id: "",
    //   sourcePlaces: ["62fdfb846ff8300c8e7b22be"],
    //   destinationPlaces: ["62fdfb846ff8300c8e7b22bd"],
    //   detail: [
    //     {
    //       priceRateRatioId: "62ff2585e0df32f78dafb39c",
    //       bill: 1200,
    //       sts: 1100,
    //       pay: 1000,
    //       employeeGroups: [],
    //     },
    //   ],
    // };

    axios
      .put(
        `master/pricerate/${customerId}/${wheel}/${priceRate._id || ""}`,
        priceRate
      )
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const getHistories = ({ customerId, wheel }) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(`master/pricerate/history/${customerId}/${wheel}`)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};

export const updateAllPlaces = ({
  customerId,
  wheel,
  sourcePlaces,
  priceRateTypeId,
}) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .put(`master/pricerate/updateallplaces/${customerId}/${wheel}`, {
        sourcePlaces,
        priceRateTypeId,
      })
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};
