import { memo } from "react";
import { DateService } from "@standard/services";
import { Button } from "react-bootstrap";

const DashboardList = ({ url, data, title, editUrl, totalrecord }) => {
  const today = new Date();

  return (
    <div className="ibox ">
      <div className="ibox-title">
        <h5>{title}</h5>
        <div className="ibox-tools">
          <Button
            variant="link"
            className="text-link p-0"
            target="_blank"
            href={url}
          >
            <span className="label label-warning-light float-right">
              {totalrecord} Items
            </span>
          </Button>
        </div>
      </div>
      <div className="ibox-content">
        <div>
          {data.map((entity) => {
            return (
              <div className="feed-activity-list" key={entity.id}>
                <div className="feed-element">
                  <div className="media-body ">
                    <small className="float-right">
                      {DateService.dateDiff(new Date(entity.date), today)}d ago
                    </small>
                    <a
                      className="text-link"
                      href={`${editUrl}${entity.id}`}
                      target="_blank"
                    >
                      <strong>{entity.title}</strong>
                    </a>
                    <br />
                    <small className="text-muted">
                      {entity.description} -{" "}
                      {DateService.convertDateToString(entity.date)}
                    </small>
                  </div>
                </div>
              </div>
            );
          })}
          <Button
            variant="link"
            className="text-link btn-block m-t"
            target="_blank"
            href={url}
          >
            <i className="fa fa-location-arrow"></i> Show More
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(DashboardList);
