import { DateService, UtilsService } from "@standard/services";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";

const ReceiptDescription = ({ invoices, onChange, disabled }) => {
  const [selectedInvoiceIdList, setSelectedInvoiceIdList] = useState([]);

  useEffect(() => {
    setSelectedInvoiceIdList(
      invoices
        .filter((f) => f.selected)
        .map((invoice) => {
          return {
            invoiceId: invoice.id,
            receiptSourceId: invoice.receiptSourceId,
          };
        })
    );
  }, [invoices]);

  useEffect(() => {
    onChange(selectedInvoiceIdList);
  }, [selectedInvoiceIdList]);

  const onChangeCheckbox = (selectedInvoiceId, selectedReceiptSourceId) => {
    const newSelectedInvoiceIdList = [...selectedInvoiceIdList];

    const index = selectedInvoiceIdList.findIndex(
      (f) =>
        f.invoiceId === selectedInvoiceId &&
        f.receiptSourceId === selectedReceiptSourceId
    );

    if (index === -1) {
      newSelectedInvoiceIdList.push({
        invoiceId: selectedInvoiceId,
        receiptSourceId: selectedReceiptSourceId,
      });
      setSelectedInvoiceIdList(newSelectedInvoiceIdList);
    } else {
      newSelectedInvoiceIdList.splice(index, 1);
      setSelectedInvoiceIdList(newSelectedInvoiceIdList);
    }
  };

  const onChangeCheckboxHead = () => {
    if (selectedInvoiceIdList.length === invoices.length) {
      setSelectedInvoiceIdList([]);
    } else {
      setSelectedInvoiceIdList(
        invoices.map((invoice) => {
          return {
            invoiceId: invoice.id,
            receiptSourceId: invoice.receiptSourceId,
          };
        })
      );
    }
  };

  const entities = invoices.map((invoice, invoiceKey) => {
    const checked =
      selectedInvoiceIdList.find(
        (f) =>
          f.invoiceId === invoice.id &&
          f.receiptSourceId === invoice.receiptSourceId
      ) !== undefined;

    return (
      <tr key={invoiceKey}>
        <td className="text-center">
          <Form.Check
            inline
            type="checkbox"
            id={`jobSelectedId_${invoiceKey}`}
            checked={checked}
            onChange={(e) =>
              onChangeCheckbox(invoice.id, invoice.receiptSourceId)
            }
            disabled={disabled}
          />
        </td>
        <td className="text-center">
          <Button
            variant="link"
            className="text-link p-1"
            target="_blank"
            href={`/transaction/invoice/edit/${invoice.id}`}
          >
            {invoice.documentNo}
          </Button>
        </td>
        <td className="text-left">{invoice.source}</td>
        <td className="text-center">
          {DateService.convertDateToString(invoice.transactionDate)}
        </td>
        <td>{invoice.typeOfTransportName}</td>
        <td className="text-right">
          {UtilsService.convertToMoney(invoice.total)} ฿
        </td>
      </tr>
    );
  });

  const checkboxHeaderChecked =
    invoices.length > 0 && selectedInvoiceIdList.length === invoices.length;

  return (
    <>
      <strong>Description:</strong>
      <table className="table">
        <thead>
          <tr>
            <th>
              <Form.Check
                inline
                type="checkbox"
                id={`jobSelectedId`}
                checked={checkboxHeaderChecked}
                onChange={onChangeCheckboxHead}
                disabled={disabled}
              />
            </th>
            <th>Invoice No.</th>
            <th>Source</th>
            <th>Transaction Date</th>
            <th>Type of Transport</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>{entities}</tbody>
      </table>
    </>
  );
};

export default ReceiptDescription;
