const Loader = ({ isLoading }) => {
  if (isLoading === false) return;

  return (
    <div className="sk-spinner sk-spinner-double-bounce">
      <div className="sk-double-bounce1"></div>
      <div className="sk-double-bounce2"></div>
    </div>
  );
};

export default Loader;
