import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory } from "react-router-dom";
import { useState, memo } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { FormService } from "@standard/services";
import { useEffect } from "react";
import { expenseTypeData, employeeTypeData } from "data";

export default function InvoiceEmployeeExpenseForm({ url }) {
  const history = useHistory();
  const { id, action } = useParams();
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(
    action === "edit" && id !== undefined
  );

  const defaultData = {
    id: id,
    isOutsource: 0,
    name: "",
    percent: "",
    expenseType: "c",
    active: true,
  };

  const [data, setData] = useState(defaultData);

  useEffect(() => {
    if (id !== undefined) {
      FormService.getRequest(url, id).then((dataResult) => {
        setData(dataResult);

        setIsLoading(false);
      });
    }
  }, []);

  const onSubmit = (newData) => {
    setIsLoading(true);

    const params = { ...newData, id: id };

    FormService.submitRequest(url, params)
      .then((result) => {
        setIsLoading(false);
        history.push(`/${url}`);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const fields = [
    {
      label: "Type",
      name: "isOutsource",
      type: "radio",
      inline: true,
      required: true,
      datasource: employeeTypeData,
    },
    {
      label: "Expense Name",
      name: "name",
      type: "text",
      required: true,
    },
    {
      label: "Percent",
      name: "percent",
      type: "number",
      required: false,
      uom: "%",
    },
    {
      label: "Expense Type",
      name: "expenseType",
      type: "ddl",
      datasource: expenseTypeData,
      required: true,
    },
    { label: "Active", name: "active", type: "switch" },
  ];

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        defaultValues={data}
        onSubmit={onSubmit}
        fields={fields}
        url={url}
        onErrors={(errors) => setErrors(errors)}
      ></DataForm>
    </BoxContainer>
  );
}
