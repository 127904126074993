import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AlertService, AuthService } from "@standard/services";
import { BoxContainerUnauthen } from "@standard/components/BoxContainer";
import { useState } from "react";

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const { token } = useParams();
  const history = useHistory();

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setErrors(["Confirm Password must be equal to New Password"]);
      return;
    }
    setIsLoading(true);
    var params = {
      token,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };

    AuthService.changePassword(params)
      .then(() => {
        setIsLoading(false);
        AlertService.done("Your password was changed").then(() =>
          history.push("/login")
        );
      })
      .catch((errors) => {
        setIsLoading(false);
        setErrors(errors);
      });
  };

  return (
    <BoxContainerUnauthen
      isLoading={isLoading}
      errors={errors}
      title="Change Password"
      description="Enter your new password and confirm password"
    >
      <div className="col-12">
        <form className="m-t" id="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group col-6 mx-auto">
            <label>New Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              required
              {...register("newPassword")}
              name="newPassword"
            />
          </div>
          <div className="form-group col-6 mx-auto">
            <label>Confirm Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Confirm Password"
              required
              {...register("confirmPassword")}
              name="confirmPassword"
            />
          </div>
          <div className="form-group col-6 mx-auto">
            <button
              type="submit"
              className="btn btn-primary block full-width m-b "
            >
              Change Password
            </button>
          </div>
          <div className="form-group col-6 mx-auto text-right">
            <Link to="/login">Go to login</Link>
          </div>
        </form>
      </div>
    </BoxContainerUnauthen>
  );
};

export default ChangePassword;
