import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory } from "react-router-dom";
import { useState, memo } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import {
  DateService,
  AuthService,
  FormService,
  AlertService,
  UtilsService,
} from "@standard/services";
import { useEffect } from "react";
import TableForm from "@standard/components/TableForm";
import { Badge, Button } from "react-bootstrap";
import { wheelData, dnReturnTypeData, dnReturnPrintStatusData } from "data";
import { DatasourceAPI } from "api";
import { dnReturnType, dnReturnPrintStatus, Wheel } from "data/constants";

export default function DNReturn({ url }) {
  const history = useHistory();
  const { id, action } = useParams();
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userFullname = AuthService.getFullName();

  const defaultData = {
    id: id,
    createdAt: new Date(),
    createdByName: userFullname,
    customerId: null,
    returnDate: Date.now(),
    type: dnReturnType.IMPORT,
    jobSTS: "",
    jobName: "",
    wheel: Wheel.WD4,
    employeeId: "",
    carNoId: "",
    status: dnReturnPrintStatus.UNPRINTED,
    detail: [],
    jobId: null,
    isEditable: true,
  };

  const [data, setData] = useState(defaultData);
  const [descriptionValue, setDescriptionValue] = useState([]);

  const disabled = !data.isEditable || action === "view";

  const [datasource, setDatasource] = useState({
    customer: [],
    employee: [],
    employeecarno: [],
    jobname: [],
    department: [],
  });

  useEffect(() => {
    if (id !== undefined) {
      FormService.getRequest(url, id).then((dataResult) => {
        const tables = [
          { name: "customer", value: dataResult.customerId },
          {
            name: "employee",
            value: dataResult.employeeId,
            ref1: dataResult.wheel,
          },
          {
            name: "employeecarno",
            value: dataResult.carNoId,
            ref1: dataResult.wheel,
          },
          { name: "jobname" },
          { name: "department" },
        ];

        DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
          if (dataResult.carNoId === null) {
            dataResult.carNoId = dataResult.carNoText;
          }
          if (dataResult.employeeId === null) {
            dataResult.employeeId = dataResult.employeeText;
          }

          setDatasource(datasourceResult);
          setData(dataResult);

          setIsLoading(false);
        });
      });
    } else {
      const tables = [
        { name: "customer" },
        { name: "employee", ref1: data.wheel },
        { name: "employeecarno", ref1: data.wheel },
        { name: "jobname" },
        { name: "department" },
      ];
      DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
        const customerDatasource = datasourceResult.customer;
        const customer = customerDatasource.find(
          (f) => f.label.toLowerCase().indexOf("dhl") !== -1
        );

        if (customer) {
          setData({ ...defaultData, customerId: customer.key });
        }

        setDatasource(datasourceResult);

        setIsLoading(false);
      });
    }
  }, []);

  const save = (newData) => {
    setIsLoading(true);
    const savedData = { ...newData };
    savedData.detail = descriptionValue.map((item) => {
      const { hawbNo, jobtb, department, deliveryDate } = item.values;
      const detail = {
        hawbNo: hawbNo,
        jobtb: jobtb,
        department: department,
        deliveryDate: deliveryDate,
      };

      if (item.id) {
        detail._id = item.id;
      }

      return detail;
    });

    savedData.id = id;

    const params = { ...savedData };

    FormService.submitRequest(url, params)
      .then((result) => {
        setIsLoading(false);
        history.push(`/${url}`);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const onSubmit = (newData) => {
    save(newData);
  };

  const fields = [
    {
      label: "Type",
      name: "type",
      type: "radio",
      inline: true,
      datasource: dnReturnTypeData,
      disabled: disabled,
    },
    {
      label: "Customer",
      name: "customerId",
      type: "ddl",
      required: true,
      datasource: datasource.customer,
      disabled: disabled,
    },
    {
      label: "Return Date",
      name: "returnDate",
      type: "date",
      required: true,
      disabled: disabled,
    },
    {
      label: "Job STS / งานเลขที่",
      name: "jobSTS",
      type: "text",
      required: true,
      col: 6,
      disabled: disabled,
    },
    {
      label: "Job Name / Consignee",
      name: "jobName",
      type: "autocomplete",
      datasource: datasource.jobname,
      required: true,
      col: 6,
      disabled: disabled,
    },
    {
      label: "Wheel",
      name: "wheel",
      type: "radio",
      required: true,
      datasource: wheelData,
      inline: true,
      disabled: disabled,
    },
    {
      label: "Car No",
      name: "carNoId",
      type: "ddl",
      datasource: datasource.employeecarno,
      required: true,
      createable: true,
      col: 6,
      disabled: disabled,
    },
    {
      label: "Employee",
      name: "employeeId",
      type: "ddl",
      required: true,
      datasource: datasource.employee,
      createable: true,
      col: 6,
      disabled: disabled,
    },
  ];

  const tableFields = [
    {
      label: "HAWB No",
      name: "hawbNo",
      type: "text",
      required: false,
      defaultValue: "",
    },
    {
      label: "Job / TB",
      name: "jobtb",
      type: "text",
      required: false,
      defaultValue: "",
    },
    {
      label: "แผนก",
      name: "department",
      type: "autocomplete",
      datasource: datasource.department,
      required: false,
      defaultValue: "",
    },
    {
      label: "Delivery Date",
      name: "deliveryDate",
      type: "date",
      required: false,
      align: "center",
      defaultValue: new Date(),
    },
  ];

  const buttons = [];

  if (!disabled) {
    buttons.push(
      <Button key={1} type={"submit"} variant="primary" className="mr-2">
        Save and Generate Job
      </Button>
    );
  }

  if (id !== undefined) {
    buttons.unshift(
      <Button
        key={2}
        variant="link"
        target="_blank"
        href={`/transaction/job/edit/${data.jobId}`}
      >
        <i className="fa fa-chevron-right pr-1" aria-hidden="true"></i>Go to Job
      </Button>
    );
  }

  const dnReturnPrintStatusLabel = dnReturnPrintStatusData.find(
    (f) => f.key === data.status
  ).label;
  const leftPanel = (
    <>
      <strong style={{ fontSize: "14px", color: "#676a6c", fontWeight: "600" }}>
        By {data.createdByName}
      </strong>
      <br />
      <small className="text-muted">
        {DateService.convertDateTimeToString(data.createdAt)}
      </small>
      <br />
      <Badge
        bg={data.status === "u" ? "danger" : "primary"}
        className="p-2 mt-2"
      >
        {dnReturnPrintStatusLabel}
      </Badge>
    </>
  );

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        defaultValues={data}
        buttons={buttons}
        fields={fields}
        onSubmit={onSubmit}
        enableSave={false}
        url={url}
        onErrors={(errors) => setErrors(errors)}
        leftPanel={leftPanel}
        onChange={({ name, value }, values) => {
          if (name === "carNoId") {
            const employee = datasource.employee.find((f) => f.key === value);
            setData({
              ...data,
              ...values,
              employeeId: employee ? employee.key : null,
            });
          } else if (name === "wheel") {
            setIsLoading(true);
            const tables = [
              { name: "employeecarno", ref1: value },
              { name: "employee", ref1: value },
            ];
            DatasourceAPI.getActiveDatasource(tables).then(
              (datasourceResult) => {
                setDatasource({ ...datasource, ...datasourceResult });
                setIsLoading(false);
              }
            );
          }
        }}
      >
        <TableForm
          fields={tableFields}
          name="description"
          label="Description"
          values={data.detail}
          disabled={disabled}
          onChange={(data) => {
            setDescriptionValue(data);
          }}
        />
      </DataForm>
    </BoxContainer>
  );
}
