import TitleHeader from "@standard/components/TitleHeader";
import EmployeeForm from "./EmployeeForm";
import EmployeeTable from "./EmployeeTable";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const programType = "Master";
const programName = "Employee Setup";
const url = "master/employee";

export default function Employee() {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <EmployeeTable url={url} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <EmployeeForm url={url} />
        </Route>
      </Switch>
    </>
  );
}
