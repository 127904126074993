import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory } from "react-router-dom";
import { useState, memo } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import {
  DateService,
  AuthService,
  FormService,
  AlertService,
  UtilsService,
} from "@standard/services";
import { useEffect } from "react";
import { Button, Badge } from "react-bootstrap";
import { DatasourceAPI, InvoiceAPI } from "api";
import InvoiceDescription from "./InvoiceDescription";
import { invoiceStatus, userRole } from "constants";
import InvoiceStatus from "./InvoiceStatus";
import axios from "@standard/axios";
import Modal from "@standard/components/Modal";
import Dropdown from "react-bootstrap/Dropdown";

export default function InvoiceForm({ url }) {
  const history = useHistory();
  const { id: paramsId, action } = useParams();
  const [id, setId] = useState(paramsId);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fullname = AuthService.getFullName();

  const defaultData = {
    id: id,
    createdAt: new Date(),
    createdByName: fullname,
    customerId: null,
    documentNo: "-",
    publishDate: "-",
    transactionDate: [null, null],
    typeOfTransportId: null,
    receiptInfo: null,
    isLocked: false,
    revisions: [],
    invoiceTemplates: [],
  };

  const [data, setData] = useState(defaultData);
  const [jobs, setJobs] = useState([]);
  const [jobFilters, setJobFilters] = useState({
    customerId: defaultData.customerId,
    transactionDate: defaultData.transactionDate,
    typeOfTransportId: defaultData.typeOfTransportId,
  });

  const [datasource, setDatasource] = useState({
    customer: [],
    typeoftransport: [],
  });

  const [selectedJobIdList, setSelectedJobIdList] = useState([]);

  // get job info
  useEffect(() => {
    const { transactionDate, customerId, typeOfTransportId } = jobFilters;

    const [transactionDateFrom, transactionDateTo] = transactionDate;
    if (
      customerId !== null &&
      transactionDateFrom !== null &&
      transactionDateTo !== null
    ) {
      setIsLoading(true);

      InvoiceAPI.getJobs({
        customerId,
        transactionDateFrom:
          DateService.convertDateToServer(transactionDateFrom),
        transactionDateTo: DateService.convertDateToServer(transactionDateTo),
        typeOfTransportId,
        invoiceId: id,
      }).then((jobsResult) => {
        const jobsEntities = jobsResult.map((job) => {
          job.selected = selectedJobIdList.indexOf(job.id) >= 0;
          return job;
        });

        setJobs(jobsEntities);
        setIsLoading(false);
      });
    }
  }, [jobFilters]);

  const fetchData = () => {
    console.log("Fetch data", id);
    if (id !== undefined) {
      FormService.getRequest(url, id).then((dataResult) => {
        const tables = [
          { name: "customer", value: dataResult.customerId },
          { name: "typeoftransport", value: dataResult.typeOfTransportId },
        ];

        DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
          setDatasource(datasourceResult);

          dataResult.publishDate = DateService.convertDateToString(
            dataResult.publishDate
          );

          const transactionDate = [
            new Date(dataResult.transactionDateFrom),
            new Date(dataResult.transactionDateTo),
          ];

          setData({ ...dataResult, transactionDate });
          setSelectedJobIdList(dataResult.jobs);

          setJobFilters({
            customerId: dataResult.customerId,
            transactionDate: transactionDate,
            typeOfTransportId: dataResult.typeOfTransportId,
          });

          setIsLoading(false);
        });
      });
    } else {
      const tables = [{ name: "customer" }, { name: "typeoftransport" }];

      DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
        setDatasource(datasourceResult);

        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const save = (newData) => {
    setIsLoading(true);
    const [transactionDateFrom, transactionDateTo] = jobFilters.transactionDate;
    const savedData = { ...newData };

    savedData.transactionDateFrom =
      DateService.convertDateToServer(transactionDateFrom);
    savedData.transactionDateTo =
      DateService.convertDateToServer(transactionDateTo);
    savedData.jobs = selectedJobIdList;
    savedData.id = id;

    const params = { ...savedData };

    FormService.submitRequest(url, params)
      .then((result) => {
        if (id) {
          fetchData();
        } else {
          history.push(`/${url}/edit/${result.data._id}`);
          setId(result.data._id);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const onSubmit = (newData) => {
    AlertService.confirm("Do you want to save ?", "Confirmation").then(
      (res) => {
        if (res === true) {
          save(newData);
        }
      }
    );
  };

  const disabled = data.isLocked === true;

  const fields = [
    {
      label: "Invoice No.",
      name: "documentNo",
      type: "label",
      required: false,
      col: 6,
    },
    {
      label: "Publish Date",
      name: "publishDate",
      type: "label",
      required: false,
      col: 6,
    },
    {
      label: "Customer Name",
      name: "customerId",
      type: "ddl",
      required: true,
      datasource: datasource.customer,
      disabled,
    },
    {
      label: "Transaction Date",
      name: "transactionDate",
      type: "date",
      required: true,
      isRange: true,
      disabled,
    },
    {
      label: "Type of Transport",
      name: "typeOfTransportId",
      type: "ddl",
      required: false,
      datasource: datasource.typeoftransport,
      disabled,
    },
  ];

  const leftPanel = (
    <>
      {data.isLocked === true ? (
        <i className="fa fa-lock" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-unlock" aria-hidden="true"></i>
      )}
      {"  "}
      <strong style={{ fontSize: "14px", color: "#676a6c", fontWeight: "600" }}>
        By {data.createdByName}
      </strong>
      <br />
      <small className="text-muted">
        {DateService.convertDateTimeToString(data.createdAt)}
      </small>
      <br />
      {data.receiptInfo && data.receiptInfo.length > 0 && (
        <>
          <strong>Receipt No: </strong>
          {data.receiptInfo.map((receipt) => (
            <Button
              variant="link"
              className="text-link p-1"
              target="_blank"
              href={`/transaction/receipt/edit/${receipt.receiptId}`}
            >
              {receipt.receiptNo}
            </Button>
          ))}
        </>
      )}
      {data.revisions.length > 0 && (
        <>
          <br />
          <strong>Revision:</strong>{" "}
          <Modal label={data.revisions.length} title="Revisions">
            <table className="table table-borderd">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>User Name</th>
                  <th>Log</th>
                </tr>
              </thead>
              <tbody>
                {data.revisions.map((revision, revisionKey) => {
                  return (
                    <tr key={revisionKey}>
                      <td className="text-center">
                        {DateService.convertDateTimeToString(
                          revision.createdAt
                        )}
                      </td>
                      <td>{revision.userId?.fullName}</td>
                      <td>
                        <ul>
                          {revision.detail.map((d, dIndex) => {
                            let text;
                            const link = `/transaction/job/edit/${d._id}`;
                            if (d.action === "r") {
                              text = "ลบเอกสารเลขที่ ";
                            } else {
                              text = "เพิ่มเอกสารเลขที่ ";
                            }
                            return (
                              <li key={dIndex}>
                                {text}
                                <Button
                                  variant="link"
                                  className="text-link p-0 m-0"
                                  target="_blank"
                                  href={link}
                                >
                                  {d.jobSTS}
                                </Button>
                              </li>
                            );
                          })}
                        </ul>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Modal>
        </>
      )}
    </>
  );

  const onRequestUnlockedClick = () => {
    AlertService.confirm(
      `Do you want to request unlock invoice no ${data.documentNo} ?`
    ).then((res) => {
      if (res === true) {
        setIsLoading(true);
        const { _id: requestByUserId } = AuthService.getCurrentUser();
        InvoiceAPI.requestUnlocked({ requestByUserId, invoiceId: id }).then(
          (res) =>
            AlertService.done(
              "Request was sent to authorizer, please wait for unlock this invoice"
            ).then(() => setIsLoading(false))
        );
      }
    });
  };

  const onUnlockedClick = () => {
    AlertService.confirm(
      `Are you sure to unlock invoice no ${data.documentNo} ?`
    ).then((res) => {
      if (res === true) {
        setIsLoading(true);
        InvoiceAPI.unlocked({ invoiceId: id }).then((res) =>
          AlertService.done(`Invoice no ${data.documentNo} was unlocked`).then(
            () => fetchData()
          )
        );
      }
    });
  };

  const onLockedClick = () => {
    AlertService.confirm(
      `Are you sure to lock invoice no ${data.documentNo} ?`
    ).then((res) => {
      if (res === true) {
        setIsLoading(true);
        InvoiceAPI.lock({ invoiceId: id }).then((res) =>
          AlertService.done(`Invoice no ${data.documentNo} was locked`).then(
            () => setIsLoading(false)
          )
        );
      }
    });
  };

  const onGenerateEachJobClick = () => {
    AlertService.confirm(
      "Do you want to generate each job ?",
      "Confirmation"
    ).then((res) => {
      if (res === true) {
        setIsLoading(true);
        const [transactionDateFrom, transactionDateTo] =
          jobFilters.transactionDate;
        const savedData = { ...data };

        savedData.transactionDateFrom =
          DateService.convertDateToServer(transactionDateFrom);
        savedData.transactionDateTo =
          DateService.convertDateToServer(transactionDateTo);

        savedData.jobs = selectedJobIdList;
        savedData.id = id;

        const params = { ...savedData };

        FormService.submitRequest(`${url}/generate`, params)
          .then((result) => {
            setIsLoading(false);
            history.push(`/${url}`);
          })
          .catch((err) => {
            setIsLoading(false);
            setErrors(err);
          });
      }
    });
  };

  const buttons = [];

  if (id !== undefined) {
    const baseUrl = axios.defaults.baseURL;

    buttons.push(
      <Dropdown key={2} className="d-inline mr-2">
        <Dropdown.Toggle variant="info" id="dropdown-report">
          Print Report
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {data.invoiceTemplates.map((invoiceTemplate) => (
            <Dropdown.Item
              key={invoiceTemplate.code}
              disabled={data.isLocked === false}
              href={
                data.isLocked === true &&
                `${baseUrl}transaction/invoice/invoice/print/${id}/${invoiceTemplate.code}`
              }
            >
              {invoiceTemplate.name}
            </Dropdown.Item>
          ))}
          <Dropdown.Divider />
          <Dropdown.Item
            href={`${baseUrl}transaction/invoice/employeedetail/print/${id}`}
          >
            Employee Detail Report
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );

    if (data.isLocked === true) {
      if (AuthService.isRole(userRole.SUPPER_ADMIN)) {
        buttons.push(
          <Button
            key={4}
            variant="danger"
            className="mr-1"
            onClick={onUnlockedClick}
          >
            Unlocked
          </Button>
        );
      } else {
        buttons.push(
          <Button
            key={3}
            variant="danger"
            className="mr-1"
            onClick={onRequestUnlockedClick}
          >
            Request Unlocked
          </Button>
        );
      }
    }
  } else {
    buttons.push(
      <Button
        key={5}
        variant="danger"
        className="mr-1"
        onClick={onGenerateEachJobClick}
      >
        Generate Each Job
      </Button>
    );
  }
  if (data.isLocked === false) {
    if (AuthService.isRole(userRole.SUPPER_ADMIN) && id !== undefined) {
      buttons.push(
        <Button
          key={4}
          variant="danger"
          className="mr-1"
          onClick={onLockedClick}
        >
          Locked
        </Button>
      );
    }
    buttons.push(
      <Button key={1} variant="secondary" type="submit" className="mr-1">
        Save & Publish
      </Button>
    );
  }

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        defaultValues={data}
        loading={isLoading}
        fields={fields}
        onSubmit={onSubmit}
        buttons={buttons}
        url={url}
        enableSave={false}
        onErrors={(errors) => setErrors(errors)}
        leftPanel={leftPanel}
        onChange={({ name, value }, values) => {
          if (
            ["customerId", "transactionDate", "typeOfTransportId"].indexOf(
              name
            ) !== -1
          ) {
            setJobFilters({
              ...jobFilters,
              [name]: value,
            });
          }

          setData({ ...data, ...values });
        }}
      >
        <InvoiceDescription
          disabled={disabled}
          onChange={(selectedJobId) => setSelectedJobIdList(selectedJobId)}
          jobs={jobs}
        ></InvoiceDescription>
      </DataForm>
    </BoxContainer>
  );
}
