import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { Auth } from "@standard/services";
// import { fetchCount } from "./counterAPI";
import { STORAGE_NAME } from "@standard/constants";
const user = JSON.parse(localStorage.getItem(STORAGE_NAME.CURRENT_USER));
const initialState = {
  user: user, //Auth.getCurrentUser(),
  isAuth: user != null, //Auth.isAuth(),
};

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched. Thunks are
// // typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   "counter/fetchCount",
//   async (amount) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const authSlice = createSlice({
  name: "login",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    login: (state, action) => {
      state.isAuth = true;
      state.user = action.payload;
    },
    logout: (state, { payload }) => {
      state.isAuth = false;
      state.user = initialState.user;
    },
    // isAuth: (state, { payload }) => {
    //   state.value /= payload;
    // },
  },
  // // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = "loading";
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = "idle";
  //       state.value += action.payload;
  //     });
  // },
});

export const { login, logout } = authSlice.actions;

// // The function below is called a selector and allows us to select a value from
// // the state. Selectors can also be defined inline where they're used instead of
// // in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state) => state.counter.value;
// export const getState = (state) => state.counter;

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export const getCurrentUser = (state) => state.auth;

export default authSlice.reducer;
