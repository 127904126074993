import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory } from "react-router-dom";
import { useState, memo } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { AuthService, FormService } from "@standard/services";
import { useEffect } from "react";

export default function ChangePasswordWithAuth({}) {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const url = "auth/changepassword";
  const { _id: id } = AuthService.getCurrentUser();

  const defaultData = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [data, setData] = useState(defaultData);

  const onSubmit = (newData) => {
    setIsLoading(true);

    const params = { ...newData, id };

    FormService.submitRequest(url, params)
      .then((result) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const fields = [
    {
      label: "Old Password",
      name: "oldPassword",
      type: "password",
      required: true,
      // value: data.oldPassword,
    },
    {
      label: "New Password",
      name: "newPassword",
      type: "password",
      required: true,
      // value: data.newPassword,
    },
    {
      label: "Confirm password",
      name: "confirmPassword",
      type: "password",
      required: true,
      // value: data.confirmPassword,
    },
  ];

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        defaultValues={data}
        fields={fields}
        onSubmit={onSubmit}
        url={url}
        onErrors={(errors) => setErrors(errors)}
      ></DataForm>
    </BoxContainer>
  );
}
