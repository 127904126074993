import DataTable from "@standard/components/DataTable";
import TitleHeader from "@standard/components/TitleHeader";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import axios from "@standard/axios";
import { useState, useEffect } from "react";
import { DatasourceAPI } from "api";
import { getURLSearchCriteria } from "@standard/store/reducers/dataTableSlice";
import { useDispatch } from "react-redux";

const programType = "Report";
const programName = "Job";
const url = "report/job";

export default function Customer() {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);

  const [datasource, setDatasource] = useState({
    customer: [],
    month: [
      { label: "January", key: 1 },
      { label: "February", key: 2 },
      { label: "March", key: 3 },
      { label: "April", key: 4 },
      { label: "May", key: 5 },
      { label: "June", key: 6 },
      { label: "July", key: 7 },
      { label: "August", key: 8 },
      { label: "September", key: 9 },
      { label: "October", key: 10 },
      { label: "November", key: 11 },
      { label: "Decembe", key: 12 },
    ],
    year: [],
  });

  useEffect(() => {
    // const tables = ["customer"];

    // DatasourceAPI.getActiveDatasource(tables).then((ds) => {
    const currentYear = new Date().getFullYear() - 5;
    const yearDatasource = new Array(10).fill(null).map((item, itemIndex) => {
      return {
        key: currentYear + itemIndex,
        label: (currentYear + itemIndex).toString(),
      };
    });

    setDatasource({ ...datasource, year: yearDatasource });
    //   setIsLoading(false);
    // });
  }, []);

  const columns = [
    {
      label: "Customer",
      name: "customerName",
      columnName: "customerId",
      sortable: true,
      // search: {
      //   type: "ddl",
      //   datasource: datasource.customer,
      //   multiple: true,
      // },
    },
    {
      label: "Total Job",
      name: "totalJob",
      align: "right",
      sortable: true,
    },
  ];

  const buttons = [
    {
      variant: "danger",
      label: '<i className="fa fa-print" aria-hidden="true"></i> Export Excel',
      onClick: (selectedId) => {
        const printUrl = `${axios.defaults.baseURL}${url}/print`;
        const printUrlWithParam = dispatch(getURLSearchCriteria(printUrl));

        console.log("printUrlWithParam", printUrlWithParam);

        window.open(printUrlWithParam, "_blank");
      },
    },
  ];

  const config = {
    customFilters: [
      {
        label: "Month From",
        name: "monthfrom",
        type: "ddl",
        datasource: datasource.month,
        col: 6,
        defaultValue: null,
      },
      {
        label: "Year From",
        name: "yearfrom",
        type: "ddl",
        datasource: datasource.year,
        col: 6,
        defaultValue: null,
      },
      {
        label: "Month To",
        name: "monthto",
        type: "ddl",
        datasource: datasource.month,
        col: 6,
        defaultValue: null,
      },
      {
        label: "Year To",
        name: "yearto",
        type: "ddl",
        datasource: datasource.year,
        col: 6,
        defaultValue: null,
      },
    ],
    enableInsert: false,
    enableAction: false,
    enableActive: false,
    enableEdit: false,
    enableTrash: false,
    enableCheckbox: false,
    buttons: buttons,
    onBeforeBind: (data) => {
      return data.map((item) => {
        const { alias, name } = item.values;

        item.values.name = (
          <>
            {alias}
            <br />
            <small className="">{name}</small>
          </>
        );

        return item;
      });
    },
  };

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <DataTable columns={columns} url={url} {...config} />
        </Route>
      </Switch>
    </>
  );
}
