import axios from "@standard/axios";
import DataTable from "@standard/components/DataTable";
import { Badge } from "react-bootstrap";

const columns = [
  {
    label: "Name",
    name: "name",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Telephone",
    name: "telephone",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Car No",
    name: "carNoText",
    sortable: true,
    search: { type: "text" },
  },
];

export default function Employee({ url }) {
  const buttons = [
    {
      variant: "danger",
      label: '<i className="fa fa-print" aria-hidden="true"></i> Export Excel',
      onClick: (selectedId) => {
        const listId = selectedId.join("|");
        const printUrl = `${axios.defaults.baseURL}${url}/print/${listId}`;
        window.open(printUrl, "_blank");
      },
    },
  ];

  const config = {
    customFilters: [
      {
        label: "Out of warranty",
        name: "isOutOfWarranty",
        type: "switch",
        inline: true,
      },
    ],
    enableActive: true,
    enableEdit: true,
    enableTrash: true,
    enableCheckbox: true,
    buttons: buttons,
    onBeforeBind: (data) => {
      return data.map((item) => {
        const { isOutOfWarranty } = item.values;
        if (isOutOfWarranty) {
          item.values.name = (
            <>
              {item.values.name} <br />
              <Badge bg="danger">Out of warranty</Badge>
            </>
          );
        }

        return item;
      });
    },
  };

  return <DataTable columns={columns} url={url} {...config} />;
}
