import axios from "axios";
import cookie from "@standard/services/cookie";
import { STORAGE_NAME } from "@standard/constants";
import { AuthService } from "./services";
// const dispath = useDispatch();
// import { history } from "./services/Authentication/_helpers";

// let apiURL;
// if (process.env.NODE_ENV === "production") {
//   // production
//   apiURL = process.env.REACT_APP_API_URL_PROD;
// } else {
//   // developent
//   apiURL = process.env.REACT_APP_API_URL;
// }

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  params: {},
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  },
};

const instance = axios.create(config);

const user = JSON.parse(localStorage.getItem(STORAGE_NAME.CURRENT_USER));

if (user) {
  const token = cookie.getToken();
  instance.defaults.headers.common["Authorization"] = "Bearer " + token;

  instance.defaults.headers.common["userId"] = user._id;
}

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // console.log("axios before");
    // store.dispath(setLoading(true));
    // Do something before request is sent
    // console.log("axios before loading", config);
    return config;
  },
  function (error) {
    // console.log("axios error before loading", config);
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // store.dispath(setLoading(false));
    // console.log("axios after loading", response);
    return response;
  },
  function (error) {
    // console.log("axios error");
    // Do something with response error
    if (error.response ? error.response.status === 401 : false) {
      console.log("unauthorized, logging out ...");
      AuthService.doLogout();
    } else if (error.response ? error.response.status === 404 : false) {
      console.log("Response page not found");
      // history.push("/404");
    }

    return Promise.reject(error.response);
  }
);

export default instance;
