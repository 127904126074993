import { clearErrors } from "@standard/store/reducers/containerSlice";
import { useDispatch } from "react-redux";

function ErrorContainer({ errors = [], onClearError, col = 6 }) {
  const dispath = useDispatch();
  if (errors === null || errors.length === 0) return "";

  return (
    <div className="row mb-2">
      <div className={`col-${col} mx-auto`}>
        <div
          className="alert alert-danger alert-dismissible fade show mb-0"
          role="alert"
        >
          <h3 className="alert-heading">มีข้อผิดพลาด !!!</h3>
          <ul className="list-unstyled">
            {errors.map((error, errorKey) => (
              <li key={errorKey}>
                <i className="fa fa-times"></i> {error}
              </li>
            ))}
          </ul>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true" onClick={onClearError}>
              &times;
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorContainer;
