import { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import DataTable from "@standard/components/DataTable";
import { Badge, Button } from "react-bootstrap";
import { UtilsService, DateService } from "@standard/services";
import axios from "@standard/axios";
import { DatasourceAPI } from "api";
import { getURLSearchCriteria } from "@standard/store/reducers/dataTableSlice";
import { jobStatus } from "data/jobStatus";

const JobDataTable = ({ url }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [datasource, setDatasource] = useState({
    customer: [],
    typeoftransport: [],
  });

  const columns = [
    {
      label: "Job STS",
      name: "jobSTS",
      sortable: true,
      align: "center",
      search: { type: "text" },
    },
    {
      label: "Customer",
      name: "customerName",
      columnName: "customerId",
      sortable: true,
      search: {
        type: "ddl",
        datasource: datasource.customer,
        multiple: true,
        // defaultValue: null,
      },
    },
    {
      label: "Date",
      name: "transactionDate",
      align: "center",
      sortable: true,
      search: { type: "date", isRange: true },
    },
    { label: "Place", name: "place", sortable: true, search: { type: "text" } },
    {
      label: "Employee",
      name: "employeeName",
      sortable: true,
      search: { type: "text" },
    },
    {
      label: "Price",
      name: "price",
      sortable: true,
      // search: { type: "number", isRange: true },
    },
    {
      label: "Invoice No",
      name: "invoiceNo",
      sortable: true,
      align: "center",
      search: { type: "text" },
    },
    {
      label: "Receipt No",
      name: "receiptNo",
      sortable: true,
      align: "center",
      search: { type: "text" },
    },
  ];

  const buttons = [
    {
      variant: "danger",
      label: '<i className="fa fa-print" aria-hidden="true"></i> Export Excel',
      onClick: () => {
        const printUrl = `${axios.defaults.baseURL}${url}/print`;
        const printUrlWithParam = dispatch(getURLSearchCriteria(printUrl));

        console.log("printUrlWithParam", printUrlWithParam);

        window.open(printUrlWithParam, "_blank");
        // window.open(printUrl, "_blank");
      },
    },
  ];

  const config = {
    customFilters: [
      {
        label: "Invoice Status",
        name: "invoiceExists",
        type: "checkbox",
        datasource: [
          { key: 1, label: "วางบิลแล้ว" },
          { key: 0, label: "ยังไม่วางบิล" },
        ],
        inline: true,
      },
      {
        label: "Receipt Status",
        name: "receiptExists",
        type: "checkbox",
        datasource: [
          { key: 1, label: "ออกใบเสร็จแล้ว" },
          { key: 0, label: "ยังไม่ออกใบเสร็จ" },
        ],
        inline: true,
      },
      {
        label: "Over Duedate",
        name: "isOverDuedate",
        type: "switch",
        defaultValue: false,
      },
    ],
    enableActive: false,
    enableEdit: true,
    enableTrash: true,
    enableView: true,
    enableCheckbox: true,
    buttons: buttons,
    onBeforeBind: (data) => {
      return data.map((item) => {
        const {
          sourcePlace,
          destinationPlace,
          employeeName,
          carNo,
          bill,
          pay,
          invoiceNo,
          invoiceId,
          jobSTS,
          status,
          transactionDate,
        } = item.values;

        item.values.jobSTS = (
          <>
            {status === jobStatus.COMPLETE ? (
              <i className="fa fa-check" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-times" aria-hidden="true"></i>
            )}
            &nbsp;{jobSTS}
          </>
        );

        item.values.place = (
          <>
            <strong>From</strong> {sourcePlace}
            <br />
            <strong>To</strong> {destinationPlace}
          </>
        );
        item.values.employeeName = (
          <>
            {employeeName}
            <br />
            <Badge className="" bg="primary">
              {carNo}
            </Badge>
          </>
        );
        item.values.price = (
          <>
            <Badge className="text-white" bg="secondary">
              Bill: ${UtilsService.convertToMoney(bill)}
            </Badge>
            <br />
            <Badge className="" bg="danger">
              Pay: ${UtilsService.convertToMoney(pay)}
            </Badge>
          </>
        );

        item.values.invoiceNo = invoiceNo ? (
          <Button
            variant="link"
            className="p-1 text-link"
            target="_blank"
            href={`/transaction/invoice/edit/${invoiceId}`}
          >
            {invoiceNo}
          </Button>
        ) : (
          "-"
        );

        item.values.receiptNo = "-";

        if (item.values.receiptInfo && item.values.receiptInfo.length > 0)
          item.values.receiptNo = (
            <>
              {item.values.receiptInfo.map((receipt) => (
                <Button
                  variant="link"
                  className="p-1 text-link"
                  target="_blank"
                  href={`/transaction/receipt/edit/${receipt._id}`}
                >
                  {receipt.documentNo}
                </Button>
              ))}
            </>
          );

        item.values.transactionDate =
          DateService.convertDateToString(transactionDate);

        return item;
      });
    },
  };

  useEffect(() => {
    const tables = ["customer"];

    DatasourceAPI.getActiveDatasource(tables).then((ds) => {
      setDatasource(ds);
      setIsLoading(false);
    });
  }, []);

  return <DataTable columns={columns} url={url} {...config} />;
};

export default memo(JobDataTable);
