import DataTable from "@standard/components/DataTable";
import TitleHeader from "@standard/components/TitleHeader";
import { DateService, UtilsService } from "@standard/services";
import { Button } from "react-bootstrap";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import axios from "@standard/axios";
import { useState, useEffect } from "react";
import { DatasourceAPI } from "api";

import { getURLSearchCriteria } from "@standard/store/reducers/dataTableSlice";
import { useDispatch } from "react-redux";

const programType = "Report";
const programName = "Summary Report";
const url = "report/summary";

export default function SummaryReport() {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true);

  const [datasource, setDatasource] = useState({
    customer: [],
    month: [
      { label: "January", key: 1 },
      { label: "February", key: 2 },
      { label: "March", key: 3 },
      { label: "April", key: 4 },
      { label: "May", key: 5 },
      { label: "June", key: 6 },
      { label: "July", key: 7 },
      { label: "August", key: 8 },
      { label: "September", key: 9 },
      { label: "October", key: 10 },
      { label: "November", key: 11 },
      { label: "Decembe", key: 12 },
    ],
    year: [],
  });

  useEffect(() => {
    const tables = ["customer"];

    DatasourceAPI.getActiveDatasource(tables).then((ds) => {
      const currentYear = new Date().getFullYear() - 5;
      const yearDatasource = new Array(10).fill(null).map((item, itemIndex) => {
        return {
          key: currentYear + itemIndex,
          label: (currentYear + itemIndex).toString(),
        };
      });

      ds.year = yearDatasource;

      setDatasource({ ...datasource, ...ds });
      setIsLoading(false);
    });
  }, []);

  const columns = [
    {
      label: "Invoice No.",
      name: "invoiceNo",
      sortable: true,
      align: "center",
      search: { type: "text" },
    },
    {
      label: "Customer",
      name: "customerName",
      columnName: "customerId",
      sortable: true,
      search: {
        type: "ddl",
        datasource: datasource.customer,
        multiple: true,
      },
    },
    {
      label: "Receipt Date",
      name: "receiptDate",
      sortable: true,
      align: "center",
      // search: { type: "date", isRange: true },
    },
    {
      label: "Receipt No.",
      name: "receiptNo",
      sortable: true,
      align: "center",
      search: { type: "text" },
    },
    {
      label: "Created At",
      name: "createdAt",
      sortable: true,
      align: "center",
    },
    {
      label: "Created By",
      name: "createdBy",
      sortable: true,
      align: "left",
    },
    {
      label: "Deleted At",
      name: "deletedAt",
      sortable: true,
      align: "center",
    },
    {
      label: "Deleted By",
      name: "deletedBy",
      sortable: true,
      align: "left",
    },
  ];

  const buttons = [
    {
      variant: "danger",
      label: '<i className="fa fa-print" aria-hidden="true"></i> Export Excel',
      onClick: (selectedId) => {
        const printUrl = `${axios.defaults.baseURL}${url}/print`;
        const printUrlWithParam = dispatch(getURLSearchCriteria(printUrl));

        window.open(printUrlWithParam, "_blank");
      },
    },
  ];

  const config = {
    customFilters: [
      {
        label: "Month From",
        name: "monthfrom",
        type: "ddl",
        datasource: datasource.month,
        col: 6,
        defaultValue: null,
      },
      {
        label: "Year From",
        name: "yearfrom",
        type: "ddl",
        datasource: datasource.year,
        col: 6,
        defaultValue: null,
      },
      {
        label: "Month To",
        name: "monthto",
        type: "ddl",
        datasource: datasource.month,
        col: 6,
        defaultValue: null,
      },
      {
        label: "Year To",
        name: "yearto",
        type: "ddl",
        datasource: datasource.year,
        col: 6,
        defaultValue: null,
      },
    ],
    enableInsert: false,
    enableAction: false,
    enableActive: false,
    enableEdit: false,
    enableTrash: false,
    enableCheckbox: false,
    buttons: buttons,
    onBeforeBind: (data) => {
      return data.map((item) => {
        const {
          invoiceId,
          invoiceNo,
          createdAt,
          receiptDate,
          receiptNo,
          receiptId,
          invoiceAmount,
          deletedAt,
        } = item.values;

        const invoiceLink = `/transaction/invoice/edit/${invoiceId}`;

        item.values.createdAt = DateService.convertDateTimeToString(createdAt);

        if (!deletedAt) {
          item.values.invoiceNo = (
            <Button
              variant="link"
              className="text-link"
              target="_blank"
              href={invoiceLink}
            >
              {invoiceNo}
            </Button>
          );
        } else {
          item.values.deletedAt =
            DateService.convertDateTimeToString(deletedAt);
        }

        item.values.invoiceAmount = UtilsService.convertToMoney(
          invoiceAmount,
          "฿"
        );

        if (receiptNo) {
          const receiptLink = `/transaction/receipt/edit/${receiptId}`;
          item.values.receiptDate =
            DateService.convertDateToString(receiptDate);
          item.values.receiptNo = (
            <Button
              variant="link"
              className="text-link"
              target="_blank"
              href={receiptLink}
            >
              {receiptNo}
            </Button>
          );
        }

        return item;
      });
    },
  };

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <DataTable columns={columns} url={url} {...config} />
        </Route>
      </Switch>
    </>
  );
}
