import React, { Component } from "react";
import { Link } from "react-router-dom";
import avatar from "assets/images/user.png";
import { menuesAuthen } from "configs";
import { AuthService } from "@standard/services";
class Navbar extends Component {
  // async componentDidMount() {
  //     const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  //     const data = await axios.get(`/menu/${currentUser.username}`).then(res => {
  //         const data = res.data.data;
  //         return data;
  //     })
  //     const menu = await this.save(data);
  //     await this.setState({ menus: menu })
  // }
  // async save(data) {
  //     await localStorage.setItem('menu', JSON.stringify(data));
  //     const menu = JSON.parse(localStorage.getItem('menu'));
  //     return menu;
  // }
  state = {
    location: window.location.pathname,
  };
  logout() {
    AuthService.logout();
  }
  Clicklink = (e) => {
    this.setState({ location: window.location.pathname });
  };

  render() {
    const user = AuthService.getCurrentUser();

    // if (user_roles === undefined) {
    //   authenticationService.doLogout();
    //   window.location.href = "/";
    // }

    var menues = menuesAuthen
      .filter((f) => AuthService.isInRoles(f.roles))
      .map((item, index) => {
        if (item.child && item.child.length > 0) {
          var active_item = false;
          var child = item.child.map((item_child, index_child) => {
            if (item_child.child) {
              var child_child = item_child.child.map(
                (item_child_child, index_child_child) => {
                  const link_class = [];
                  if (item_child_child.link === this.state.location) {
                    link_class.push("active");
                    active_item = true;
                  }
                  return (
                    <li
                      key={index_child_child}
                      onClick={this.Clicklink.bind(index_child_child)}
                      className={link_class.join(" ")}
                    >
                      <Link to={item_child_child.link}>
                        {item_child_child.label}
                      </Link>
                    </li>
                  );
                }
              );

              return (
                <li key={index_child} className={active_item ? "active" : ""}>
                  <Link to="#">
                    <span className="nav-label">{item_child.label}</span>{" "}
                    <span className="fa arrow"></span>
                  </Link>
                  <ul
                    className={
                      item_child.link === this.state.location
                        ? "nav nav-third-level collapse in"
                        : "nav nav-third-level collapse"
                    }
                  >
                    {child_child}
                  </ul>
                </li>
              );
            } else {
              const link_class = [];
              if (item_child.link === this.state.location) {
                link_class.push("active");
                active_item = true;
              }
              return (
                <li
                  key={index_child}
                  onClick={this.Clicklink.bind(index_child)}
                  className={link_class.join(" ")}
                >
                  <Link to={item_child.link}>{item_child.label}</Link>
                </li>
              );
            }
          });

          return (
            <li key={index} className={active_item ? "active" : ""}>
              <Link to="#">
                <i className={item.iconClass}></i>{" "}
                <span className="nav-label">{item.label}</span>{" "}
                <span className="fa arrow"></span>
              </Link>
              <ul
                className={
                  item.link === this.state.location
                    ? "nav nav-second-level collapse in"
                    : "nav nav-second-level collapse"
                }
              >
                {child}
              </ul>
            </li>
          );
        } else {
          return (
            <li key={index}>
              <Link to={item.link ? item.link : "#"}>
                <i className={item.iconClass}></i>{" "}
                <span className="nav-label">{item.label}</span>
              </Link>
            </li>
          );
        }
      });

    return (
      <nav className="navbar-default navbar-static-side" role="navigation">
        <div className="sidebar-collapse">
          <ul className="nav metismenu" id="side-menu">
            <li className="nav-header">
              <div className="dropdown profile-element text-center">
                <a data-toggle="dropdown" className="dropdown-toggle" href="/">
                  <img
                    alt="avartar"
                    className="rounded-circle"
                    src={avatar}
                    width="50px"
                  />
                  <span className="text-muted m-t-xs text-xs block">
                    {user.fullName || ""} <b className="caret"></b>
                  </span>
                </a>
                <ul className="dropdown-menu animated fadeInRight m-t-xs">
                  <li>
                    <Link to="/profile" className="dropdown-item">
                      ประวัติส่วนตัว
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/profile/changepassword"
                      className="dropdown-item"
                    >
                      เปลี่ยนรหัสผ่าน
                    </Link>
                  </li>
                  <li>
                    <a
                      href="#logout"
                      className="dropdown-item"
                      onClick={this.logout}
                    >
                      ออกจากระบบ
                    </a>
                  </li>
                </ul>
              </div>
              <div className="logo-element">IN+</div>
            </li>
            {menues}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;
