const LABEL = {
  ALERT: {
    TITLE: {
      CONFIRM: "ยืนยัน",
      SUCCESS: "สำเร็จ",
      ERROR: "มีข้อผิดพลาด",
      WARNING: "แจ้งเตือน",
    },
    TEXT: {
      CONFIRM: "บันทึกข้อมูลเสร็จสิ้น",
      SUCCESS: "สำเร็จ",
      ERROR: "มีข้อผิดพลาด",
      WARNING: "ไม่ถูกต้อง",
    },
    ICON: {
      WARNING: "warning",
      SUCCESS: "success",
      ERROR: "error",
      INFO: "info",
    },
  },
};

export default LABEL;
