import { userRolesData } from "data";

export function ProfileRoleStatus({ role }) {
  const userRole = userRolesData.find((f) => f.key === role);
  let className = ["badge p-2"];
  switch (role.toLowerCase().trim()) {
    case "admin":
      className.push("badge-warning");
      break;
    case "user":
      className.push("badge-primary");
      break;
    case "superadmin":
      className.push("badge-danger");
      break;
  }

  return <span className={className.join(" ")}>{userRole.label ?? ""}</span>;
}
