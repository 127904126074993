import { BehaviorSubject } from "rxjs";
import Alert from "./alert";
import { history } from "../history";
import { STORAGE_NAME } from "@standard/constants";
import { Auth as AuthAPI } from "api";
import cookie from "./cookie";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem(STORAGE_NAME.CURRENT_USER))
);

function isAuth() {
  const currentUser = getCurrentUser();

  if (currentUser === null) return false;

  return true;
}

function changePassword({ token, newPassword, confirmPassword }) {
  var promise = AuthAPI.changePassword({ token, newPassword, confirmPassword });
  return promise;
}

function forgetPassword(email) {
  var promise = AuthAPI.forgetPassword(email);
  return promise;
}

function login({ username, password, remember }) {
  var promise = new Promise(function (resolve, reject) {
    AuthAPI.login(username, password, remember)
      .then((res) => {
        const currentUser = res.data.user;
        const token = res.data.token;
        setCurrentUser(currentUser, token);
        resolve(currentUser);
      })
      .catch((errors) => reject([errors]));
  });
  return promise;
}

function logout() {
  Alert.confirm("คุณต้องการออกจากระบบหรือไม่", "Logout").then((res) => {
    if (res === true) doLogout();
  });
}

function doLogout() {
  localStorage.removeItem(STORAGE_NAME.CURRENT_USER);
  localStorage.clear();
  cookie.removeToken();
  currentUserSubject.next(null);
  // history.push("/");
  window.location.href = "/";
}

function isRole(role) {
  const currentUser = getCurrentUser();

  if (currentUser !== null && currentUser.roles.indexOf(role) !== -1) {
    return true;
  }

  return false;
}

function getCurrentUser() {
  return currentUserSubject.value;
}

function getFullName() {
  const user = getCurrentUser();

  return user.fullName;
}

function setCurrentUser(currentUser, token = null) {
  localStorage.setItem(STORAGE_NAME.CURRENT_USER, JSON.stringify(currentUser));
  currentUserSubject.next(currentUser);
  if (token !== null) cookie.setToken(token);
}

function getUserName() {
  const user = getCurrentUser();

  return user.username;
}

const isInRoles = (roles) => {
  if (roles === undefined) return true;

  const user = getCurrentUser();
  const userRoles = user.roles;

  for (const userRole of userRoles) {
    if (roles.indexOf(userRole) !== -1) return true;
  }

  return false;
};

const auth = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  getCurrentUser,
  doLogout,
  isAuth,
  isRole,
  setCurrentUser,
  forgetPassword,
  changePassword,
  getUserName,
  isInRoles,
  getFullName,
};

export default auth;

export const getCurrentUserId = () => {
  const user = getCurrentUser();

  return user._id;
};
