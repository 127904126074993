import DataTable from "@standard/components/DataTable";
import TitleHeader from "@standard/components/TitleHeader";
import PlaceForm from "./PlaceForm";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const programType = "Master";
const programName = "Place Setup";
const url = "master/place";
const columns = [
  {
    label: "Customer Name",
    name: "customerName",
    align: "left",
    sortable: true,
  },
  {
    label: "Place",
    name: "name",
    align: "left",
    sortable: true,
  },
];

const config = {
  enableActive: false,
  enableEdit: true,
  enableTrash: false,
  enableInsert: false,
  onBeforeBind: (data) => {
    return data;
  },
};

export default function Place() {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <DataTable columns={columns} url={url} {...config} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <PlaceForm url={url} />
        </Route>
      </Switch>
    </>
  );
}
