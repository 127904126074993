export const dnReturnType = {
  IMPORT: "i",
  EXPORT: "e",
  PAT: "p",
};

export default [
  { key: dnReturnType.IMPORT, label: "Import" },
  { key: dnReturnType.EXPORT, label: "Export" },
  { key: dnReturnType.PAT, label: "PAT" },
];
