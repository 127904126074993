import { Link } from "react-router-dom";
import { AuthService } from "@standard/services";
import { history } from "@standard/history";
import { Controller, useForm } from "react-hook-form";
import { BoxContainerUnauthen } from "@standard/components/BoxContainer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  username: yup
    .string()
    // .email("You must enter a valid email")
    .required("Please enter your username or email"),
  password: yup.string().required("Please enter your password."),
  // .min(8, "Password is too short - should be 8 chars minimum."),
});

const defaultValues = {
  username: "",
  password: "",
  remember: true,
};

const Login = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors: errorField } = formState;

  useEffect(() => {
    if (!_.isEmpty(errorField)) {
      const _errors = [];
      for (var i in errorField) {
        _errors.push(errorField[i].message);
      }

      setErrors(_errors);
      console.log(errorField);
    }
  }, [errorField]);

  const onSubmit = (data) => {
    setIsLoading(true);
    var params = {
      username: data.username,
      password: data.password,
      remember: data.remember,
    };

    AuthService.login(params)
      .then(() => {
        setIsLoading(false);
        props.history.push({ pathname: "/" });
        window.location.reload();
        history.go(0);
      })
      .catch((errors) => {
        setIsLoading(false);
        setErrors(errors);
      });
  };

  return (
    <BoxContainerUnauthen
      isLoading={isLoading}
      errors={errors}
      isShowProjectName={true}
    >
      <Form className="m-t" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <div className="col-6 mx-auto">
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <Form.Group className="" controlId="formBasicEmail">
                  <Form.Control
                    type="username"
                    placeholder="Username / Email"
                    {...field}
                  />
                </Form.Group>
              )}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-6 mx-auto">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Form.Group className="" controlId="formBasicPassword">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    {...field}
                  />
                </Form.Group>
              )}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-6 mx-auto">
            <Button
              variant="primary"
              type="submit"
              size="lg"
              className="block full-width"
            >
              Login
            </Button>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-6 mx-auto">
            <div className="form-group row">
              <div className="text-left col">
                <Controller
                  name="remember"
                  control={control}
                  render={({ field }) => (
                    <Form.Group
                      className=""
                      controlId="formBasicCheckbox"
                      {...field}
                    >
                      <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group>
                  )}
                />
              </div>
              <div className="text-right col">
                <Link to="/forgotpassword">
                  <small>Forgot password?</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </BoxContainerUnauthen>
  );
};
export default Login;
