import NotFound from "../pages/NotFound";
import Dashboard from "../pages/Dashboard";
import Master from "../pages/Master";
import Transaction from "../pages/Transaction";
import Report from "../pages/Report";
import Sample from "../pages/Sample";

export default [
  {
    label: "Dashboard",
    iconClass: "fa fa-dashboard",
    link: "/",
    exact: true,
    component: Dashboard,
  },
  {
    label: "Master",
    iconClass: "fa fa-cogs",
    roles: ["superadmin", "admin"],
    child: [
      { label: "User", link: "/master/user", component: Master.User },
      {
        label: "Job Expense",
        link: "/master/jobexpense",
        component: Master.JobExpense,
      },
      {
        label: "Employee Group",
        link: "/master/employeegroup",
        component: Master.EmployeeGroup,
      },
      {
        label: "Type of Transport",
        link: "/master/typeOfTransport",
        component: Master.TypeOfTransport,
      },
      // { label: "Place", link: "/master/place", component: Master.Place },
      {
        label: "Customer",
        link: "/master/customer",
        component: Master.Customer,
      },
      {
        label: "Invoice Employee Expense",
        link: "/master/invoiceemployeeexpense",
        component: Master.InvoiceEmployeeExpense,
      },
      {
        label: "Setting",
        link: "/master/setting",
        component: Master.Setting,
      },
      // {
      //   label: "Invoice Template",
      //   link: "/master/invoicetemplate",
      //   component: Master.InvoiceTemplate,
      // },
      {
        label: "Employee",
        link: "/master/employee",
        component: Master.Employee,
      },
      {
        label: "Place",
        link: "/master/place",
        component: Master.Place,
      },
      // {
      //   label: "Price Rate",
      //   link: "/master/pricerate",
      //   component: Master.PriceRate,
      // },
    ],
  },
  {
    label: "Transaction",
    iconClass: "fa fa-usd",
    roles: ["superadmin", "admin", "user"],
    child: [
      {
        label: "DN Return",
        link: "/transaction/dnreturn",
        component: Transaction.DNReturn,
      },
      {
        label: "Job",
        link: "/transaction/job",
        component: Transaction.Job,
      },
      {
        label: "Invoice Employee",
        link: "/transaction/invoiceemployee",
        component: Transaction.InvoiceEmployee,
      },
      {
        label: "Invoice",
        link: "/transaction/invoice",
        component: Transaction.Invoice,
      },
      {
        label: "Receipt",
        link: "/transaction/receipt",
        component: Transaction.Receipt,
      },
    ],
  },
  {
    label: "Report",
    iconClass: "fa fa-file-pdf-o",
    roles: ["superadmin", "admin", "user"],
    child: [
      {
        label: "Summary",
        link: "/report/summary",
        component: Report.SummaryReport,
      },
      { label: "Job", link: "/report/job", component: Report.JobReport },
    ],
  },
  // {
  //   label: "Sample",
  //   iconClass: "fa fa-dashboard",
  //   link: "/sample",
  //   component: Sample,
  // },
  // {
  //   path: "*",
  //   component: NotFound,
  // },
];
