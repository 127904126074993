import TitleHeader from "@standard/components/TitleHeader";
import ReceiptForm from "./ReceiptForm";
import ReceiptDataTable from "./ReceiptDataTable";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const programType = "Tranasction";
const programName = "Receipt";
const url = "transaction/receipt";

export default function Customer() {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <ReceiptDataTable url={url} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <ReceiptForm url={url} />
        </Route>
      </Switch>
    </>
  );
}
