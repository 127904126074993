import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import Profile from "./Profile";
import ChangePasswordWithAuth from "./ChangePasswordWithAuth";

export default {
  Login,
  ForgotPassword,
  ChangePassword,
  Profile,
  ChangePasswordWithAuth,
};
