import swal from "sweetalert2";
import { AlertService, UtilsService, FormService } from "@standard/services";
import { wheelData } from "data";
import { DatasourceAPI } from "api";
import React, {
  useState,
  memo,
  useEffect,
  createContext,
  useReducer,
} from "react";
import PriceRateFormRow from "./PriceRateFormRow";
import _ from "lodash";
import { PriceRateAPI } from "api";
import Modal from "@standard/components/Modal";
import { useRef } from "react";
import PriceRatePlaceModal from "./PriceRatePlaceModal";
import PriceRateFilter from "./PriceRateFilter";
import PriceRateEditSourceModal from "./PriceRateEditSourceModal";
import { useCallback } from "react";

const initialState = {
  isLoading: true,
  priceRates: [],
  priceRateRatios: [],
  priceRateDefaultEachType: [],
  reFetchData: false,
};

export const PRICE_RATE_ACTION = {
  SAVE_SINGLE_PRICE_RATE: "saveSinglePriceRate",
  FETCH_PRICE_RATE_LOADING: "fetchPriceRateLoading",
  FETCH_PRICE_RATE_COMPLETE: "fetchPriceRateComplete",
  UPDATE_PRICE_RATE_PLACE: "updatePriceRatePlace",
  DELETE_PRICE_RATE: "deletePriceRate",
  UPDATE_ALL_SOURCE_PLACE: "updateAllSourcePlace",
};

const PriceRateReducer = (state, action) => {
  switch (action.type) {
    case PRICE_RATE_ACTION.SAVE_SINGLE_PRICE_RATE:
      return { ...state, isLoading: true, reFetchData: true };
    case PRICE_RATE_ACTION.FETCH_PRICE_RATE_LOADING:
      return { ...state, isLoading: true };
    case PRICE_RATE_ACTION.FETCH_PRICE_RATE_COMPLETE:
      return {
        ...state,
        isLoading: false,
        priceRates: action.payload.priceRates,
        priceRateRatios: action.payload.priceRateRatios,
        priceRateDefaultEachType: action.payload.priceRateDefaultEachType,
      };
    case PRICE_RATE_ACTION.UPDATE_PRICE_RATE_PLACE:
      return { ...state, isLoading: true };
    case PRICE_RATE_ACTION.DELETE_PRICE_RATE:
      return { ...state, isLoading: true };
    case PRICE_RATE_ACTION.UPDATE_ALL_SOURCE_PLACE:
      return { ...state, isLoading: true };
    default:
      throw new Error();
  }
};

export const PriceRateContext = createContext({});

const PriceRateFormDetail = ({
  customerId,
  wheel,
  autoUpdatePriceRate,
  ceil,
  employeeGroups,
}) => {
  const [state, dispatch] = useReducer(PriceRateReducer, initialState);

  const refModal = useRef(null);
  const url = `master/pricerate`;
  const defaultSelectedRatio = {
    _id: null,
    label: "",
    ratio: "",
    enableInputs: ["bill", "sts", "pay", "employeeGroup"],
    ratioOfPriceRateRatioId: null,
    sortIndex: "",
  };

  const [searchCriteria, setSearchCriteria] = useState({
    term: "",
    pageNumber: 1,
  });

  const [totalPage, setTotalPage] = useState(1);

  const [selectedRatio, setSelectedRatio] = useState(defaultSelectedRatio);

  const [datasource, setDatasource] = useState({
    place: [],
    employeegroup: [],
    pricerateratio: [],
    typeoftransport: [],
  });

  const fetchData = () => {
    dispatch({ type: PRICE_RATE_ACTION.FETCH_PRICE_RATE_LOADING });
    console.log("searchCriteria", searchCriteria);
    FormService.getRequest(url, {
      customerId,
      wheel,
      pageNumber: searchCriteria.pageNumber,
      term: searchCriteria.term,
    }).then((dataResult) => {
      const tables = [
        { name: "place", ref1: customerId },
        { name: "employeegroup" },
        { name: "priceratetype", ref1: { customerId } },
        {
          name: "pricerateratio",
          ref1: { customerId, wheel },
        },
      ];

      DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
        setTotalPage(Math.ceil(dataResult.totalrecord / 25));
        setDatasource(datasourceResult);
        dispatch({
          type: PRICE_RATE_ACTION.FETCH_PRICE_RATE_COMPLETE,
          payload: {
            priceRates: dataResult.priceRates,
            priceRateRatios: dataResult.priceRateRatios,
            priceRateDefaultEachType: dataResult.priceRateDefaultEachType,
          },
        });
      });
    });
  };

  useEffect(() => {
    console.log("searchCriteria term", searchCriteria, state.isLoading);
    fetchData();
  }, [searchCriteria.term, searchCriteria.pageNumber]);

  const onSearch = useCallback(({ term, pageNumber }) => {
    console.log("onSearch", term, pageNumber);
    setSearchCriteria({ term, pageNumber });
  }, []);

  const onSaveRatio = (value) => {
    const ratio = {
      _id: selectedRatio._id,
      label: value.label,
      ratio:
        value.ratio && UtilsService.isNumeric(value.ratio + "")
          ? parseFloat(value.ratio) / 100
          : null,
      enableInputs: value.enableInputs,
      ratioOfPriceRateRatioId: value.ratioOfPriceRateRatioId ?? null,
      sortIndex: value.sortIndex,
    };

    return new Promise((resolve) => {
      var promise;
      if (selectedRatio._id !== null) {
        promise = PriceRateAPI.updatePriceRateRatio({
          customerId,
          wheel,
          ratio,
        });
      } else {
        promise = PriceRateAPI.createPriceRateRatio({
          customerId,
          wheel,
          ratio,
        });
      }

      promise.then((res) => {
        setSelectedRatio(defaultSelectedRatio);
        fetchData();
        resolve({ show: false });
      });
    });
  };

  const onDeleteRatioClick = (priceRateRatioId) => {
    AlertService.confirm("Do you want to delete ?").then((res) => {
      if (res === true) {
        PriceRateAPI.removePriceRateRatio(priceRateRatioId).then(() => {
          fetchData();
        });
      }
    });
  };

  const onEditRatioClick = (index) => {
    const priceRateRatio = state.priceRateRatios[index];
    setSelectedRatio({
      _id: priceRateRatio._id,
      label: priceRateRatio.label,
      ratio: priceRateRatio.ratio * 100,
      enableInputs: priceRateRatio.enableInputs,
      ratioOfPriceRateRatioId: priceRateRatio.ratioOfPriceRateRatioId,
      sortIndex: priceRateRatio.sortIndex,
    });

    refModal.current.click();
  };

  const ratioFields = [
    { label: "Label", name: "label", type: "text", value: selectedRatio.label },
    {
      label: "Ratio",
      name: "ratio",
      type: "number",
      value: selectedRatio.ratio,
      decimal: 2,
      uom: "%",
    },
    {
      label: "Show",
      name: "enableInputs",
      type: "checkbox",
      value: selectedRatio.enableInputs,
      datasource: [
        { key: "bill", label: "Bill" },
        { key: "sts", label: "STS" },
        { key: "pay", label: "Pay" },
        { key: "employeeGroup", label: "Employee Group" },
      ],
      inline: true,
    },
    {
      label: "Ratio of",
      name: "ratioOfPriceRateRatioId",
      type: "ddl",
      value: selectedRatio.ratioOfPriceRateRatioId,
      datasource: datasource.pricerateratio,
    },
    {
      label: "Index",
      name: "sortIndex",
      type: "number",
      value: selectedRatio.sortIndex,
    },
  ];

  return (
    <PriceRateContext.Provider
      value={{
        dispatch,
        fetchData,
        customerId,
        wheel,
        autoUpdatePriceRate,
        ceil,
        priceRateRatios: state.priceRateRatios,
        employeeGroups,
        datasource,
      }}
    >
      <PriceRateFilter totalPage={totalPage} onSearch={onSearch} />
      <Modal
        ref={refModal}
        variant="primary"
        title="Add Ratio"
        label={
          <span>
            <i className="fa fa-plus"></i> Add Ratio
          </span>
        }
        enableSave={true}
        onSave={onSaveRatio}
        onClosed={() => {
          setSelectedRatio(defaultSelectedRatio);
        }}
        fields={ratioFields}
      ></Modal>
      &nbsp;
      <PriceRatePlaceModal
        onSave={(value) => {}}
        title="Add New Place"
        label={
          <>
            <i className="fa fa-plus"></i> Add New Place
          </>
        }
      />
      <PriceRateEditSourceModal
        onSave={(value) => {}}
        className="ml-1"
        priceRateDefaultEachType={state.priceRateDefaultEachType}
        title="Add / Remove Source to All Price"
        label={
          <>
            <i className="fa fa-pencil"></i> Add / Remove Source to All Price
          </>
        }
      />
      {state.isLoading ? (
        <>
          <br />
          <br />
          Loading...
        </>
      ) : (
        <div
          className="table-freeze mt-2"
          style={{ minHeight: "600px", minWidth: "100%" }}
        >
          <table
            className="table table-bordered table-sm"
            style={{ minWidth: "100%" }}
          >
            <thead>
              <tr>
                <th style={{ minWidth: "260px" }}>Places</th>
                {state.priceRateRatios.map((priceRateRatio, index) => (
                  <th key={index} style={{ minWidth: "260px" }}>
                    {priceRateRatio.label}{" "}
                    <div className="btn-group pull-right">
                      <button
                        className="btn btn-secondary btn-sm dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-cogs cursor-pointer"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <div className="dropdown-menu">
                        <a
                          className="dropdown-item"
                          onClick={() => onEditRatioClick(index)}
                        >
                          Edit
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => onDeleteRatioClick(priceRateRatio._id)}
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {state.priceRates.map((priceRate, priceRateIndex) => (
                <PriceRateFormRow key={priceRateIndex} priceRate={priceRate} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </PriceRateContext.Provider>
  );
};

export default memo(PriceRateFormDetail);
