import TitleHeader from "@standard/components/TitleHeader";
import InvoiceForm from "./InvoiceForm";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import InvoiceDataTable from "./InvoiceDataTable";

const programType = "Transaction";
const programName = "Invoice";
const url = "transaction/invoice";

export default function Customer() {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <InvoiceDataTable url={url} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <InvoiceForm url={url} />
        </Route>
      </Switch>
    </>
  );
}
