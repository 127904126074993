import { AlertService, UtilsService } from "@standard/services";
import { Badge, InputGroup, FormControl, Button } from "react-bootstrap";
import FormInput from "@standard/components/DataForm/FormInput";
import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { PriceRateContext, PRICE_RATE_ACTION } from "./PriceRateFormDetail";
import { PriceRateAPI } from "api";
import NumberFormat from "react-number-format";

const PriceRateFormInput = ({ detail, detailIndex }) => {
  const { fetchData, dispatch } = useContext(PriceRateContext);
  const [disabled, setDisabled] = useState(true);
  const [newDetail, setNewDetail] = useState({
    _id: null,
    bill: "",
    pay: "",
    sts: "",
    employeeGroups: [],
  });

  useEffect(() => {
    setNewDetail({
      _id: detail._id,
      bill: detail.bill
        ? UtilsService.convertToMoney(
            detail.bill.toString().replaceAll(",", "")
          )
        : "",
      pay: detail.pay
        ? UtilsService.convertToMoney(detail.pay.toString().replaceAll(",", ""))
        : "",
      sts: detail.sts
        ? UtilsService.convertToMoney(detail.sts.toString().replaceAll(",", ""))
        : "",
      employeeGroups: detail.employeeGroups?.map((employeeGroup) => {
        const price = UtilsService.convertToMoney(
          employeeGroup.price
            ? employeeGroup.price.toString().replaceAll(",", "")
            : ""
        );

        return {
          _id: employeeGroup._id,
          employeeGroupId: employeeGroup.employeeGroupId,
          label: employeeGroup.label,
          price,
        };
      }),
    });
  }, [detail]);

  const onChangeValue = ({ name, value }) => {
    setNewDetail((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onEmployeeGroupChangeValue = (employeeIndex, value) => {
    setNewDetail((prev) => {
      prev.employeeGroups[employeeIndex].price = value;
      return { ...prev };
    });
  };

  const onSaveClick = () => {
    AlertService.confirm("Do you want to save changes ?").then((res) => {
      if (res === true) {
        setDisabled(true);
        dispatch({ type: PRICE_RATE_ACTION.SAVE_SINGLE_PRICE_RATE });
        PriceRateAPI.updatePriceRateById({
          detail: {
            _id: newDetail._id,
            pay: newDetail.pay
              ? UtilsService.convertToDecimal(newDetail.pay)
              : null,
            sts: newDetail.sts
              ? UtilsService.convertToDecimal(newDetail.sts)
              : null,
            bill: newDetail.bill
              ? UtilsService.convertToDecimal(newDetail.bill)
              : null,
            employeeGroups: newDetail.employeeGroups.map((employeeGroup) => {
              return {
                employeeGroupId: employeeGroup.employeeGroupId,
                price: employeeGroup.price
                  ? UtilsService.convertToDecimal(employeeGroup.price)
                  : null,
              };
            }),
          },
        }).then(() => {
          fetchData();
        });
      }
    });
  };

  const onCancelClick = () => {
    setNewDetail({
      _id: detail._id,
      bill: detail.bill
        ? UtilsService.convertToMoney(
            detail.bill.toString().replaceAll(",", "")
          )
        : "",
      pay: detail.pay
        ? UtilsService.convertToMoney(detail.pay.toString().replaceAll(",", ""))
        : "",
      sts: detail.sts
        ? UtilsService.convertToMoney(detail.sts.toString().replaceAll(",", ""))
        : "",
      employeeGroups: detail.employeeGroups?.map((employeeGroup) => {
        const price = UtilsService.convertToMoney(
          employeeGroup.price.toString().replaceAll(",", "")
        );

        return {
          _id: employeeGroup._id,
          employeeGroupId: employeeGroup.employeeGroupId,
          label: employeeGroup.label,
          price,
        };
      }),
    });
    setDisabled(true);
  };

  return (
    <>
      <div className="d-flex flex-row">
        <div className="input-group input-group-sm mb-3">
          {detail.enableInputs.indexOf("bill") !== -1 && (
            <>
              <span className="input-group-text">Bill</span>
              <NumberFormat
                value={newDetail.bill}
                thousandSeparator={true}
                className="form-control"
                style={{ minWidth: "60px" }}
                id={"bill"}
                name={"bill"}
                required={false}
                prefix={""}
                decimalScale={2}
                decimalSeparator="."
                disabled={disabled}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  onChangeValue({ name: "bill", value: value });
                }}
              />
            </>
          )}
        </div>
        <div className="input-group input-group-sm mb-3">
          {detail.enableInputs.indexOf("pay") !== -1 && (
            <>
              <span className="input-group-text">Pay</span>
              <NumberFormat
                value={newDetail.pay}
                thousandSeparator={true}
                className="form-control"
                style={{ minWidth: "60px" }}
                id={"pay"}
                name={"pay"}
                required={false}
                prefix={""}
                decimalScale={2}
                decimalSeparator="."
                disabled={disabled}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  onChangeValue({ name: "pay", value: value });
                }}
              />
            </>
          )}
        </div>
      </div>

      <div className="d-flex flex-row">
        <div className="input-group input-group-sm mb-3">
          {detail.enableInputs.indexOf("sts") !== -1 && (
            <>
              <span className="input-group-text">STS</span>
              <NumberFormat
                value={newDetail.sts}
                thousandSeparator={true}
                className="form-control"
                style={{ minWidth: "60px" }}
                id={"sts"}
                name={"sts"}
                required={false}
                prefix={""}
                decimalScale={2}
                decimalSeparator="."
                disabled={disabled}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  onChangeValue({ name: "sts", value: value });
                }}
              />
            </>
          )}
        </div>
        <div className="input-group input-group-sm mb-3">
          {detail.enableInputs.indexOf("employeeGroup") !== -1 && (
            <>
              {newDetail.employeeGroups.map(
                (employeeGroup, employeeGroupIndex) => {
                  return (
                    <React.Fragment
                      key={`${detailIndex}-${employeeGroupIndex}`}
                    >
                      <span className="input-group-text bg-warning">
                        {employeeGroup.label}
                      </span>
                      <NumberFormat
                        value={employeeGroup.price}
                        thousandSeparator={true}
                        className="form-control"
                        style={{ minWidth: "60px" }}
                        id={"employeeGroup"}
                        name={"employeeGroup"}
                        required={false}
                        prefix={""}
                        decimalScale={2}
                        decimalSeparator="."
                        disabled={disabled}
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                          // onChangeValue({ name: "employeeGroup", value: value });
                          onEmployeeGroupChangeValue(employeeGroupIndex, value);
                        }}
                      />
                    </React.Fragment>
                  );
                }
              )}
            </>
          )}
        </div>
      </div>
      {disabled && detailIndex > 0 && (
        <button
          type="button"
          className="btn btn-block btn-info mt-3"
          onClick={() => setDisabled(false)}
        >
          Edit
        </button>
      )}
      {!disabled && (
        <div className="btn-group d-block" role="group">
          <button
            type="button"
            className="btn btn-primary w-50"
            onClick={onSaveClick}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-warning w-50"
            onClick={onCancelClick}
          >
            Cancel
          </button>
        </div>
      )}
    </>
  );
};

export default PriceRateFormInput;
