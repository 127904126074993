import React, { useState, memo, useEffect, useRef, forwardRef } from "react";
import { Badge, Button } from "react-bootstrap";
import ReactModal from "react-bootstrap/Modal";
import FormInput from "@standard/components/DataForm/FormInput";
import { AlertService } from "@standard/services";
import { PriceRateAPI } from "api";
import { useContext } from "react";
import { PriceRateContext, PRICE_RATE_ACTION } from "./PriceRateFormDetail";
import NumberFormat from "react-number-format";
import _ from "lodash";

const PriceRatePlaceModal = ({
  onSave,
  disabled,
  label,
  title,
  className = "",
  variant = "secondary",
  priceRate = {},
}) => {
  const {
    dispatch,
    fetchData,
    customerId,
    wheel,
    autoUpdatePriceRate,
    ceil,
    priceRateRatios,
    employeeGroups,
    datasource,
  } = useContext(PriceRateContext);
  const [show, setShow] = useState(false);
  const [places, setPlaces] = useState({
    destinationPlaces: [],
    sourcePlaces: [],
  });
  const [priceRateTypeId, setPriceRateTypeId] = useState(null);
  const [inputFields, setInputField] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onSaveModal = () => {
    AlertService.confirm("Do you want to save ?").then((res) => {
      if (res === true) {
        dispatch({ type: PRICE_RATE_ACTION.UPDATE_PRICE_RATE_PLACE });
        const sourcePlaces = places.sourcePlaces.map((place) => place.key);
        const destinationPlaces = places.destinationPlaces.map(
          (place) => place.key
        );

        const detail = inputFields.map((d) => {
          return {
            id: d._id,
            priceRateRatioId: d.priceRateRatioId,
            bill: d.fields.bill.value,
            sts: d.fields.sts.value,
            pay: d.fields.pay.value,
            employeeGroups: d.fields.employeeGroups.map((employeeGroup) => {
              return {
                _id: employeeGroup._id,
                employeeGroupId: employeeGroup.employeeGroupId,
                price: employeeGroup.value,
              };
            }),
          };
        });

        const newPriceRate = {
          _id: !_.isEmpty(priceRate) ? priceRate._id : null,
          priceRateTypeId: priceRateTypeId,
          sourcePlaces,
          destinationPlaces,
          detail,
        };

        PriceRateAPI.updatePriceRateRow({
          customerId,
          wheel,
          priceRate: newPriceRate,
        }).then(() => {
          setShow(false);
          fetchData();
        });
      }
    });
  };

  useEffect(() => {
    const inputFieldsInit = [];

    if (!_.isEmpty(priceRate)) {
      setPlaces({
        sourcePlaces: priceRate.sourcePlaces,
        destinationPlaces: priceRate.destinationPlaces,
      });

      setPriceRateTypeId(priceRate.priceRateTypeId);
    }

    priceRateRatios.map((priceRateRatio) => {
      let defaultBill = "",
        defaultPay = "",
        defaultSTS = "",
        defaultEmployeeGroups = [],
        detailId = null;

      if (!_.isEmpty(priceRate)) {
        const detail = priceRate.detail.find(
          (f) => f.priceRateRatioId === priceRateRatio._id
        );

        if (detail) {
          detailId = detail._id;
          defaultBill = detail.bill;
          defaultPay = detail.pay;
          defaultSTS = detail.sts;
          defaultEmployeeGroups = detail.employeeGroups;
        }
      }

      const employeeGroupFields = employeeGroups.map((employeeGroup) => {
        let employeeGroupValue = "";
        const employeeGroupDetail = defaultEmployeeGroups.find(
          (f) => f.employeeGroupId === employeeGroup.key
        );

        if (employeeGroupDetail) {
          employeeGroupValue = employeeGroupDetail.price ?? "";
        }

        return {
          label: employeeGroup.label,
          name: `${priceRateRatio._id}_${employeeGroup._id}_employeeGroup`,
          type: "number",
          uom: "฿",
          value: employeeGroupValue,
          decimal: 2,
          _id: employeeGroupDetail ? employeeGroupDetail._id : null,
          employeeGroupId: employeeGroup.key,
        };
      });

      inputFieldsInit.push({
        _id: detailId,
        priceRateRatioId: priceRateRatio._id,
        ratioOfPriceRateRatioId: priceRateRatio.ratioOfPriceRateRatioId,
        label: priceRateRatio.label,
        ratio: priceRateRatio.ratio,
        fields: {
          bill: {
            label: "Bill",
            name: `${priceRateRatio._id}_bill`,
            type: "number",
            uom: "฿",
            value: defaultBill,
            decimal: 2,
          },
          sts: {
            label: "STS",
            name: `${priceRateRatio._id}_sts`,
            type: "number",
            uom: "฿",
            value: defaultSTS,
            decimal: 2,
          },
          pay: {
            label: "Pay",
            name: `${priceRateRatio._id}_pay`,
            type: "number",
            uom: "฿",
            value: defaultPay,
            decimal: 2,
          },
          employeeGroups: employeeGroupFields,
        },
        enableInputs: priceRateRatio.enableInputs,
      });
    });

    setInputField(inputFieldsInit);
  }, [priceRateRatios]);

  const updateRelativeField = ({
    priceRateRatioId,
    type,
    value,
    employeeGroupFieldIndex,
  }) => {
    const newInputFields = inputFields.map((inputField) => {
      if (inputField.ratioOfPriceRateRatioId === priceRateRatioId) {
        let newValue = parseFloat(value) + parseFloat(value) * inputField.ratio;
        if (ceil === true) newValue = Math.round(newValue);

        if (type === "employeeGroup") {
          inputField.fields.employeeGroups[employeeGroupFieldIndex].value =
            newValue;
        } else {
          inputField.fields[type].value = newValue;
        }
      }

      return inputField;
    });

    setInputField(newInputFields);
  };

  const onInputChange = (value, index, type) => {
    let priceRateRatioId;
    const newInputField = inputFields.map((inputField, inputFieldIndex) => {
      if (inputFieldIndex === index) {
        inputField.fields[type].value = value;
        priceRateRatioId = inputField.priceRateRatioId;
      }

      return inputField;
    });

    setInputField(newInputField);

    if (autoUpdatePriceRate === true && type === "bill")
      updateRelativeField({ priceRateRatioId, type, value });
  };

  const onEmployeeGroupInputChange = (
    value,
    index,
    employeeGroupFieldIndex
  ) => {
    let priceRateRatioId;
    const newInputField = inputFields.map((inputField, inputFieldIndex) => {
      if (inputFieldIndex === index) {
        inputField.fields.employeeGroups[employeeGroupFieldIndex].value = value;
        priceRateRatioId = inputField.priceRateRatioId;
      }

      return inputField;
    });

    setInputField(newInputField);

    // if (autoUpdatePriceRate === true)
    //   updateRelativeField({
    //     priceRateRatioId,
    //     type: "employeeGroup",
    //     value,
    //     employeeGroupFieldIndex,
    //   });
  };

  const onRemovePlace = ({ name, index }) => {
    AlertService.confirm("Do you want to delete ?").then((res) => {
      if (res === true) {
        const newPlaces = [...places[name]].filter(
          (place, placeIndex) => placeIndex !== index
        );

        setPlaces((prev) => {
          return { ...prev, [name]: newPlaces };
        });
      }
    });
  };

  const onInputPlaceChange = ({ name, value }) => {
    if (value === undefined) return;
    if (places[name].findIndex((place) => place.key === value) >= 0) return;

    const newPlaces = [...places[name]];

    const ds = datasource.place.find((f) => f.key === value);
    let placeName = value;
    if (ds) {
      placeName = ds.label;
    }

    newPlaces.push({
      key: value,
      label: placeName,
    });

    setPlaces((prev) => {
      return { ...prev, [name]: newPlaces };
    });

    // setPriceRates((prev) => {
    //   return prev.map((prevPriceRate, prevPriceRateIndex) => {
    //     if (prevPriceRateIndex === index)
    //       prevPriceRate.sourcePlaces = sourcePlaces;
    //     return prevPriceRate;
    //   });
    // });
  };

  const onInputPriceRateTypeChange = ({ value }) => {
    setPriceRateTypeId(value);
  };

  const placeField = {
    priceRateType: {
      label: "Price Rate Type",
      name: "priceRateTypeId",
      type: "ddl",
      required: false,
      datasource: datasource.priceratetype,
      value: priceRateTypeId,
    },
    sourcePlace: {
      label: "Source Place",
      name: "sourcePlaces",
      type: "ddl",
      datasource: datasource.place,
      value: null,
      createable: true,
      placeholder: "Source Place",
    },
    destinationPlace: {
      label: "Destination Place",
      name: "destinationPlaces",
      type: "ddl",
      datasource: datasource.place,
      value: null,
      createable: true,
      placeholder: "Destination Place",
    },
  };

  return (
    <>
      <Button
        variant={variant}
        onClick={handleShow}
        disabled={disabled}
        className={className}
      >
        {label}
      </Button>

      <ReactModal show={show} onHide={handleClose} size="lg">
        <ReactModal.Header className="bg-primary">
          <ReactModal.Title>{title}</ReactModal.Title>
          <a href="#" className="mt-2 mr-3 text-white" onClick={handleClose}>
            <i className="fa fa-times" style={{ fontSize: "1.5em" }}></i>
          </a>
        </ReactModal.Header>
        <ReactModal.Body>
          <FormInput
            field={placeField.priceRateType}
            onChange={onInputPriceRateTypeChange}
          />
          <FormInput
            field={placeField.sourcePlace}
            onChange={onInputPlaceChange}
          />
          <div className="col-12" style={{ marginTop: "-10px" }}>
            {places.sourcePlaces.map((place, placeIndex) => (
              <Badge key={placeIndex} bg="primary" className="text-white mr-1">
                {place.label}{" "}
                <span
                  className="ml-2 cursor-pointer"
                  onClick={() =>
                    onRemovePlace({ name: "sourcePlaces", index: placeIndex })
                  }
                >
                  <i className="fa fa-times text-danger"></i>
                </span>
              </Badge>
            ))}
          </div>
          <FormInput
            field={placeField.destinationPlace}
            onChange={onInputPlaceChange}
          />
          <div className="col-12" style={{ marginTop: "-10px" }}>
            {places.destinationPlaces.map((place, placeIndex) => (
              <Badge
                key={placeIndex}
                bg="secondary"
                className="text-white mr-1"
              >
                {place.label}{" "}
                <span
                  className="ml-2 cursor-pointer"
                  onClick={() =>
                    onRemovePlace({
                      name: "destinationPlaces",
                      index: placeIndex,
                    })
                  }
                >
                  <i className="fa fa-times text-danger"></i>
                </span>
              </Badge>
            ))}
          </div>
          <div className="mt-3">
            {inputFields.map((inputField, inputFieldIndex) => (
              <div className="card mb-2" key={inputFieldIndex}>
                <div className="card-header bg-info">
                  <strong>{inputField.label}</strong>
                </div>
                <div className="card-body">
                  <div className="d-flex flex-row">
                    <div className="input-group input-group-sm mb-3">
                      {inputField.enableInputs.indexOf("bill") !== -1 && (
                        <>
                          <span className="input-group-text">Bill</span>
                          <NumberFormat
                            value={inputField.fields.bill.value}
                            thousandSeparator={true}
                            className="form-control"
                            style={{ minWidth: "60px" }}
                            id={inputField.fields.bill.name}
                            name={inputField.fields.bill.name}
                            required={false}
                            prefix={""}
                            decimalScale={2}
                            decimalSeparator="."
                            disabled={disabled}
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              onInputChange(value, inputFieldIndex, "bill");
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      {inputField.enableInputs.indexOf("pay") !== -1 && (
                        <>
                          <span className="input-group-text">Pay</span>
                          <NumberFormat
                            value={inputField.fields.pay.value}
                            thousandSeparator={true}
                            className="form-control"
                            style={{ minWidth: "60px" }}
                            id={inputField.fields.pay.name}
                            name={inputField.fields.pay.name}
                            required={false}
                            prefix={""}
                            decimalScale={2}
                            decimalSeparator="."
                            disabled={disabled}
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              onInputChange(value, inputFieldIndex, "pay");
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="input-group input-group-sm mb-3">
                      {inputField.enableInputs.indexOf("sts") !== -1 && (
                        <>
                          <span className="input-group-text">STS</span>
                          <NumberFormat
                            value={inputField.fields.sts.value}
                            thousandSeparator={true}
                            className="form-control"
                            style={{ minWidth: "60px" }}
                            id={inputField.fields.sts.name}
                            name={inputField.fields.sts.name}
                            required={false}
                            prefix={""}
                            decimalScale={2}
                            decimalSeparator="."
                            disabled={disabled}
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              onInputChange(value, inputFieldIndex, "sts");
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      {inputField.enableInputs.indexOf("employeeGroup") !==
                        -1 && (
                        <>
                          {inputField.fields.employeeGroups.map(
                            (employeeGroupField, employeeGroupFieldIndex) => {
                              return (
                                <React.Fragment
                                  key={`${employeeGroupField.name}`}
                                >
                                  <span className="input-group-text bg-warning">
                                    {employeeGroupField.label}
                                  </span>
                                  <NumberFormat
                                    value={employeeGroupField.value}
                                    thousandSeparator={true}
                                    className="form-control"
                                    style={{ minWidth: "60px" }}
                                    id={"employeeGroup"}
                                    name={"employeeGroup"}
                                    required={false}
                                    prefix={""}
                                    decimalScale={2}
                                    decimalSeparator="."
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      onEmployeeGroupInputChange(
                                        value,
                                        inputFieldIndex,
                                        employeeGroupFieldIndex
                                      );
                                    }}
                                  />
                                </React.Fragment>
                              );
                            }
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {/* <FormInput
                    field={inputField.fields.bill}
                    onChange={(value) => {
                      onInputChange(value, inputFieldIndex, "bill");
                    }}
                  />
                  <FormInput
                    field={inputField.fields.sts}
                    onChange={(value) => {
                      onInputChange(value, inputFieldIndex, "sts");
                    }}
                  />
                  <FormInput
                    field={inputField.fields.pay}
                    onChange={(value) => {
                      onInputChange(value, inputFieldIndex, "pay");
                    }}
                  />
                  {inputField.fields.employeeGroups.map(
                    (employeeGroupField, employeeGroupFieldIndex) => (
                      <FormInput
                        key={employeeGroupField.name}
                        field={employeeGroupField}
                        onChange={(value) => {
                          onEmployeeGroupInputChange(
                            value,
                            inputFieldIndex,
                            employeeGroupFieldIndex
                          );
                        }}
                      />
                    )
                  )} */}
                </div>
              </div>
            ))}
          </div>
        </ReactModal.Body>
        <ReactModal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSaveModal}>
            Save Changes
          </Button>
        </ReactModal.Footer>
      </ReactModal>
    </>
  );
};

export default memo(PriceRatePlaceModal);
