import { isValidElement } from "react";

var HtmlToReactParser = require("html-to-react").Parser;
const $ = window.$;

export default {
  goToTop,
  htmlParse,
  arraysEqual,
  checkZeroValue,
  JSONEqual,
  getProjectName,
  isReactElement,
  convertToMoney,
  convertMoneyToInt,
  convertToDecimal,
  convertToInt,
  numberWithCommas,
  isNumeric,
};

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

function convertMoneyToInt(money) {
  return parseInt(money.replace(/[^0-9]/g, ""));
}

function convertToDecimal(numeric) {
  if (typeof numeric === "string") numeric = numeric.replace(",", "");
  const number = parseFloat(numeric);

  if (isNaN(number)) {
    return 0;
  } else {
    return number;
  }
}
function numberWithCommas(x) {
  if (x === undefined || x === null) return "0";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function convertToMoney(money, sign = null) {
  if (money !== undefined && money !== null && money !== "") {
    money = parseFloat(money);
    return (
      money.toLocaleString(undefined, { maximumFractionDigits: 2 }) +
      (sign ? " " + sign : "")
    );
  }

  return "";
}
function convertToInt(int) {
  return int === null || int === undefined || int === "" || isNaN(int)
    ? 0
    : parseInt(int);
}

function isReactElement(element) {
  return isValidElement(element);
}

function getProjectName() {
  const projectName = process.env.REACT_APP_PROJECT_NAME;

  return projectName;
}

function goToTop() {
  var body = $("html, body");
  body.stop().animate({ scrollTop: 0 }, 500, "swing");
}

function checkZeroValue(int) {
  let value = this.convertToDecimal(int);
  let zero = false;
  if (value === 0) {
    zero = true;
  }
  return zero;
}

function htmlParse(html) {
  var htmlToReactParser = new HtmlToReactParser();
  const reactElement = htmlToReactParser.parse(html);

  return reactElement;
}

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

function JSONEqual(x, y) {
  // If both x and y are null or undefined and exactly the same
  if (x === y) {
    return true;
  }

  // If they are not strictly equal, they both need to be Objects
  if (!(x instanceof Object) || !(y instanceof Object)) {
    return false;
  }

  // They must have the exact same prototype chain, the closest we can do is
  // test the constructor.
  if (x.constructor !== y.constructor) {
    return false;
  }

  for (var p in x) {
    // Inherited properties were tested using x.constructor === y.constructor
    if (x.hasOwnProperty(p)) {
      // Allows comparing x[ p ] and y[ p ] when set to undefined
      if (!y.hasOwnProperty(p)) {
        return false;
      }

      // If they have the same strict value or identity then they are equal
      if (x[p] === y[p]) {
        continue;
      }

      // Numbers, Strings, Functions, Booleans must be strictly equal
      if (typeof x[p] !== "object") {
        return false;
      }

      // Objects and Arrays must be tested recursively
      if (!JSONEqual(x[p], y[p])) {
        return false;
      }
    }
  }

  for (p in y) {
    // allows x[ p ] to be set to undefined
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
      return false;
    }
  }
  return true;
}
