import { combineReducers } from "redux";
import auth from "./authSlice";
import counter from "./counterSlice";
import container from "./containerSlice";
import dataTable from "./dataTableSlice";

export default combineReducers({
  auth,
  counter,
  container,
  dataTable,
});
