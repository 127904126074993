import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory } from "react-router-dom";
import { useState, memo, useEffect } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import {
  DateService,
  AuthService,
  FormService,
  AlertService,
} from "@standard/services";
import { Button } from "react-bootstrap";
import { DatasourceAPI, ReceiptAPI } from "api";
import axios from "@standard/axios";
import { paymentMethodData } from "data";
import { paymentMethod, userRole } from "constants";
import ReceiptDescription from "./ReceiptDescription";
import { receiptStatus } from "data/receiptStatus";

export default function ReceiptForm({ url }) {
  const history = useHistory();
  const { id: paramsId, action } = useParams();
  const [id, setId] = useState(paramsId);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fullname = AuthService.getFullName();

  const defaultData = {
    id: id,
    createdAt: new Date(),
    createdByName: fullname,
    transactionDate: new Date(),
    documentNo: "-",
    customerId: null,
    paymentMethod: paymentMethod.CASH,
    bankId: null,
    branch: "",
    chequeNo: "",
    chequeDate: new Date(),
    isLocked: false,
    status: "",
  };

  const [data, setData] = useState(defaultData);

  const [datasource, setDatasource] = useState({
    customer: [],
    bank: [],
  });

  const [invoices, setInvoices] = useState([]);
  const [customerId, setCustomerId] = useState(data.customerId);
  const [selectedInvoiceIdList, setSelectedInvoiceIdList] = useState([]);
  const disabled = data.isLocked === true;

  useEffect(() => {
    if (customerId) {
      setIsLoading(true);

      const receiptId = id;

      ReceiptAPI.getInvoices({
        customerId,
        receiptId,
      }).then((invoiceResult) => {
        const invoiceEntities = invoiceResult.map((invoice) => {
          invoice.selected =
            selectedInvoiceIdList.find(
              (f) =>
                f.invoiceId === invoice.id &&
                f.receiptSourceId === invoice.receiptSourceId
            ) !== undefined;
          return invoice;
        });

        setInvoices(invoiceEntities);
        setIsLoading(false);
      });
    }
  }, [customerId]);

  const fetchData = () => {
    if (id !== undefined) {
      FormService.getRequest(url, id).then((dataResult) => {
        const tables = [
          { name: "customer", value: dataResult.customerId },
          { name: "bank", value: dataResult.typeOfTransportId },
        ];

        DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
          setDatasource(datasourceResult);
          setData(dataResult);

          setSelectedInvoiceIdList(dataResult.invoices);

          setCustomerId(dataResult.customerId);

          setIsLoading(false);
        });
      });
    } else {
      const tables = [{ name: "customer" }, { name: "bank" }];

      DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
        setData(data);

        setDatasource(datasourceResult);

        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const save = (newData) => {
    setIsLoading(true);
    const savedData = { ...newData };
    savedData.invoices = selectedInvoiceIdList;
    savedData.id = id;

    const params = { ...savedData };
    params.status = data.status;

    FormService.submitRequest(url, params)
      .then((result) => {
        history.push(`/${url}/edit/${result.data._id}`);

        if (result.data._id !== id) setId(result.data._id);
        else fetchData();
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const onSubmit = (newData) => {
    const status = data.status;
    let message = "";

    if (status === receiptStatus.DRAFT) {
      message = "Do you want to save ?";
    } else {
      message = "Do you want to publish ?";
    }

    AlertService.confirm(message, "Confirmation").then((res) => {
      if (res === true) {
        save(newData);
      }
    });
  };

  const fields = [
    {
      label: "Receipt No.",
      name: "documentNo",
      type: "label",
    },
    {
      label: "Customer Name",
      name: "customerId",
      type: "ddl",
      required: true,
      datasource: datasource.customer,
      disabled: disabled,
    },
    {
      label: "Transaction Date",
      name: "transactionDate",
      type: "date",
      required: true,
      disabled: disabled,
    },
    {
      label: "Payment Method",
      name: "paymentMethod",
      type: "radio",
      datasource: paymentMethodData,
      required: true,
      inline: true,
      disabled: disabled,
    },
  ];

  if (data.paymentMethod === paymentMethod.CHEQUE) {
    fields.push({
      label: "Bank",
      name: "bankId",
      type: "ddl",
      required: true,
      datasource: datasource.bank,
      col: 6,
      disabled: disabled,
    });
    fields.push({
      label: "Branch",
      name: "branch",
      type: "text",
      required: true,
      col: 6,
      disabled: disabled,
    });
    fields.push({
      label: "Cheque No.",
      name: "chequeNo",
      type: "text",
      required: false,
      col: 6,
      disabled: disabled,
    });
    fields.push({
      label: "Date",
      name: "chequeDate",
      type: "date",
      required: false,
      col: 6,
      disabled: disabled,
    });
  }

  const leftPanel = (
    <>
      {data.isLocked === true ? (
        <i className="fa fa-lock" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-unlock" aria-hidden="true"></i>
      )}
      {"  "}
      <strong style={{ fontSize: "14px", color: "#676a6c", fontWeight: "600" }}>
        By {data.createdByName}
      </strong>
      <br />
      <small className="text-muted">
        {DateService.convertDateTimeToString(data.createdAt)}
      </small>
    </>
  );

  const onRequestUnlockedClick = () => {
    AlertService.confirm(
      `Do you want to request unlock invoice no ${data.documentNo} ?`
    ).then((res) => {
      if (res === true) {
        setIsLoading(true);
        const { _id: requestByUserId } = AuthService.getCurrentUser();
        ReceiptAPI.requestUnlocked({ requestByUserId, receiptId: id }).then(
          (res) =>
            AlertService.done(
              "Request was sent to authorizer, please wait for unlock this receipt"
            ).then(() => setIsLoading(false))
        );
      }
    });
  };

  const onUnlockedClick = () => {
    AlertService.confirm(
      `Are you sure to unlock receipt no ${data.documentNo} ?`
    ).then((res) => {
      if (res === true) {
        setIsLoading(true);
        ReceiptAPI.unlocked({ receiptId: id }).then((res) =>
          AlertService.done(`Receipt no ${data.documentNo} was unlocked`).then(
            () => fetchData()
          )
        );
      }
    });
  };

  const onLockedClick = () => {
    AlertService.confirm(
      `Are you sure to lock receipt no ${data.documentNo} ?`
    ).then((res) => {
      if (res === true) {
        setIsLoading(true);
        ReceiptAPI.lock({ receiptId: id }).then((res) =>
          AlertService.done(`Receipt no ${data.documentNo} was locked`).then(
            () => setIsLoading(false)
          )
        );
      }
    });
  };

  const buttons = [];

  const baseUrl = axios.defaults.baseURL;

  if (id) {
    buttons.push(
      <Button
        key={1}
        variant="info"
        target="_blank"
        href={`${baseUrl}transaction/receipt/print/${id}`}
        className="mr-1"
      >
        Receipt Report
      </Button>
    );
  }

  if (data.isLocked === false) {
    if (AuthService.isRole(userRole.SUPPER_ADMIN) && id) {
      buttons.push(
        <Button
          key={2}
          variant="danger"
          className="mr-1"
          onClick={onLockedClick}
        >
          Locked
        </Button>
      );
    }
    buttons.push(
      <Button
        key={receiptStatus.DRAFT}
        variant="warning"
        type="submit"
        className="mr-1"
        onClick={() => setData({ ...data, status: receiptStatus.DRAFT })}
      >
        Save Draft
      </Button>
    );

    buttons.push(
      <Button
        key={receiptStatus.PUBLISH}
        variant="secondary"
        type="submit"
        className="mr-1"
        onClick={() => setData({ ...data, status: receiptStatus.PUBLISH })}
      >
        Publish
      </Button>
    );
  } else {
    if (AuthService.isRole(userRole.SUPPER_ADMIN)) {
      buttons.push(
        <Button
          key={4}
          variant="danger"
          className="mr-1"
          onClick={onUnlockedClick}
        >
          Unlocked
        </Button>
      );
    } else {
      buttons.push(
        <Button
          key={5}
          variant="danger"
          className="mr-1"
          onClick={onRequestUnlockedClick}
        >
          Request Unlocked
        </Button>
      );
    }
  }

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        defaultValues={data}
        loading={isLoading}
        buttons={buttons}
        fields={fields}
        onSubmit={onSubmit}
        enableSave={false}
        url={url}
        onErrors={(errors) => setErrors(errors)}
        leftPanel={leftPanel}
        onChange={({ name, value }, values) => {
          if (name === "customerId") {
            const customerDatasource = datasource.customer.find(
              (f) => f.key === value
            );

            if (customerDatasource) {
              values.bankId = customerDatasource.ref1;
              values.branch = customerDatasource.ref2;
            }

            setCustomerId(value);
          }

          setData({ ...data, ...values });
        }}
      >
        <div className="mt-2">
          <ReceiptDescription
            disabled={disabled}
            onChange={(selectedInvoiceId) =>
              setSelectedInvoiceIdList(selectedInvoiceId)
            }
            invoices={invoices}
          />
        </div>
      </DataForm>
    </BoxContainer>
  );
}
