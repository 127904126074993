import Pagination from "@standard/components/Pagination/Pagination";
import { useCallback, useEffect } from "react";
import { useState } from "react";

const PriceRateFilter = ({ totalPage, onSearch }) => {
  const [term, setTerm] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);

  const onPageChange = useCallback((value) => {
    setSelectedPage(value);
  });

  useEffect(() => {
    onSearch({ pageNumber: selectedPage, term });
  }, [selectedPage]);

  const onSubmitSearch = () => {
    if (selectedPage === 1) onSearch({ pageNumber: selectedPage, term });
    else setSelectedPage(1);
  };

  return (
    <div>
      <div className="input-group mb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Please enter place..."
          value={term}
          onKeyUp={(e) => {
            if (e.keyCode === 13) onSubmitSearch();
          }}
          onChange={(e) => {
            setTerm(e.target.value);
          }}
        />
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => onSubmitSearch()}
        >
          <i className="fa fa-search"></i>
        </button>
      </div>
      <div className="float-right">
        <Pagination
          selectedPage={selectedPage}
          totalPage={totalPage}
          onPageChange={onPageChange}
        ></Pagination>
      </div>
    </div>
  );
};

export default PriceRateFilter;
