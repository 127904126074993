import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams, useHistory } from "react-router-dom";
import { useState, memo } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { FormService } from "@standard/services";
import { useEffect } from "react";

const getRunning = ({ template, running }) => {
  running += 1;
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;

  let documentNo = template;
  if (documentNo.split("{MONTH}").length > 0) {
    month
      .toString()
      .padStart(2, "0")
      .split("")
      .map((m) => (documentNo = documentNo.replace("{MONTH}", m)));
  }
  if (documentNo.split("{YEAR}").length > 0) {
    year
      .toString()
      .substring(2, 4)
      .split("")
      .map((y) => (documentNo = documentNo.replace("{YEAR}", y)));
  }

  const runningDigit = documentNo.split("{RUNNING}").length - 1;
  running
    .toString()
    .padStart(runningDigit, "0")
    .split("")
    .map((r) => (documentNo = documentNo.replace("{RUNNING}", r)));

  return documentNo;
};

export default function Setting({}) {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const url = "master/setting";

  const defaultData = {
    companyNameTH: "",
    companyNameEN: "",
    companyAddressEN: "",
    companyAddressEN2: "",
    companyAddressTH: "",
    companyAddressTH2: "",
    companyTelephone: "",
    companyMobile: "",
    companyFax: "",
    companyEmail: "",
    companyTaxNo: "",
    policyPasswordAttempt: 5,
    documentRunningInvoiceNo: "",
    documentRunningRceiptNo: "",
    documentRunningInvoice: {
      template: "",
      running: 0,
    },
    documentRunningRceipt: {
      template: "",
      running: 0,
    },
  };

  const [data, setData] = useState(defaultData);

  useEffect(() => {
    FormService.getRequest(url).then((result) => {
      result.documentRunningInvoiceNo = result.documentRunningInvoice.running;
      result.documentRunningRceiptNo = result.documentRunningRceipt.running;
      setData(result);
      setIsLoading(false);
    });
  }, []);

  const onSubmit = (newData) => {
    setIsLoading(true);

    const params = { ...newData, id: data._id };

    FormService.submitRequest(url, params)
      .then((result) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const infoFields = [
    {
      label: "Company Name (TH)",
      name: "companyNameTH",
      type: "text",
      required: true,
    },
    {
      label: "Company Name (EN)",
      name: "companyNameEN",
      type: "text",
      required: true,
    },
    {
      label: "Address (EN)",
      name: "companyAddressEN",
      type: "text",
      required: false,
    },
    {
      label: "Address 2 (EN)",
      name: "companyAddressEN2",
      type: "text",
      required: false,
    },
    {
      label: "Address (TH)",
      name: "companyAddressTH",
      type: "text",
      required: false,
    },
    {
      label: "Address 2 (TH))",
      name: "companyAddressTH2",
      type: "text",
      required: false,
    },
    {
      label: "Telephone",
      name: "companyTelephone",
      type: "text",
      required: true,
    },

    {
      label: "Fax",
      name: "companyMobile",
      type: "text",
      required: true,
    },
    {
      label: "Mobile",
      name: "companyFax",
      type: "text",
      required: true,
    },
    {
      label: "Email",
      name: "companyEmail",
      type: "text",
      required: true,
    },
    {
      label: "Tax No",
      name: "companyTaxNo",
      type: "text",
      required: true,
    },
  ];

  const policytaxFields = [
    {
      label: "Password Attempt",
      name: "policyPasswordAttempt",
      type: "number",
      required: true,
    },
  ];

  const documentRunningFields = [
    {
      label: "Invoice Document Running",
      name: "documentRunningInvoiceNo",
      type: "text",
      description: `This is current running ( Next invoice no. will be ${getRunning(
        data.documentRunningInvoice
      )} )`,
    },
    {
      label: "Receipt Document Running",
      name: "documentRunningRceiptNo",
      type: "text",
      description: `This is current running ( Next receipt no. will be ${getRunning(
        data.documentRunningRceipt
      )} )`,
    },
  ];

  const tabs = [
    { name: "info", label: "Company Info", fields: infoFields },
    {
      name: "policy",
      label: "Policy",
      fields: policytaxFields,
    },
    {
      name: "documentRunning",
      label: "Document Running",
      fields: documentRunningFields,
    },
  ];

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        defaultValues={data}
        fields={tabs}
        onSubmit={onSubmit}
        url={url}
        onErrors={(errors) => setErrors(errors)}
        enableTabs={true}
      ></DataForm>
    </BoxContainer>
  );
}
